import actionTypes from "../../actionTypes";
const initialState = {}

const setError = (state, action) =>{
    return {
        ...state,
        [action.key]: action.payload ? action.payload : true
    }
} 
const unsetError = (state, action) =>{
    return {
        ...state,
        [action.key]: false
    }
} 


export const errorsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_ERROR: return setError(state, action) 
        case actionTypes.UNSET_ERROR: return unsetError(state, action) 
        
        case actionTypes.FETCH_SERVICE_RANGE_ERROR: return setError(state, {...action, key: 'FETCH_SERVICE_RANGE'})
        case actionTypes.FETCH_SERVICE_RANGE_SUCCESS: return unsetError(state, {key: 'FETCH_SERVICE_RANGE'})
        
        case actionTypes.FETCH_FORM_ERROR: return setError(state, {...action, key: 'FETCH_FORM'})
        case actionTypes.FETCH_FORM_SUCCESS: return unsetError(state, {key: 'FETCH_FORM'})

        case actionTypes.SUBMIT_FORM_ERROR: return setError(state, {...action, key: 'SUBMIT_FORM'})
        case actionTypes.SUBMIT_FORM_SUCCESS: return unsetError(state, {key: 'SUBMIT_FORM'})
        
        case actionTypes.FETCH_REPORT_DATA_ERROR: return setError(state, {...action, key: 'FETCH_REPORT_DATA'})
        case actionTypes.FETCH_REPORT_DATA_SUCCESS: return unsetError(state, {key: 'FETCH_REPORT_DATA'})
        
        case actionTypes.FETCH_SERVICE_RANGES_ERROR: return setError(state, {...action, key: 'FETCH_SERVICE_RANGES'})
        case actionTypes.FETCH_SERVICE_RANGES_SUCCESS: return unsetError(state, {key: 'FETCH_SERVICE_RANGES'})
        
        case actionTypes.REQUEST_APPOINTMENT_ERROR: return setError(state, {...action, key: 'REQUEST_APPOINTMENT'})
        case actionTypes.REQUEST_APPOINTMENT_SUCCESS: return unsetError(state, {key: 'REQUEST_APPOINTMENT'})
       
        case actionTypes.CREATE_REPORT_ERROR: return setError(state, {...action, key: 'CREATE_REPORT'})
        case actionTypes.CREATE_REPORT_SUCCESS: return unsetError(state, {key: 'CREATE_REPORT'})
        
        case actionTypes.FETCH_RESSOURCES_ERROR: return setError(state, {...action, key: 'FETCH_RESSOURCES'})
        case actionTypes.FETCH_RESSOURCES_SUCCESS: return unsetError(state, {key: 'FETCH_RESSOURCES'})
        case actionTypes.FETCH_USERS_ERROR: return setError(state, {...action, key: 'FETCH_USERS'})
        case actionTypes.FETCH_USERS_SUCCESS: return unsetError(state, {key: 'FETCH_USERS'})

        case actionTypes.UPDATE_APPOINTMENT_ERROR: return setError(state, {...action, key: 'UPDATE_APPOINTMENT'})
        case actionTypes.UPDATE_APPOINTMENT_SUCCESS: return unsetError(state, {key: 'UPDATE_APPOINTMENT'})
        
        case actionTypes.UPDATE_RESSOURCE_ERROR: return setError(state, {...action, key: 'UPDATE_RESSOURCE'})
        case actionTypes.UPDATE_RESSOURCE_SUCCESS: return unsetError(state, {key: 'UPDATE_RESSOURCE'})
        
        case actionTypes.OPEN_REPORT_ERROR: return setError(state, {...action, key: 'OPEN_REPORT'})
        case actionTypes.OPEN_REPORT_SUCCESS: return unsetError(state, {key: 'OPEN_REPORT'})
        
        case actionTypes.ASSIGN_USER_ERROR: return setError(state, {...action, key: 'ASSIGN_USER'})
        case actionTypes.ASSIGN_USER_SUCCESS: return unsetError(state, {key: 'ASSIGN_USER'})
        
        case actionTypes.REMOVE_ASSIGNEE_ERROR: return setError(state, {...action, key: 'REMOVE_ASSIGNEE'})
        case actionTypes.REMOVE_ASSIGNEE_SUCCESS: return unsetError(state, {key: 'REMOVE_ASSIGNEE'})
        
        case actionTypes.CREATE_SERVICE_RANGE_ERROR: return setError(state, {...action, key: 'CREATE_SERVICE_RANGE'})
        case actionTypes.CREATE_SERVICE_RANGE_SUCCESS: return unsetError(state, {key: 'CREATE_SERVICE_RANGE'})
        
        case actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_ERROR: return setError(state, {...action, key: 'CREATE_SERVICE_WITH_RESSOURCE_RANGE'})
        case actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_SUCCESS: return unsetError(state, {key: 'CREATE_SERVICE_WITH_RESSOURCE_RANGE'})
        
        case actionTypes.LOGIN_ERROR: return setError(state, {...action, key: 'LOGIN'})
        case actionTypes.LOGIN_SUCCESS: return unsetError(state, {key: 'LOGIN'})
        
        case actionTypes.FETCH_PROFILE_ERROR: return setError(state, {...action, key: 'FETCH_PROFILE'})
        case actionTypes.FETCH_PROFILE_SUCCESS: return unsetError(state, {key: 'FETCH_PROFILE'})
        
        case actionTypes.ADD_PROFILE_ERROR: return setError(state, {...action, key: 'ADD_PROFILE'})
        case actionTypes.ADD_PROFILE_SUCCESS: return unsetError(state, {key: 'ADD_PROFILE'})
        
        case actionTypes.ADD_COMMENT_ERROR: return setError(state, {...action, key: 'ADD_COMMENT'})
        case actionTypes.ADD_COMMENT_SUCCESS: return unsetError(state, {key: 'ADD_COMMENT'})
        
        case actionTypes.ADD_CONTACT_ATTEMPT_ERROR: return setError(state, {...action, key: 'ADD_CONTACT_ATTEMPT'})
        case actionTypes.ADD_CONTACT_ATTEMPT_SUCCESS: return unsetError(state, {key: 'ADD_CONTACT_ATTEMPT'})

        default: return state;
    }
};
