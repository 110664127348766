import React from 'react'
import './LoadingCircle.scss'

const LoadingCircle = () =>{
    return(
        <div className="svg-wrap-wrap">
            <div className="svg-wrap">
                <svg version="1.1" id="Loader" x="0px" y="0px"
                    viewBox="-2.5 -2.5 49 49">
                    <circle className="path_circle" fill="none" stroke="#fff" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" cx="22" cy="22" r="22"/>
                </svg>
            </div>
        </div>
    )
}

export default LoadingCircle;