import actionTypes from "../../actionTypes";
const initialState = {}

const setEntities = (state, action) =>{
    return {
        ...state, 
        ...action.payload.entities.appointments
    }
};
const updateEntitie = (state, action) =>{
    return {
        ...state,
        [action.id]:{
            ...state[action.id],
            ...action.payload
        } 
    }
};

export const appointmentsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_RESSOURCES_SUCCESS: return setEntities(state, action);
        case actionTypes.UPDATE_RESSOURCE_SUCCESS: return setEntities(state, action);
        case actionTypes.UPDATE_APPOINTMENT_SUCCESS: return updateEntitie(state, action);
        case actionTypes.ASSIGN_USER_SUCCESS: return updateEntitie(state, action);
        case actionTypes.REMOVE_ASSIGNEE_SUCCESS: return updateEntitie(state, action);
        default: return state;
    }
};

