import React, {useState} from 'react'
import BaseModal from 'styled-react-modal'
import {themeGet} from 'styled-system'
import styled, {keyframes} from 'styled-components'

const fadeInModal = keyframes`
  from {
    opacity:0;
  }

  to {
    opacity: 1;
  }
`;


const fadeOutModal= keyframes`
  from {
    opacity:1;
  }

  to {
    opacity: 0;
  }
`;

const fadeInInner = keyframes`
  from {
    opacity:0;
    transform: translateY(45px);
  }

  to {
    opacity: 1;
    transform: translateY(0px)
  }
`;

const fadeOutInner= keyframes`
  from {
    opacity:1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-45px)
  }
`;


const StyledModal = BaseModal.styled`
  width: 100vw;
  height: 100vh;
  display: block;
  padding: 5vh;
  align-items: center;
  justify-content: center;
  background-color:  rgba(28, 34, 65, 0.74);
  animation: ${props=>!props.isClosing ? fadeInModal : fadeOutModal} ${props => !props.isClosing ? '0.25s ease-in-out' : '0.25s ease-in-out'};
  overflow-y: scroll;
  box-sizing: border-box;
`;

const StyledInner = styled.div`
  
  width: 35rem;
  min-height:20px;
  display: block;
  background-color: ${themeGet('colors.neutral.grays.100')};
  border-radius: ${themeGet('radii.2')};
  box-shadow: ${themeGet('shadows.4')};
  margin: 10vh auto;
  transform: translateY(0px);
  animation: ${props=>!props.isClosing ? fadeInInner : fadeOutInner} ${props => !props.isClosing ? '0.25s ease-in-out' : '0.25s ease-in-out'};
  
 
`;


const Modal = (props) =>{



  const [isClosing, setIsClosing] = useState(false);

  const beforeClose = () =>{
      
      return new Promise(resolve => {        
        setIsClosing(true);
        setTimeout(resolve, 250);
      });
  }

  const afterClose = ()=>{
    
      setIsClosing(false);
      props.afterClose && props.afterClose()
  }
  const handleBackGroundClick = (e) =>{
    e.preventDefault()
    e.stopPropagation()  
    if (e.target === e.currentTarget) {
      props.onRequestClose && props.onRequestClose(e)
    }
  }

  return (
    <StyledModal
    afterClose={afterClose}
    allowScroll={props.allowScroll}
    isClosing={isClosing}      
    beforeClose={beforeClose} 
    isOpen={props.isOpen} 
    onClick={handleBackGroundClick}
    onEscapeKeydown={props.onRequestClose}>
      <StyledInner isClosing={isClosing}>
        {props.children}
      </StyledInner>
    </StyledModal>
  );
  
}

export default Modal

