import actionTypes from '../actionTypes'
import {appointmentsApi} from '../../api'
import {setEvent} from './uiActions'
import {reportError} from '../../utils'


const updateAppointmentStart = id => {
    return {
        type: actionTypes.UPDATE_APPOINTMENT_START,
        id
    }
}
export const updateAppointmentSuccess = (id, data) => {
    let result = data.data.appointment
    delete result["ressource"]
    return {
        type: actionTypes.UPDATE_APPOINTMENT_SUCCESS,
        id,
        payload: result
    }
}

const updateAppointmentError = (id, error) => {
    return {
        type: actionTypes.UPDATE_APPOINTMENT_ERROR,
        payload: {
            error
        } 
    }
}

export const updateAppointment = (id, data) => async dispatch =>{
    dispatch(updateAppointmentStart(id))
    try{
        const response = await appointmentsApi.update(id, data)
        dispatch(updateAppointmentSuccess(id, response))
        dispatch(setEvent(`UPDATE_APPOINTMENT_${id}`))
    }catch(error){
        reportError(actionTypes.UPDATE_APPOINTMENT_ERROR, error)
        dispatch(updateAppointmentError(error))
    }
}


const assignUserStart = id => {
    return {
        type: actionTypes.ASSIGN_USER_START,
        id
    }
}
export const assignUserSuccess = (id, data) => {
    console.log(data)
    const assignees = data.data.appointment.assignees;
    const result = {
        assignees
    }
    
    return {
        type: actionTypes.ASSIGN_USER_SUCCESS,
        id,
        payload: result
    }
}

const assignUserError = (error) => {
    console.log(error)
    return {
        type: actionTypes.ASSIGN_USER_ERROR,
        payload: {
            error
        } 
    }
}

export const assignUser = (id, user) => async dispatch =>{
    dispatch(assignUserStart(id))
    try{
        const response = await appointmentsApi.addUser(id, user)
        dispatch(assignUserSuccess(id, response))
    }catch(error){
        reportError(actionTypes.ASSIGN_USER_ERROR, error)
        dispatch(assignUserError(error))
    }
}

const removeAssigneeStart = id => {
    return {
        type: actionTypes.REMOVE_ASSIGNEE_START,
        id
    }
}

export const removeAssigneeSuccess = (id, data) => {
    return {
        type: actionTypes.REMOVE_ASSIGNEE_SUCCESS,
        id,
        payload: {assignees: data.data.appointment.assignees}
    }
}

const removeAssigneeError = (error) => {
    console.log(error)
    return {
        type: actionTypes.REMOVE_ASSIGNEE_ERROR,
        payload: {
            error
        } 
    }
}

export const removeAssignee = (id, user) => async dispatch =>{
    dispatch(removeAssigneeStart(id))
    try{
        const response = await appointmentsApi.removeUser(id, user)
        dispatch(removeAssigneeSuccess(id, response))
    }catch(error){
        reportError(actionTypes.REMOVE_ASSIGNEE_ERROR, error)
        dispatch(removeAssigneeError(error))
    }
}