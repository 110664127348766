import React from 'react';
import './MainContentWrapper.scss';
import Scrollbar from '../elements/Scrollbar';

const MainContentWrapper = (props) => {
    return(
    <div className="main-content-layout">
    <Scrollbar> 
        <div className="main-content-wrapper">
            {props.children}
        </div>
    </Scrollbar>
    </div>
    );
}

export default MainContentWrapper

