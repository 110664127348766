
const styles = {
    control: (provided, state) => {
      
      return{
        ...provided,
       minHeight: '45px',
       background: state.isFocused ? !state.selectProps.isSearchable ? '#D3D8F4' : '#E0E4FC' :'#ECEFFE',       
       paddingLeft: '12px',
       paddingRight: '12px',
       margin: '0.5em 0',
      //  border: state.isFocused ? '1px solid rgba(255, 255, 255, 0.9)' : 'none',
      //  boxShadow: state.isFocused ? '0 0 0 2px rgba(24,144,255,0.2)': 'none',
       border: state.isFocused ? 0 : 0,
    // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
          border: state.isFocused ? 0 : 0
        },
       cursor: 'pointer',
       
    }},
    noOptionsMessage: (provided, state) =>({
      ...provided,
      fontSize: '12px',
      fontWeight: 400,
      color: '#32395E'
    }),
    multiValue: (provided, {data}) => ({
      ...provided,
      backgroundColor: data.error ? '#ea00004f' : '#9d9bda63',
      borderRadius: '3px',
      padding: '0.15em',
      paddingLeft:'0.5em',
      color: data.error ? '#ea0000' : '#514cce',
    }),
    menu:(provided, {options})=>({
      ...provided,
      display: options.length === 0 ? 'none' : 'block'
    }),
    option: (provided, {isFocused, isSelected}) =>(
      {
      ...provided,
      color: isSelected ? '#514cce' : !isFocused ? '#6F7898' : '#514cce',
      background: isSelected ? '#fff' : !isFocused ? '#fff' : '#ECEFFE',
      padding: '12px 15px',
      ':active': {
        ...provided[':active'],
      },
      }),
    multiValueLabel: (provided, {data}) => ({
      ...provided,
      fontSize: '75%',
      fontWeight:600,
      color: data.error ? '#ea0000' : '#514cce',

    }),
    multiValueRemove: (provided, state) =>({
      paddingRight:'0.5em',
      paddingTop: '2px'
    }),
    singleValue: (provided, state) => ({
      color: '#514cce',
      fontSize: '1em',
      fontWeight: 400
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor:'none',
    }),
    placeholder: (provided, state) =>({
      ...provided,
      fontWeight: 400,
      color: '#AAB5DB',
      fontSize: '0.875em'

    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      fill: !state.selectProps.isSearchable ? '#514cce' : '#AAB5DB',
      color: !state.selectProps.isSearchable ? '#514cce' : '#AAB5DB'
    }),
  }

export const theme = (theme) => ({
  ...theme,
  borderRadius: '8px',
  colors: {
  ...theme.colors,
    primary25: '#ECEFFE',
    primary: '#514cce',
  },
})

export default styles