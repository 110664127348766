import React from 'react';
import {Route, Switch} from 'react-router-dom'

import LoginPage from '../pages/auth/LoginPage'
import SignupPage from '../pages/auth/SignupPage'
import ResetPwdPage from '../pages/auth/ResetPwdPage'
import AuthLayout from '../hoc/Layouts/AuthLayout';


export const AuthRouter = (props) => {
    const path = props.match.path
    return (
        <AuthLayout>
            <Switch>
                <Route path={`${path}/login`} component={LoginPage}/>
                <Route path={`${path}/signup`} component={SignupPage}/>
                <Route path={`${path}/reset-pwd`} component={ResetPwdPage}/>
            </Switch>
        </AuthLayout>
    )
}
