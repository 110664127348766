import React from 'react'
import {SimpleSelect} from './SimpleSelect'
import {CreatableSelect} from './CreatableSelect'
import styles, {theme} from './styles'


const Select = ({styles: passedStyles, isCreatable, skipSort, options, ...rest}) => {

    const sortedOptions = skipSort ? options :options.sort((a, b)=>{
      if(a.label>b.label){return 1}
      if(b.label>a.label){return -1}
      return 0
    });
    
    const handleChange = value =>{
      if(rest.onChange){
        rest.name ? rest.onChange(rest.name, value)
      : rest.onChange(value)
      }
      
    }
    const Component = isCreatable ? CreatableSelect : SimpleSelect;
    return (
        <Component {...rest} theme={theme} options={sortedOptions} onChange={handleChange} styles={passedStyles ? passedStyles : styles}  />
    );
}
 
export default Select;
