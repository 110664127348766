import actionTypes from "../../actionTypes";
import { stat } from "fs";
const initialState = {}

const setEntities = (state, action) =>{
    return {
        ...state, 
        ...action.payload.entities.ressources
    }
};

const updateEntitie = (state, action) =>{
    return {
        ...state,
        [action.id]:{
            ...state[action.id],
            ...action.payload
        }
    }
}

const addComment = (state, action) =>{
    if(state[action.id]){
        return {
            ...state,
            [action.id]:{
                ...state[action.id],
                comments: [...state[action.id].comments.filter(e=>(e.id !== action.payload.id)), action.payload]
            
            }
        }
    }else{
        return state
    }
    
}

export const ressourcesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_RESSOURCES_SUCCESS: return setEntities(state, action);
        case actionTypes.UPDATE_RESSOURCE_SUCCESS: return setEntities(state, action);
        case actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_SUCCESS: return updateEntitie(state, action);
        case actionTypes.ADD_COMMENT_SUCCESS: return addComment(state, action);
        case actionTypes.ADD_CONTACT_ATTEMPT_SUCCESS: return setEntities(state, action);
        default: return state;
    }
};

