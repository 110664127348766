import React from 'react'
import Field, {FastField} from '../../../components/elements/Field'
import { withFormik } from 'formik';
import Button from '../../../components/elements/Button'
import {connect} from 'react-redux'
import {loginUser} from '../../../store/actions'
import {selectIsLoading} from '../../../store/selectors'
import validator from 'validator';

 
const Form = (props) => {
    const {
        values,
        touched,
        errors,
        isValid,
        setFieldValue,
        handleSubmit,
        validate
      } = props;
    return (
        <form onSubmit={handleSubmit}>
            
                <div className="auth-form">
                <p>Bitte mit Nutzerdaten anmelden.</p>
                    <div className="information-block">
                        <div className="item">
                            <label>E-Mail</label>
                            <Field plain onChange={(value)=>{                                
                                setFieldValue("email", value)                                
                            }}type="email" name="email" value={values.email}/>
                        </div>
                        
                    </div>
                    <div className="information-block">
                        <div className="item">
                            <label>Password</label>
                            <Field plain onChange={(value)=>{
                                setFieldValue("password", value)
                            }} type="password" name="password" value={values.password}/>
                        </div>
                        
                    </div>
                    <div className="submit">
                        <Button type="submit" disabled={!isValid} isLoading={props.isLoading}  variant="default-secondary" block height="35" spaced="true">Login</Button>
                    </div>

                </div>
            
        </form>
    );
}
 



const LoginForm = (props) => {

    const EnhancedForm = withFormik({
        handleSubmit: (values)=>{
            props.login({
                ...values,
                password: values.password.trim(),
                email: values.email.toLowerCase().trim()
            })
        },
        validate: (values)=>{
            
            let errors ={}
            if(!values.email){
                errors = {
                    ...errors, 
                    email: "Email ist Pflicht"
                }
            }
            else if(!validator.isEmail(values.isEmail)){
                errors = {
                    ...errors, 
                    email: "Gültige Email Adresse"
                }
            }
            if(!values.password){
                errors = {
                    ...errors, 
                    password: "Passwort eingeben"
                }                        
            }            
            console.log(errors)
            console.log(values)
            return errors
        },
        mapPropsToValues: () => ({
            email:"",
            password: ""
        }),
        })(Form)
    return (
        <div>
            <EnhancedForm {...props}/>
        </div>
    );
}

const mapStateToProps = state =>({
    isLoading: selectIsLoading(state, "LOGIN")
})
 
const mapDispatchToProps = dispatch =>({
    login: (data) => dispatch(loginUser(data)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)