import actionTypes from "../../actionTypes";
const initialState = {}

const setEntities = (state, action) =>{
    return {
        ...action.payload.entities.serviceRanges
    }
};


export const serviceRangesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_SERVICE_RANGES_SUCCESS: return setEntities(state, action);
        default: return state;
    }
};

