import React from 'react'
import {connect} from 'react-redux'
import {isAuthenticated} from '../../store/selectors'
import {
  Route,
  Redirect
} from 'react-router-dom'


const mapStateToProps = state =>({
  isAuthenticated: isAuthenticated(state)
})

export const PrivateRoute = connect(mapStateToProps, null)(({ 
    component: Component, 
    isAuthenticated,
    ...rest }) => {
      return (
        <Route {...rest} render={(props) => (
          isAuthenticated
            ? <Component {...props} />
            : <Redirect to='/auth/login' />
        )} />)
    })


  PrivateRoute.propTypes = {
    ...Route.propTypes
}