import { createSelector } from "reselect";
import {getAuthenticatedUser} from './authSelector'
import {selectRessource} from './ressourcesSelector'
import {selectUser} from './userSelector'
const _selectAppointments = state => (Object.values(state.entities.appointments))

export const selectAppointment = (state, id) => {
    return state.entities.appointments[id]
}

export const selectAppointments = createSelector(
    [_selectAppointments],
    (items)=>(items)
)
export const selectAppointmentsForRessource = (state, id) =>{
    const ressource = selectRessource(state, id)
    if(ressource){
        const changeAppointment = state.entities.appointments[ressource.changeAppointment]
        const saveAppointment = state.entities.appointments[ressource.saveAppointment]
        const restoreAppointment = state.entities.appointments[ressource.restoreAppointment]

        return{
            changeAppointment, 
            restoreAppointment, 
            saveAppointment
        }
    }
    
}


export const selectMyAppointments = createSelector(
    _selectAppointments,
    getAuthenticatedUser,
    (items, user)=>(items.filter(e=>(user && user.id && e.assignees && e.assignees[0] &&e.assignees[0].id === user.id)))
)

export const selectClosedAppointments = createSelector(
    [_selectAppointments],
    (items)=>(items.filter(e=>(e.status !== "OPEN" && e.status !== "NEEDS_REVIEW")))
)

export const selectAppointmentAssignees = (state, id) =>{

    return id ? selectAppointment(state, id).assignees.map(e=>{
        return state.entities.users[e]
    }) : []
}
export const selectAppointmentRessource = (state, id) =>{
    
    const ressourceId = id ? selectAppointment(state, id).ressource.id : null

    if(ressourceId){
        const ressource = selectRessource(state, ressourceId)
        const saveAppointment = selectAppointment(state, ressource.saveAppointment)
        return {
            ...ressource,
            saveAppointment
        }
    }
    return null
}