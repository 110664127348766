import actionTypes from '../actionTypes'
import {hubApi} from '../../api'
import {setEvent} from './uiActions'
import {serviceRangesNormalizer} from '../normalizers'

const fetchServiceRangesStart = () => {
    return {
        type: actionTypes.FETCH_SERVICE_RANGES_START
    }
}

const fetchServiceRangesSuccess = (data) =>{
    const entities = serviceRangesNormalizer(data) 
    return {
        type: actionTypes.FETCH_SERVICE_RANGES_SUCCESS,
        payload: entities
    }
}


const fetchServiceRangesError = (error) =>{
    console.log(error)
    return {
        type: actionTypes.FETCH_SERVICE_RANGES_ERROR
    }
}



export const fetchServiceRanges = () => async dispatch => {
    
    dispatch(fetchServiceRangesStart())
    try{
        const response = await hubApi.getServiceRanges()
        dispatch(fetchServiceRangesSuccess(response))
    }catch(error){
        dispatch(fetchServiceRangesError(error))
    }
    
};







const createServiceRangeStart = () => {
    return {
        type: actionTypes.CREATE_SERVICE_RANGE_START,
    }
}

const createServiceRangeSuccess = (data) => {
    console.log(data)
    return {
        type: actionTypes.CREATE_SERVICE_RANGE_SUCCESS
    }
}

const createServiceRangeError = (error) => {
    console.log(error)
    return {
        type: actionTypes.CREATE_SERVICE_RANGE_ERROR,
        payload: {
            error
        } 
    }
}

export const createServiceRange = (data) => async dispatch =>{
    dispatch(createServiceRangeStart())
    try{
        const response = await hubApi.createServiceRange(data)
        dispatch(createServiceRangeSuccess(response))
        dispatch(setEvent('CREATE_SERVICE_RANGE'))
    }catch(error){
        dispatch(createServiceRangeError(error))
    }
}

const createServiceRangeForRessourceStart = () => {
    return {
        type: actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_START,
    }
}

const createServiceRangeForRessourceSuccess = (id, data) => {
    console.log(data)
    return {
        type: actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_SUCCESS,
        id,
        payload: {
            status: 'WAITING_FOR_APPOINTMENT',
            serviceTimeRange:{...data.data.serviceRange}
        }
    }
}

const createServiceRangeForRessourceError = (error) => {
    console.log(error)
    return {
        type: actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_ERROR,
        payload: {
            error
        } 
    }
}

export const createServiceRangeForRessource = (id, data) => async dispatch =>{
    dispatch(createServiceRangeForRessourceStart())
    try{
        const response = await hubApi.createServiceRangeForRessource(id, data)
        dispatch(createServiceRangeForRessourceSuccess(id, response))
        dispatch(setEvent('CREATE_SERVICE_RANGE'))
    }catch(error){
        dispatch(createServiceRangeForRessourceError(error))
    }
}