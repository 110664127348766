import actionTypes from '../actionTypes'
import {hubApi} from '../../api'
import {setEvent} from './uiActions'

const fetchServiceRangeStart = () => {
    return {
        type: actionTypes.FETCH_SERVICE_RANGE_START
    }
}

const fetchServiceRangeSuccess = (data) =>{
    return {
        type: actionTypes.FETCH_SERVICE_RANGE_SUCCESS,
        payload: data.data
    }
}


const fetchServiceRangeError = (error) =>{
    console.log(error)
    return {
        type: actionTypes.FETCH_SERVICE_RANGE_ERROR
    }
}



export const fetchServiceRange = (token) => async dispatch => {
    
    dispatch(fetchServiceRangeStart())
    try{
        const response = await hubApi.getServiceRange(token)
        dispatch(fetchServiceRangeSuccess(response))
    }catch(error){
        dispatch(fetchServiceRangeError(error))
    }
    
};


const requestAppointmentStart = () => {
    return {
        type: actionTypes.REQUEST_APPOINTMENT_START
    }
}

const requestAppointmentSuccess = (data) =>{
    console.log(data)
    return {
        type: actionTypes.REQUEST_APPOINTMENT_SUCCESS,
        payload: data.data.appointment
    }
}


const requestAppointmentError = (error) =>{
    console.log(error)
    return {
        type: actionTypes.REQUEST_APPOINTMENT_ERROR
    }
}


export const requestAppointment = (id, data) => async dispatch  =>{
    dispatch(requestAppointmentStart())
    try{
        const response = await hubApi.requestAppointment(id, data)
        dispatch(requestAppointmentSuccess(response))
    }catch(error){
        dispatch(requestAppointmentError(error))
    }
}


const fetchReportDataStart = () => {
    return {
        type: actionTypes.FETCH_REPORT_DATA_START
    }
}

const fetchReportDataSuccess = (data) =>{
    return {
        type: actionTypes.FETCH_REPORT_DATA_SUCCESS,
        payload: data.data
    }
}


const fetchReportDataError = (error) =>{
    
    return {
        type: actionTypes.FETCH_REPORT_DATA_ERROR
    }
}



export const fetchReportData = (token) => async dispatch => {
    
    dispatch(fetchReportDataStart())
    try{
        const response = await hubApi.getReportData(token)
        dispatch(fetchReportDataSuccess(response))
    }catch(error){
        dispatch(fetchReportDataError(error))
    }
    
};

const createReportStart = () => {
    return {
        type: actionTypes.CREATE_REPORT_START
    }
}

const createReportSuccess = (data) =>{
    console.log(data)
    return {
        type: actionTypes.CREATE_REPORT_SUCCESS,
        payload: data.data
    }
}


const createReportError = (error) =>{
    console.log(error)
    return {
        type: actionTypes.CREATE_REPORT_ERROR
    }
}


export const createReport = (id, data) => async dispatch  =>{
    dispatch(createReportStart())
    try{
        const response = await hubApi.createReport(id, data)
        dispatch(createReportSuccess(response))
    }catch(error){
        dispatch(createReportError(error))
    }
}