import {LOCATION_OPTIONS, APPOINTMENT_CONFIG, SOFTWARE_OPTIONS, DEVICE_CATEGORY_OPTIONS} from '../../constants/serviceRange'
import { stat } from 'fs';


export const selectServiceRangeRessource = (state) =>{
    return state.entities.serviceRange.ressource;
}

export const selectReportRessource = (state) =>{
    return state.entities.serviceRange.ressource;
}

export const selectServiceRangeSlots = (state) =>{
    const slots = state.entities.serviceRange.slots ? state.entities.serviceRange.slots : {
        change: [],
        restore: [],
        save: [],
    };
    return {
        change: Object.values(slots.change)
    }
    

}

export const selectServiceRangeStatus = state => {
    return state.entities.serviceRange.ressource ? state.entities.serviceRange.ressource.status : null
}

export const selectForm = state =>{
    return state.entities.form
}

export const selectServiceRangeConfig = (state) =>{
    return state.entities.serviceRange.config || {
        locations:{
            sites: Object.keys(LOCATION_OPTIONS.DE),
            buildings: LOCATION_OPTIONS.DE
        },
        time:{
            buffer:{
                save: APPOINTMENT_CONFIG.SAVE.buffer,
                change: APPOINTMENT_CONFIG.CHANGE.buffer
            },
            duration: {
                save: APPOINTMENT_CONFIG.SAVE.duration,
                change: APPOINTMENT_CONFIG.CHANGE.duration
            }
        },
        deviceCategories: DEVICE_CATEGORY_OPTIONS,
        availableSoftware: SOFTWARE_OPTIONS

    };
}


export const selectFormConfig = (state) =>{
    return selectServiceRangeConfig(state)
}
