import React from 'react'
import { Scrollbars as BaseScrollbar} from 'react-custom-scrollbars';

const Scrollbar = (props) => {
    
    return (
        <BaseScrollbar>
            {props.children}
        </BaseScrollbar>
    );
}
 
export default Scrollbar;
