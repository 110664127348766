import React from 'react'

const SignupPage = () =>{
    return(
        <div>
            SignupPage
        </div>
    )
}

export default SignupPage