import React from 'react';
 

import Table from '../elements/Table'


import moment from 'moment'
import Badge from '../elements/Badge';





const ServiceRangesTable = (props) => {


    const columns = [
            
      {
        Header: 'Start',
        accessor: 'startDate',
      Cell: props => { 
          
          return(<span>{moment(props.value).locale('de').format('LL')}</span>)
        } // Custom cell components!
      },
      {
        Header: 'Ende',
        accessor: 'endDate',
      Cell: props => { 
          
          return(<span>{moment(props.value).locale('de').format('LL')}</span>)
        } // Custom cell components!
      },
      {
        Header: 'Werk',
        accessor: 'location.site',
      },
      {
        Header: 'Gebäude',
        accessor: 'location.building'
      },
      {
        Header: 'Rechner',
        accessor: 'ressource'
      },
      {
        Header: 'Typ',
        accessor: 'type', // String-based value accessors!,
        Cell: props => {return (<Badge value={props.value}/>)}
      },
    ]

    return (
        <Table
        data={props.data} 
        columns={columns}
        />
    );
}


 
export default ServiceRangesTable;

// {
//     Header: 'Friend Name',
//     accessor: d => d.friend.name // Custom value accessors!
//   }