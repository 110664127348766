import React from 'react';
 

import Table from '../elements/Table'


import moment from 'moment'
import Badge from '../elements/Badge';


const mapDivsion = ressource =>{
  return ressource.division && ressource.division === 'TE-ADMIN' ? 'TE' : 'FRD'
}


const commonFilters = [
  {
    accessor: d=>(d.ressource ? `${d.ressource.ciName} ${d.ressource.ciNameNew}` : ''),
    type: "text",
    label: 'CI-Name'
  },
  
 
  
  { 
    label: 'Datum',
    accessor: "startDate",
    type: "date",
  }, 
]

const commonColumns = [
  {
      Header: 'Status',
      accessor: 'status', // String-based value accessors!,
      Cell: props => {return (<Badge value={props.value}></Badge>)}
    },
    {
      Header: "Task",
      id: "removeOnly",
      accessor: d=>(d.ressource ? d.ressource.removeOnly : false), // String-based value accessors!,
      Cell: (props) => {
        console.log(props.original.ressource);
        return <Badge value={props.original.ressource.removeOnly ? "Remove" : "Replace"}  variant={props.original.ressource.removeOnly ? "warning" : "neutral"}/>;
      },
    },
    {
      Header: 'Datum',
      accessor: 'startDate',
    Cell: props => { 
        
        return(<span>{moment(props.value).locale('de').format('LL')}</span>)
      } // Custom cell components!
    },
    {
      Header: 'Uhrzeit',
      id: 'time',
      accessor: 'startDate',
    Cell: props => { 
        
        return(<span>{moment(props.value).locale('de').format('LT')}</span>)
      } // Custom cell components!
    },
    {
      Header: 'Computer',
      id: 'ciName',
      accessor: d=>(d.ressource ? d.ressource.ciName : ''),
    },
    {
      Header: 'Neu',
      id: 'ciNameNew',
      accessor: d=>(d.ressource ? d.ressource.ciNameNew : ''),
    },
    {
      Header: 'User',
      id: 'customerName',
      accessor: d=>(d.ressource ? d.ressource.customer.fullName : ''),
    },
    {
      Header: 'Werk',
      id: 'locationSite',
      accessor: d=>(d.ressource ? d.ressource.location.siteOriginal : ''),
    },    
  ]

const externalFilters = [
  {
    type: 'text',
    id: 'status',
    accessor: 'status' 
  },
  {
    type: 'date',
    id: 'date',
    accessor: 'startDate'
  } 
]

const filtered = {
  'OPEN':
    [
      {
        id: 'status', 
        value: 'OPEN'
      }
    ]
  ,
  'DONE': [
    {
      id: 'status', 
      value: 'DONE'
    }
  ],
  'TODAY':[
    {
      id: 'date', 
      value: new Date()
    }
  ]
}
const AppointmentsTable = (props) => {

  
  const handleRowClick = (e, t, rowInfo)=>{
    props.onRowSelect && props.onRowSelect(rowInfo)
  }

  const columns = props.own ? commonColumns : [
    ...commonColumns,
    {
      Header: 'Techniker',
      id: 'assignee',
      accessor: d=>(d.assignees ? d.assignees[0] ? `${d.assignees[0].firstName} ${d.assignees[0].lastName}` : '' : ''),
    },    
  ]

  const filters = props.own ? commonFilters : [
    ...commonFilters,
    {
      type: 'text',
      label: 'Techniker',
      id: 'assignee',
      accessor: (d) => (d.assignees ? d.assignees[0] ? `${d.assignees[0].firstName} ${d.assignees[0].lastName}` : '' : '') 
    },
   
  ]
    return (
        <Table
        emptyTitle="Keine Aufgaben"
        emptyDescription="Es gibt momentan keine Aufgaben."
        data={props.data} 
        fields={columns}
        filters={filters}
        defaultSorted={[{
          id: 'startDate',
          desc: false}]
        }
        externalFiltered={filtered[props.section]}
        externalFilters={externalFilters}
        getTrProps={(state, rowInfo, column) => {
          
          if(rowInfo){
            return {
              onClick: (e, t) => {
                handleRowClick(e, t, rowInfo);
              },
              style: {
                background: rowInfo.index === props.selected ? '#ECEFFE' : '',
                borderRadius: rowInfo.index === props.selected ? '6px' : '0px',
                border: rowInfo.index === props.selected + 1 || rowInfo.index === props.selected ? 'none' : ''

              }
            };
          }else{
            return{}
          }
        }}/>
    );
}


 
export default AppointmentsTable;

// {
//     Header: 'Friend Name',
//     accessor: d => d.friend.name // Custom value accessor


