import { createSelector } from "reselect";
import {getTimeDifference} from '../../utils'
const _selectRessources = state => (Object.values(state.entities.ressources))

export const selectRessource = (state, id) => {
    const ressource = state.entities.ressources[id]
    return ressource && ressource.customer ? ressource : {
        ...ressource,
        customer: {}
    }
}

export const selectRessources = createSelector(
    [_selectRessources],
    (items)=>(items.filter(e=>(true)))
)

export const selectOverDueRessources = createSelector(
    [_selectRessources],
    (items)=>(items.filter(e=>{
        return e.status === 'WAITING_FOR_APPOINTMENT' && e.lastContactAttempt && getTimeDifference(e.lastContactAttempt.startDate, new Date()) <= -2
    }))
)