const actionTypes = {
 
    SET_SHOW: "SET_SHOW",
    SET_REDIRECT: "SET_REDIRECT",
    UNSET_REDIRECT: "UNSET_REDIRECT",
    SET_ERROR: "SET_ERROR",
    UNSET_ERROR: "UNSET_ERROR",
    SET_EVENT: "SET_EVENT",
    UNSET_EVENT: "UNSET_EVENT",
    
    FETCH_SERVICE_RANGE_START: "FETCH_SERVICE_RANGE_START",
    FETCH_SERVICE_RANGE_ERROR: "FETCH_SERVICE_RANGE_ERROR",
    FETCH_SERVICE_RANGE_SUCCESS: "FETCH_SERVICE_RANGE_SUCCESS",
    
    FETCH_REPORT_DATA_START: "FETCH_REPORT_DATA_START",
    FETCH_REPORT_DATA_ERROR: "FETCH_REPORT_DATA_ERROR",
    FETCH_REPORT_DATA_SUCCESS: "FETCH_REPORT_DATA_SUCCESS",
    
    FETCH_SERVICE_RANGES_START: "FETCH_SERVICE_RANGES_START",
    FETCH_SERVICE_RANGES_ERROR: "FETCH_SERVICE_RANGES_ERROR",
    FETCH_SERVICE_RANGES_SUCCESS: "FETCH_SERVICE_RANGES_SUCCESS",
    
    FETCH_FORM_START: "FETCH_FORM_START",
    FETCH_FORM_ERROR: "FETCH_FORM_ERROR",
    FETCH_FORM_SUCCESS: "FETCH_FORM_SUCCESS",
    
    SUBMIT_FORM_START: "SUBMIT_FORM_START",
    SUBMIT_FORM_ERROR: "SUBMIT_FORM_ERROR",
    SUBMIT_FORM_SUCCESS: "SUBMIT_FORM_SUCCESS",
    
    CREATE_SERVICE_RANGE_START: "CREATE_SERVICE_RANGE_START",
    CREATE_SERVICE_RANGE_ERROR: "CREATE_SERVICE_RANGE_ERROR",
    CREATE_SERVICE_RANGE_SUCCESS: "CREATE_SERVICE_RANGE_SUCCESS",
    
    
    CREATE_SERVICE_WITH_RESSOURCE_RANGE_START: "CREATE_SERVICE_WITH_RESSOURCE_RANGE_START",
    CREATE_SERVICE_WITH_RESSOURCE_RANGE_ERROR: "CREATE_SERVICE_WITH_RESSOURCE_RANGE_ERROR",
    CREATE_SERVICE_WITH_RESSOURCE_RANGE_SUCCESS: "CREATE_SERVICE_WITH_RESSOURCE_RANGE_SUCCESS",
    
    REQUEST_APPOINTMENT_START: "REQUEST_APPOINTMENT_START",
    REQUEST_APPOINTMENT_SUCCESS: "REQUEST_APPOINTMENT_SUCCESS",
    REQUEST_APPOINTMENT_ERROR: "REQUEST_APPOINTMENT_ERROR",
    
    CREATE_REPORT_START: "CREATE_REPORT_START",
    CREATE_REPORT_SUCCESS: "CREATE_REPORT_SUCCESS",
    CREATE_REPORT_ERROR: "CREATE_REPORT_ERROR",

    FETCH_RESSOURCES_START: "FETCH_RESSOURCES_START",
    FETCH_RESSOURCES_ERROR: "FETCH_RESSOURCES_ERROR",
    FETCH_RESSOURCES_SUCCESS: "FETCH_RESSOURCES_SUCCESS",

    FETCH_USERS_START: "FETCH_START",
    FETCH_USERS_ERROR: "FETCH_USERS_ERROR",
    FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",

    UPDATE_APPOINTMENT_START: "UPDATE_APPOINTMENT_START",
    UPDATE_APPOINTMENT_ERROR: "UPDATE_APPOINTMENT_ERROR",
    UPDATE_APPOINTMENT_SUCCESS: "UPDATE_APPOINTMENT_SUCCESS",
    
    
    UPDATE_RESSOURCE_START: "UPDATE_RESSOURCE_START",
    UPDATE_RESSOURCE_ERROR: "UPDATE_RESSOURCE_ERROR",
    UPDATE_RESSOURCE_SUCCESS: "UPDATE_RESSOURCE_SUCCESS",
    
    OPEN_REPORT_START: "OPEN_REPORT_START",
    OPEN_REPORT_ERROR: "OPEN_REPORT_ERROR",
    OPEN_REPORT_SUCCESS: "OPEN_REPORT_SUCCESS",
    
    ASSIGN_USER_START: "ASSIGN_USER_START",
    ASSIGN_USER_ERROR: "ASSIGN_USER_ERROR",
    ASSIGN_USER_SUCCESS: "ASSIGN_USER_SUCCESS",
    
    REMOVE_ASSIGNEE_START: "REMOVE_ASSIGNEE_START",
    REMOVE_ASSIGNEE_ERROR: "REMOVE_ASSIGNEE_ERROR",
    REMOVE_ASSIGNEE_SUCCESS: "REMOVE_ASSIGNEE_SUCCESS",

    CHECK_AUTH_STATUS: "CHECK_AUTH_STATUS",
    SET_JWT_TOKEN: "SET_JWT_TOKEN",
    
    LOGIN_START: "LOGIN_START",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    
    
    FETCH_PROFILE_START: "FETCH_PROFILE_START",
    FETCH_PROFILE_ERROR: "FETCH_PROFILE_ERROR",
    FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
    
    ADD_COMMENT_START: "ADD_COMMENT_START",
    ADD_COMMENT_ERROR: "ADD_COMMENT_ERROR",
    ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
    
    
    ADD_CONTACT_ATTEMPT_START: "ADD_CONTACT_ATTEMPT_START",
    ADD_CONTACT_ATTEMPT_ERROR: "ADD_CONTACT_ATTEMPT_ERROR",
    ADD_CONTACT_ATTEMPT_SUCCESS: "ADD_CONTACT_ATTEMPT_SUCCESS",
    
    LOGOUT: "LOGOUT",
    
}
export default actionTypes