import React from 'react';

import {selectError} from '../../store/selectors'
import {connect} from 'react-redux'

import AlertBox from '../AlertBox'

const getMessage = props =>{
    return props.message || "Da ist etwas schief gelaufen."
}

const ErrorMessage = (props) => {

    return (
        props.error ? 
            <AlertBox>
                 {getMessage(props)}
            </AlertBox>
        : null
    );
}

const mapStateToProps = (state, {errorKey}) =>({
    error: selectError(state, errorKey)
})
 
export default connect(mapStateToProps, null)(ErrorMessage);