import actionTypes from '../actionTypes'

export const setRedirect = (pathname, search, state) =>{
    const test ={   
    type: actionTypes.SET_REDIRECT,
    payload: {
        pathname, 
        search, 
        state
    }
}
    return test
};

export const unSetRedirect = () =>({
    type: actionTypes.UNSET_REDIRECT
});

export const setEvent = (key) =>{
    return {
        type: actionTypes.SET_EVENT,
        key
    }
}
export const unSetEvent = (key) =>{
    return {
        type: actionTypes.UNSET_EVENT,
        key
    }
}