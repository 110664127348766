import React, { useState } from "react";
import Badge from "../elements/Badge";
import Table from "../elements/Table";
import moment from "moment";

const RessourcesTable = (props) => {
  const handleRowClick = (e, t, rowInfo) => {
    props.onRowSelect && props.onRowSelect(rowInfo);
  };

  const filters = [
    {
      label: "Ci-Name",
      accessor: (d) => `${d.ciName} ${d.ciNameNew}`,
      type: "text",
    },
    {
      label: "SN-Nummer",
      accessor: (d) => `${d.serialNumber} ${d.serialNumberNew}`,
      type: "text",
    },
    {
      type: "text",
      label: "User",
      accessor: (d) =>
        d.customer ? `${d.customer.firstName} ${d.customer.lastName}` : "",
    },
    {
      accessor: "status",
      type: "select",
      label: "Status",
      selectOptions: [
        {
          value: "OPEN",
          label: "Offen",
        },
        {
          value: "WAITING_FOR_APPOINTMENT",
          label: "User Angefragt",
        },
        {
          value: "APPOINTED",
          label: "Terminiert",
        },
        {
          value: "IN_CUSTOMER_DEPOT",
          label: "Im Kundenlager",
        },
        {
          value: "IN_VENDOR_DEPOT",
          label: "INT Lager",
        },
        {
          value: "DONE",
          label: "Abgeschlossen",
        },
        {
          value: "HANDLED_EXTERNAL",
          label: "External",
        },
        {
          value: "ON_HOLD",
          label: "On Hold",
        },
      ],
    },
    {
      accessor: (d) => d.location.site,
      type: "select",
      label: "Werk",
      selectOptions: [
        {
          value: "Friedrichshafen",
          label: "Friedrichshafen",
        },
        {
          value: "Freital",
          label: "Freital",
        },
        {
          value: "Kassel",
          label: "Kassel",
        },
        {
          value: "Rödermark",
          label: "Rödermark",
        },
      ],
    },
    {
      accessor: (d) => (d.removeOnly ? "REMOVE" : "REPLACE"),
      type: "select",
      label: "Task",
      selectOptions: [
        {
          value: "REPLACE",
          label: "Replace",
        },
        {
          value: "REMOVE",
          label: "Remove",
        },
      ],
    },
  ];

  const columns = [
    {
      Header: "Status",
      accessor: "status", // String-based value accessors!,
      Cell: (props) => {
        return <Badge value={props.value} />;
      },
    },
    {
      Header: "Task",
      accessor: "removeOnly", // String-based value accessors!,
      Cell: (props) => {
        console.log(props.value);
        return (
          <Badge
            value={props.value ? "Remove" : "Replace"}
            variant={props.value ? "warning" : "neutral"}
          />
        );
      },
    },
    {
      Header: "CI Alt",
      accessor: "ciName",
    },
    {
      Header: "CI Neu",
      accessor: "ciNameNew",
    },
    {
      Header: "Werk",
      id: "site",
      accessor: (d) => d.location.site,
    },
    {
      Header: "Standort",
      id: "locationSiteOriginal",
      accessor: (d) => d.location.siteOriginal,
    },

    {
      Header: "Nutzer",
      id: "customerName",
      accessor: (d) =>
        d.customer
          ? `${d.customer.firstName || ""} ${d.customer.lastName || ""}`
          : "",
    },
  ];

  return (
    <React.Fragment>
      {/* <TableHeader filters={

        }/> */}

      <Table
        emptyTitle="Keine Rechner"
        emptyDescription="Es gibt momentan keine Rechner."
        data={props.data}
        fields={columns}
        filters={filters}
        getTrProps={(state, rowInfo, column) => {
          if (rowInfo) {
            return {
              onClick: (e, t) => {
                handleRowClick(e, t, rowInfo);
              },
              style: {
                background: rowInfo.index === props.selected ? "#ECEFFE" : "",
                borderRadius: rowInfo.index === props.selected ? "6px" : "0px",
                border:
                  rowInfo.index === props.selected + 1 ||
                  rowInfo.index === props.selected
                    ? "none"
                    : "",
              },
            };
          } else {
            const today = new Date();
            const todayString = moment(today).format("YYYY-MM-DD");
            return {};
          }
        }}
      />
    </React.Fragment>
  );
};

export default RessourcesTable;

// {
//     Header: 'Friend Name',
//     accessor: d => d.friend.name // Custom value accessors!
//   }
