import 'react-app-polyfill/ie11'; 
import 'react-app-polyfill/stable';


import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';

import {ThemeProvider} from 'styled-components';
import theme from './constants/theme'

import { ModalProvider } from './components/elements/Modal'
import i18n from './i18n'


import App from './App';

import store from './store'

import * as serviceWorker from './serviceWorker';
import 'normalize.css'
import './App.scss'
import './style/common/index.scss'
import 'normalize.css'

const app = (
    <div className="app-wrapper">
        <Provider store={store}>
            <ThemeProvider theme={theme}>    
                <ModalProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ModalProvider>      
            </ThemeProvider>
        </Provider>
    </div>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
