import actionTypes from "../../actionTypes";
const initialState = {
    EDIT_COURSE_TITLE: null, 
    RENAME_CARDSET: null
}

const setSelected = (state, key, value) =>{
    return {
        ...state,
        [key]: value
    }
}

export const selectedReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_REDIRECT: return setSelected(state, "REDIRECT", action.payload);
        case actionTypes.UNSET_REDIRECT: return setSelected(state, "REDIRECT", null);
        case actionTypes.SET_EVENT: return setSelected(state, action.key, true);
        case actionTypes.UNSET_EVENT: return setSelected(state, action.key, false);
        default: return state;
    }
};

