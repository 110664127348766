import {baseInstance} from './baseInstance';

export const hubApi = {
        getServiceRange: (token) => baseInstance.get(`/ressources/${token}/servicerange`),
        getForm: (token) => baseInstance.get(`/forms/${token}`),
        submitForm: (token, data) => baseInstance.post(`/forms/${token}`, data),
        getReportData: (token) => baseInstance.get(`/ressources/${token}/reports/preview`),
        createReport: (id, data) => baseInstance.post(`/ressources/${id}/reports`, data),
        getServiceRanges: () => baseInstance.get(`/serviceranges`),
        requestAppointment: (id, data) => baseInstance.post(`/ressources/${id}/appointments/request`, data),
        createServiceRange: (data) => baseInstance.post(`/ressources/servicerange`, data),
        createServiceRangeForRessource: (id, data) => baseInstance.post(`/ressources/${id}/servicerange`, data),
}