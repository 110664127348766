import React from 'react'

const ResetPwdPage = () =>{
    return(
        <div>
            ResetPwdPage
        </div>
    )
}

export default ResetPwdPage