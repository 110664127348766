import actionTypes from '../actionTypes'
import {usersApi} from '../../api'
import {userNormalizer} from '../normalizers'

const fetchUsersStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START
    }
}

const fetchUsersSuccess = (data) =>{
    const entities = userNormalizer(data)
    
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        payload: entities
    }
}


const fetchUsersError = (error) =>{
    console.log(error)
    return {
        type: actionTypes.FETCH_USERS_ERROR,
        payload: error
    }
}

export const fetchUsers = () => async dispatch => {
    dispatch(fetchUsersStart())
    try{
        const response = await usersApi.getAll()
        dispatch(fetchUsersSuccess(response))
    }catch(error){
        dispatch(fetchUsersError(error))
    }
    
};