import {combineReducers} from 'redux'
import { usersReducer } from "./usersReducer";
import { appointmentsReducer } from "./appointmentsReducer";
import { serviceRangeReducer } from "./serviceRangeReducer";
import { serviceRangesReducer } from "./serviceRangesReducer";
import { ressourcesReducer } from "./ressourcesReducer";
import { formReducer } from "./formReducer";

const entitiesReducer = combineReducers({
    users: usersReducer,
    ressources: ressourcesReducer, 
    appointments: appointmentsReducer,
    serviceRanges: serviceRangesReducer,
    serviceRange: serviceRangeReducer,
    form: formReducer
})

export default entitiesReducer