import React from 'react'

const NotFoundPage = () =>{
    return(
        <div>
            Not Found
        </div>
    )
}

export default NotFoundPage