import React from 'react';
import classNames from 'classnames'
import './Icon.scss'

import {ReactComponent as Plus}  from '../../../assets/icons/fontAwesome/solid/plus.svg'
import {ReactComponent as Trash}  from '../../../assets/icons/fontAwesome/solid/trash.svg'
import {ReactComponent as Building} from '../../../assets/icons/fontAwesome/solid/building.svg'
import {ReactComponent as Home} from '../../../assets/icons/fontAwesome/solid/home.svg'
import {ReactComponent as UserAstronaut} from '../../../assets/icons/fontAwesome/solid/user-astronaut.svg'
import {ReactComponent as Images} from '../../../assets/icons/fontAwesome/regular/images.svg'
import {ReactComponent as ArrowLeft} from '../../../assets/icons/fontAwesome/solid/arrow-left.svg'
import {ReactComponent as Play} from '../../../assets/icons/fontAwesome/solid/play.svg'
import {ReactComponent as Pen} from '../../../assets/icons/fontAwesome/solid/pen.svg'
import {ReactComponent as Times} from '../../../assets/icons/fontAwesome/solid/times.svg'
import {ReactComponent as Heart} from '../../../assets/icons/fontAwesome/solid/heart.svg'
import {ReactComponent as Archive} from '../../../assets/icons/fontAwesome/solid/archive.svg'
import {ReactComponent as Book} from '../../../assets/icons/fontAwesome/solid/book.svg'
import {ReactComponent as BookOpen} from '../../../assets/icons/fontAwesome/solid/book-open.svg'
import {ReactComponent as EllipsisH} from '../../../assets/icons/fontAwesome/solid/ellipsis-h.svg'
import {ReactComponent as Rename} from '../../../assets/icons/fontAwesome/solid/edit.svg'
import {ReactComponent as Copy} from '../../../assets/icons/fontAwesome/solid/copy.svg'
import {ReactComponent as Move} from '../../../assets/icons/fontAwesome/solid/expand-arrows-alt.svg'
import {ReactComponent as Door} from '../../../assets/icons/fontAwesome/solid/door-open.svg'
import {ReactComponent as SignOut} from '../../../assets/icons/fontAwesome/solid/sign-out-alt.svg'
import {ReactComponent as Desktop} from '../../../assets/icons/fontAwesome/solid/desktop.svg'
import {ReactComponent as Calendar} from '../../../assets/icons/fontAwesome/solid/calendar-alt.svg'
import {ReactComponent as Server} from '../../../assets/icons/fontAwesome/solid/server.svg'
import {ReactComponent as CalendarAlt} from '../../../assets/icons/fontAwesome/regular/calendar.svg'
import {ReactComponent as CalendarCheck} from '../../../assets/icons/fontAwesome/regular/calendar-check.svg'
import {ReactComponent as Lock} from '../../../assets/icons/fontAwesome/solid/lock.svg'
import {ReactComponent as Exclamation} from '../../../assets/icons/fontAwesome/solid/exclamation-circle.svg'

const icons = {
    plus: Plus,
    trash: Trash,
    building: Building,
    home: Home,
    userAstronaut: UserAstronaut,
    images: Images,
    arrowLeft: ArrowLeft,
    play: Play,
    edit: Pen,
    close: Times,
    heart: Heart,
    archive: Archive,
    book: Book,
    bookOpen: BookOpen,
    ellipsisH: EllipsisH, 
    rename: Rename,
    copy: Copy,
    move: Move,
    door: Door,
    signOut: SignOut,
    desktop: Desktop,
    calendar: Calendar,
    server: Server,
    calendarAlt: CalendarAlt,
    calendarCheck: CalendarCheck,
    calendarCheck: CalendarCheck,
    lock: Lock,
    exclamation: Exclamation
}

const Icon = (props) => {

    const classes = classNames(
        'icon',
        props.className
    )
    const Selection = icons[props.icon] ? icons[props.icon] : null;
    return (
        
        Selection && <Selection className={classes}/>
    );
}
 
export default Icon