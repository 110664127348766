import React from 'react'
import {uid} from 'react-uid';
import './Menu.scss'

const Menu = (props) => {
    return(
        <div className="menu">
            <ul>
                {props.children && props.children.map((e, i)=>{
                    return(
                        <li key={uid(e, i)}>{e}</li>
                    )
                })}
            </ul>
        </div>
    )
}
 
export default Menu