import React from 'react'
import Button from '../elements/Button'
import ConfirmationDialog from '../ConfirmationDialog'
import {useShowHide} from '../../hooks'


const ConfirmationButton = (props) =>{
    
    
    const {isShown, show, hide} = useShowHide()
 
    const handleOnClick = (e) =>{
        e.preventDefault()
        show('DELETE_CONFIRMATION')
    }

    const handleConfirm = () =>{      
    if(props.closeDialogOnConfirm){
        hide('DELETE_CONFIRMATION')
    }
        props.onClick && props.onClick()   
    }

    const handleCancel = () =>{
        hide('DELETE_CONFIRMATION')
    } 
    return (
        <React.Fragment>
            <ConfirmationDialog 
            img={props.dialogImg}
            message={props.dialogMessage} title={props.dialogTitle} 
            isOpen={isShown.DELETE_CONFIRMATION && !props.shouldHideDialog}
            onCancel={handleCancel}
            isLoading={props.dialogIsLoading}
            onConfirm={handleConfirm} />
            <Button {...props} onClick={handleOnClick} isLoading={props.isLoading}/>
        </React.Fragment>
    )
    
}

export default ConfirmationButton