import actionTypes from "../../actionTypes";
const initialState = {}

const setEntities = (state, action) =>{
    return {
        ...action.payload
    }
};
const setSuccessStatus = (state, action) =>{
    return {
        ...state,
        ressource: action.payload
    }
}

export const serviceRangeReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_SERVICE_RANGE_SUCCESS: return setEntities(state, action);
        case actionTypes.FETCH_REPORT_DATA_SUCCESS: return setEntities(state, action);
        case actionTypes.CREATE_REPORT_SUCCESS: return setEntities(state, action);
        case actionTypes.REQUEST_APPOINTMENT_SUCCESS: return setSuccessStatus(state, action)
        default: return state;
    }
};

