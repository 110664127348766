import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import 'moment/locale/de';
import 'react-day-picker/lib/style.css';
import './DayPicker.scss'
const DayPicker = (props) => {

    const handleChange = value =>{
        props.onDayChange && props.onDayChange(value)
    }
    
    return (
        <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="LL"
        className="input-field"
        value={props.value}
        placeholder={props.placeholder}
        inputProps={{ disabled: props.disabled, readOnly: true }}
        onDayChange={handleChange}
        dayPickerProps={{
        locale: 'de',
        localeUtils: MomentLocaleUtils,
        ...props.dayPickerProps
        }}  
        />
    );
}

DayPicker.defaultProps = {
    placeholder: "Tag auswählen"
}
 
export default DayPicker;