import React from 'react'
import './AlertBox.scss'
import Text from '../elements/Text'
const AlertBox = (props) => {
    return (
        <div style={props.style === 'danger' ? {backgroundColor: '#ea0000'} : {}} className="alert-box">
                <Text className="alert-label"> {props.children}</Text>
        </div> 
    );
}

 
export default AlertBox;