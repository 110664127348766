import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import EmptyState from "../../EmptyState";
import Field from "../Field";
import DayPicker from "../DayPicker";
import Select from "../Select";
import "./Table.scss";
import moment from "moment";

import Switch from "../../../components/elements/Switch";
import _ from "lodash";
import Text from "../Text";

const TextFilter = (props) => {
  const _filter =
    props.filtered && props.filtered.filter((e) => e.id === props.id)[0];
  const _value = _filter ? _filter.value : "";

  const handleChange = (value) => {
    props.onChange && props.id && props.onChange(props.id, value, props.global);
  };

  return (
    <Field
      plain
      onChange={handleChange}
      value={_value}
      placeholder={props.placeholder}
    />
  );
};

const SwitchFilter = (props) => {
  const _filter =
    props.filtered && props.filtered.filter((e) => e.id === props.id)[0];
  const _value = _filter ? _filter.value : "";

  const handleChange = (value) => {
    props.onChange && props.id && props.onChange(props.id, value, props.global);
  };

  return <Switch onChange={handleChange} />;
};

const SelectFilter = (props) => {
  const _filter =
    props.filtered && props.filtered.filter((e) => e.id === props.id)[0];
  const _value = _filter ? _filter.value : "";

  const options = [
    { value: "ALL_FILTER_*", label: "Alle" },
    ...(props.selectOptions || []),
  ];

  const handleChange = (value) => {
    props.onChange && props.id && props.onChange(props.id, value, props.global);
  };
  return (
    <Select
      placeholder={props.placeholder}
      isSearchable={false}
      value={_value}
      onChange={handleChange}
      options={options}
    />
  );
};
const DateFilter = (props) => {
  const _filter =
    props.filtered && props.filtered.filter((e) => e.id === props.id)[0];
  const _value = _filter ? _filter.value : "";
  const handleChange = (value) => {
    props.onChange && props.id && props.onChange(props.id, value, props.global);
  };
  return (
    <DayPicker
      format="LL"
      onDayChange={handleChange}
      name="_date"
      value={_value}
      inputProps={{ disabled: true }}
    />
  );
};

const getRowValue = (row, accessor) => {
  if (accessor) {
    if (typeof accessor === "string") {
      return row[accessor];
    } else {
      if (typeof accessor === "function") {
        return accessor(row._original) ? accessor(row._original) : false;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

const textSearchFilterMethod = (filter, row, accessor) => {
  const rowValue = getRowValue(row, accessor);
  if (!filter.value) {
    return true;
  } else if (typeof filter.value === "object" && !filter.value.value) {
    return true;
  } else if (rowValue) {
    if (typeof filter.value === "object") {
      return filter.value.value && typeof filter.value.value !== "object"
        ? String(rowValue.toLowerCase()).indexOf(
            String(filter.value.value.toLowerCase())
          ) > -1 || filter.value.value === "ALL_FILTER_*"
        : true;
    } else {
      return (
        String(rowValue.toLowerCase()).indexOf(filter.value.toLowerCase()) >
          -1 || filter.value === "ALL_FILTER_*"
      );
    }
  } else {
    return false;
  }
};
const switchSearchFilterMethod = (filter, row, accessor) => {
  const rowValue = getRowValue(row, accessor);
  return filter.value ? rowValue : true;
};

const dateSearchFilterMethod = (filter, row, accessor) => {
  const rowValue = getRowValue(row, accessor);
  if (rowValue) {
    return moment(filter.value).isSame(rowValue, "d");
  } else {
    return false;
  }
};

const filterMap = {
  text: {
    method: textSearchFilterMethod,
    component: TextFilter,
  },
  date: {
    method: dateSearchFilterMethod,
    component: DateFilter,
  },
  select: {
    method: textSearchFilterMethod,
    component: SelectFilter,
  },
  switch: {
    method: switchSearchFilterMethod,
    component: SwitchFilter,
  },
};

const filterMethodMap = {
  default: textSearchFilterMethod,
  textExact: (text) => {
    return true;
  },
};

const getFilterMethod = (filter) => {
  if (filter.method) {
    if (typeof filter.method === "function") {
      return filter.method;
    } else {
      return filterMethodMap[filter.method] || filterMethodMap["default"];
    }
  } else {
    const type = filter.type
      ? filterMap[filter.type]
        ? filter.type
        : "text"
      : "text";

    return filterMap[type].method;
  }
};

const getFilterComponent = (filter) => {
  if (filter.component) {
    return filter.component;
  } else {
    const type = filter.type && filterMap[filter.type] ? filter.type : "text";

    return filterMap[type].component;
  }
};

const test = (po) => {
  alert("dsjlkdfj");
};

const Table = (props) => {
  const [_columns, _setColumns] = useState([]);
  const [_filters, _setFilters] = useState([]);
  const [_filterMethods, _setFilterMethods] = useState([]);
  const [_filtered, _setFiltered] = useState([]);
  const [_externalFiltered, _setExternalFiltered] = useState([]);

  const _filter = (filter, row, column) => {
    if (_filterMethods[filter.id] && _filterMethods[filter.id].method) {
      return _filterMethods[filter.id].method(
        filter,
        row,
        _filterMethods[filter.id].accessor,
        _filterMethods[filter.id].options
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    const pivotFields = props.filters
      ? props.filters.map((e, i) => {
          return {
            id: `PIVOT_FILTER_${i}`,
            width: 0,
            resizable: false,
            sortable: false,
          };
        })
      : [];

    const externalPivotFields = props.externalFilters
      ? props.externalFilters.map((e, i) => {
          return {
            id: `EXXTERNAL_FILTER_${e.id}`,
            width: 0,
            resizable: false,
            sortable: false,
          };
        })
      : [];

    const filters = props.filters
      ? props.filters.map((e, i) => {
          return {
            ...e,
            id: `PIVOT_FILTER_${i}`,
          };
        })
      : [];

    const filterMethods = filters.reduce((acc, e) => {
      return {
        ...acc,
        [e.id]: {
          ...e,
          method: getFilterMethod(e),
        },
      };
    }, {});

    const externalFilterMethods = props.externalFilters
      ? props.externalFilters.reduce((acc, e) => {
          return {
            ...acc,
            [`EXXTERNAL_FILTER_${e.id}`]: {
              ...e,
              method: getFilterMethod(e),
            },
          };
        }, {})
      : [];

    if (props.fields) {
      _setColumns([...props.fields, ...pivotFields, ...externalPivotFields]);
    }

    _setFilters(filters);
    _setFilterMethods({ ...filterMethods, ...externalFilterMethods });
    if (props.externalFiltered) {
      const externalFiltered = props.externalFiltered.map((e) => {
        return {
          ...e,
          id: `EXXTERNAL_FILTER_${e.id}`,
          external: true,
        };
      });
      _setExternalFiltered(props.externalFiltered);
      _setFiltered(externalFiltered);
    }
  }, []);

  useEffect(() => {
    if (props.externalFiltered !== _externalFiltered) {
      _setExternalFiltered(props.externalFiltered);
      const local = _filtered.filter((e) => !e.external);
      const external = props.externalFiltered
        ? props.externalFiltered.map((e) => {
            return {
              ...e,
              id: `EXXTERNAL_FILTER_${e.id}`,
              external: true,
            };
          })
        : [];
      _setFiltered([...local, ...external]);
    }
  });

  const setFiltered = (id, value, global) => {
    const newfilter = {
      id,
      pivotId: id,
      value,
      global,
    };
    const filtersWhithoutNew = _filtered.filter((item) => item.id !== id);
    
    
    _setFiltered([...filtersWhithoutNew, newfilter]);
  };

  const clearFilters = () => {
    _setFiltered(_filtered.filter((e) => e.external));
  };

  return (
    <div className="buffl-table">
      {props.data && props.data.length > 0 ? (
        <div>
          <div>
            {/* <button onClick={clearFilters}></button> */}
            <div className="table-filter-wrapper">
              {_filters.map((e) => {
                const Component = getFilterComponent(e);

                return (
                  <div key={e.id} className="table-filter">
                    <Component
                      {...e}
                      onChange={setFiltered}
                      placeholder={e.label}
                      filtered={_filtered}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <ReactTable
            filtered={_filtered}
            minRows={10}
            showFilters={true}
            columns={_columns}
            defaultFilterMethod={_filter}
            onFilteredChange={(filtered) => {
              alert("filtered");
            }}
            {...props}
          >
            {(state, makeTable, instance) => {              
              return <div>
                {makeTable()}
                <div>
                    <br></br>
                    <Text textStyle="description">Anzahl: {state.sortedData && state.sortedData.length} / {state.data && state.data.length}</Text>
                </div>
              </div>
            }}
          </ReactTable>
        </div>
      ) : (
        <EmptyState
          title={props.emptyTitle}
          description={props.emptyDescription}
        />
      )}
    </div>
  );
};

{
  /* <TextFilter onChange={setFiltered} filters={_filtered} filterId="ciName"/>
        <DateFilter value={_filtered} onChange={setFiltered} global filters={_filtered} filterId="startDate"/>
        <SelectFilter onChange={setFiltered} filters={_filtered} filterId="status" options={options}/> */
}

export default Table;
