import React, { useState } from "react";
import Icon from "../../../components/elements/Icon";
import Field from "../../../components/elements/Field";
import Select from "../../../components/elements/Select";
import ErrorMessage from "../../../components/ErrorMessage";
import AlertBox from "../../../components/AlertBox";
import { FieldError } from "../../../components/FieldGroup";

import Button from "../../../components/elements/Button";
import { TimeSlotPicker } from "../../../components/elements/DayPicker";

import { checkMinDifference } from "../../../utils";
import "../hub.scss";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";

const Form = (props) => {
  const { t } = useTranslation();

  const {
    values,
    touched,
    errors,
    isValid,
    submitCount,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
  } = props;

  const handleChangeSlotChange = (field, value) => {
    setFieldTouched(field);
    setFieldValue(field, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="hub-row">
        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-block-icon variant1">
              <Icon icon="door"></Icon>
            </div>
          </div>
          <div className="hub-block-right">
            <h2>{t("hub:appointment.form.location")}</h2>
            <div className="hub-formarea">
              <div className="item">
                <label>{t("hub:appointment.form.chooseBuilding")}</label>
                <Select
                  skipSort
                  value={values.location.isCorrect}
                  placeholder="Auswählen"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                    setFieldTouched(name, value);
                  }}
                  name="location.isCorrect"
                  options={
                    props.ressource &&
                    props.ressource.location &&
                    props.ressource.location.siteOriginal
                      ? [
                          {
                            value: "YES",
                            label: props.ressource.location.siteOriginal,
                          },
                          { value: "NO", label: "Anderer Standort" },
                        ]
                      : [{ value: "NO", label: "Anderer Standort" }]
                  }
                  isSearchable={false}
                />
                <FieldError errors={errors} touched={touched} name="location.isCorrect" />
              </div>
              {values.location.isCorrect && values.location.isCorrect.value == "NO" && (
                <div>
                  <label>
                    {t("hub:appointment.form.otherInformation")}
                  </label>
                  <Field
                    fast
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    type="text"
                    name="location.otherInformation"
                    component="textarea"
                  />
                  <FieldError
                    errors={errors}
                    touched={touched}
                    name="location.otherInformation"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hub-row">
        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-block-icon variant1">
              <Icon icon="calendar"></Icon>
            </div>
          </div>
          <div className="hub-block-right">
            <h2>{t("hub:appointment.form.appointments")}</h2>
          </div>
        </div>

        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-circle variant3">1</div>
          </div>
          <div className="hub-block-right">
            <h3>{t("hub:appointment.form.migration")} </h3>
            <p>{t("hub:appointment.form.migrationText")}</p>
            <p>
              {t("hub:appointment.form.migrationText2")}{" "}
              <strong>{t("hub:appointment.form.migrationText3")}</strong>{" "}
              {t("hub:appointment.form.migrationText4")}
            </p>
            <TimeSlotPicker
              options={props.slots.change}
              name="slots.change"
              onChange={handleChangeSlotChange}
              value={values.slots.change}
            />
            <FieldError errors={errors} touched={touched} name="slots.change" />
          </div>
        </div>
      </div>
      <div className="hub-row">
        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-block-icon variant1">
              <Icon icon="server"></Icon>
            </div>
          </div>
          <div className="hub-block-right">
            <h2>{t("hub:appointment.form.others")}</h2>
            <div className="hub-formarea">
              <div>
                <label>{t("hub:appointment.form.otherNotes")}</label>
                <Field
                  fast
                  onChange={setFieldValue}
                  type="text"
                  name="comment"
                  component="textarea"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ErrorMessage
        errorKey="REQUEST_APPOINTMENT"
        message="{t('hub:appointment.form.error')}"
      />
      {!isValid && submitCount > 0 && (
        <AlertBox style="danger">
          {t("hub:appointment.form.errorAlert")}
        </AlertBox>
      )}
      <div className="submit">
        <Button
          onClick={handleSubmit}
          type="submit"
          isLoading={props.isLoading}
          size="large"
          variant="default-secondary"
          borderStyle="round"
          height="35"
          spaced="true"
        >
          {t("hub:appointment.form.submit")}
        </Button>
      </div>
    </form>
  );
};

const MakeAppointmentForm = (props) => {
  const emptyOption = null;

  const validate = (values) => {
    let errors = {};

    if (!values.slots.change) {
      errors = {
        ...errors,
        slots: {
          ...errors.slots,
          change: "Bitte Termin für Umstellung wählen.",
        },
      };
    }
    if(!values.location.isCorrect){
        errors = {
            ...errors,
            location: {
                isCorrect: "Bitte auswählen."
            }
        }
    }  

    if(values.location.isCorrect && values.location.isCorrect.value === "NO" && !values.location.otherInformation){
        errors = {
            ...errors,
            location: {
                otherInformation: "Bitte Standort angeben"
            }
        }
    }

    console.log(errors)

    return errors;
  };

  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      
      const result = {
        ...values,
        slots: {
          change: values.slots.change.startDate,
          // restore: values.slots.restore.startDate,
        },
        location:{            
            otherInformation: values.location.otherInformation,            
        },
        comment: values.comment,
      };
      props.onSubmit(result);
    },
    validate: validate,
    mapPropsToValues: ({ ressource }) => ({
      slots: {
        save: emptyOption,
        change: emptyOption,
        // restore:emptyOption
      },
      comment: "",
      location: {
        isCorrect: ""
      },
      usage: { value: "DEFAULT", label: "Office (Standard)" },
      software: [],
      otherUsers: [],
    }),
  })(Form);
  return <EnhancedForm {...props} />;
};

export default MakeAppointmentForm;
