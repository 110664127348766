import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {fetchUsers, fetchRessources, fetchServiceRanges} from "../../../store/actions";
import MainContentWrapper from '../../../components/MainContentWrapper'
import SubNavigation from '../../../components/SubNavigation'
import CreateRangeDialog from '../../../components/CreateRangeDialog'

const navSectionsMocks = [
    {
        
        links: [
            {
                title:"Meine Termine", 
                icon: 'calendarAlt',
                to:"/app/home/my-appointments"
            },
            {
                title:"Alle Termine", 
                icon: 'calendar',
                to:"/app/home/appointments"
            },
            {
                title:"Rechner", 
                icon: 'desktop',
                to:"/app/home/machines"
            },
            {
                title:"Erledigt", 
                icon: 'calendarCheck',
                to:"/app/home/appointments-done"
            },
            {
                title:"Zeitfenser", 
                icon: 'calendar',
                to:"/app/home/service-ranges"
            }
        ]
    }
]
const HomeLayout = (props) => { 
    useEffect(()=>{
        props.fetchRessources()
        props.fetchUsers()
        props.fetchServiceRanges()
    }, [])

    const [_showCreateRangeDialog, _setShowCreateRangeDialog] = useState(false)
    return (
        <React.Fragment>
            <CreateRangeDialog 
            isOpen={_showCreateRangeDialog}
            onRequestClose={()=>{_setShowCreateRangeDialog(false)}}
            />
            <SubNavigation 
            title="Workspace" 
            sections={navSectionsMocks}
            bottomBtnTitle=""
            onBottomBtnClick={()=>{_setShowCreateRangeDialog(true)}}
            bottomBtnTitle="Termine öffnen"
            />
            
            {props.children}
        </React.Fragment>
    );
}

const mapDispatchToProps = dispatch =>({
    fetchUsers: () => dispatch(fetchUsers()),
    fetchRessources: () => dispatch(fetchRessources()),
    fetchServiceRanges: () => dispatch(fetchServiceRanges()),
})

export default connect(null, mapDispatchToProps)(HomeLayout);