import {colors} from './colors'
import {width, space, breakpoints, heights} from './layout'
import {fontSizes, fonts, fontWeights,letterSpacings, textStyles, lineHeights} from './typography' 
import {shadows, shadowPairs, borderRadius, radii} from './other'
import {buttons} from './buttons'

const theme = {
    colors,
    width,
    space,
    fontSizes,
    fonts,
    fontWeights,
    letterSpacings,
    shadows,
    shadowPairs,
    textStyles,
    radii,
    buttons,
    breakpoints,
    borderRadius,
    heights,
    lineHeights
};
 

export default theme



