import actionTypes from '../actionTypes'
import {ressourcesApi} from '../../api'
import {setEvent} from './uiActions'
import {ressourcesNormalilzer, ressourceNormalilzer} from '../normalizers'
import {reportError} from '../../utils'

const fetchRessourcesStart = () => {
    return {
        type: actionTypes.FETCH_RESSOURCES_START
    }
}

const fetchRessourcesSuccess = (data) =>{
    const entities = ressourcesNormalilzer(data)
    return {
        type: actionTypes.FETCH_RESSOURCES_SUCCESS,
        payload: entities
    }
}


const fetchRessourcesError = (error) =>{
    
    return {
        type: actionTypes.FETCH_RESSOURCES_ERROR,
        payload: error
    }
}

export const fetchRessources = () => async dispatch => {
    dispatch(fetchRessourcesStart())
    try{
        const response = await ressourcesApi.getAll()
        dispatch(fetchRessourcesSuccess(response))
    }catch(error){
        reportError(actionTypes.FETCH_RESSOURCES_ERROR, error)
        dispatch(fetchRessourcesError(error))
    }
    
};

const updateRessourceStart = id => {
    return {
        type: actionTypes.UPDATE_RESSOURCE_START,
        id
    }
}
export const updateRessourceSuccess = (id, data) => {
    const entities = ressourceNormalilzer(data)

    return {
        type: actionTypes.UPDATE_RESSOURCE_SUCCESS,
        id,
        payload: entities
    }
}

const updateRessourceError = (id, error) => {
    
    return {
        type: actionTypes.UPDATE_RESSOURCE_ERROR,
        payload: {
            error
        } 
    }
}

export const updateRessource = (id, data) => async dispatch =>{
    dispatch(updateRessourceStart(id))
    try{
        const response = await ressourcesApi.update(id, data)
        dispatch(updateRessourceSuccess(id, response))
        dispatch(setEvent(`UPDATE_RESSOURCE_SUCCESS`))
    }catch(error){
        reportError(actionTypes.UPDATE_RESSOURCE_ERROR, error)
        dispatch(updateRessourceError(error))
    }
}

export const updateRessourceStatus = (id, data) => async dispatch =>{
    dispatch(updateRessourceStart(id))
    try{
        const response = await ressourcesApi.updateStatus(id, data)
        dispatch(updateRessourceSuccess(id, response))
    }catch(error){
        reportError(actionTypes.UPDATE_RESSOURCE_ERROR, error)
        dispatch(updateRessourceError(error))
    }
}


const addCommentStart = id => {
    return {
        type: actionTypes.ADD_COMMENT_START,
        id
    }
}
export const addCommentSuccess = (id, data) => {
    return {
        type: actionTypes.ADD_COMMENT_SUCCESS,
        id,
        payload: data.data.comment
    }
}

const addCommentError = (id, error) => {
    
    return {
        type: actionTypes.ADD_COMMENT_ERROR,
        payload: {
            error
        } 
    }
}

export const addComment = (id, data) => async dispatch =>{
    dispatch(addCommentStart(id))
    try{
        const response = await ressourcesApi.addComment(id, data)
        dispatch(addCommentSuccess(id, response))
        dispatch(setEvent(`ADD_COMMENT_SUCCESS`))
    }catch(error){

        reportError(actionTypes.ADD_COMMENT_ERROR, error)
        dispatch(addCommentError(error))
    }
}



const addContactAttemptStart = id => {
    return {
        type: actionTypes.ADD_CONTACT_ATTEMPT_START,
        id
    }
}
export const addContactAttemptSuccess = (id, data) => {
    const entities = ressourceNormalilzer(data)
    return {
        type: actionTypes.ADD_CONTACT_ATTEMPT_SUCCESS,
        id,
        payload: entities
    }
}

const addContactAttemptError = (id, error) => {
    return {
        type: actionTypes.ADD_CONTACT_ATTEMPT_ERROR,
        payload: {
            error
        } 
    }
}

export const addContactAttempt = (id, data) => async dispatch =>{
    dispatch(addContactAttemptStart(id))
    try{
        const response = await ressourcesApi.addContactAttempt(id, data)
        dispatch(addContactAttemptSuccess(id, response))
        dispatch(setEvent(`ADD_CONTACT_ATTEMPT_SUCCESS`))
    }catch(error){
        reportError(actionTypes.ADD_CONTACT_ATTEMPT_ERROR, error)
        dispatch(addContactAttemptError(error))
    }
}


const openReportStart = id => {
    return {
        type: actionTypes.OPEN_REPORT_START,
        id
    }
}
export const openReportSuccess = (id, data) => {
    const entities = ressourceNormalilzer(data)

    return {
        type: actionTypes.OPEN_REPORT_SUCCESS,
        id,
        payload: entities
    }
}

const openReportError = (id, error) => {
    
    return {
        type: actionTypes.OPEN_REPORT_ERROR,
        payload: {
            error
        } 
    }
}

export const openReport = (id) => async dispatch =>{
    dispatch(openReportStart(id))
    try{
        const response = await ressourcesApi.openReport(id)
        dispatch(openReportSuccess(id, response))
        dispatch(setEvent(`UPDATE_REPORT_SUCCESS`))
    }catch(error){
        reportError(actionTypes.OPEN_REPORT_ERROR, error)
        dispatch(openReportError(error))
    }
}