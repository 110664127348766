import {useEffect} from 'react';
import { connect } from "react-redux";
import { selectEvent } from "../../store/selectors";
import { unSetEvent } from "../../store/actions/uiActions";

const ReduxObserver = (props) => {

    useEffect(()=>{ 
        if(props.shouldExecute){
            props.onEvent && props.onEvent()
            props.unSetEvent(props.event)
        }
    }, [props.shouldExecute])

    return (
        null
    );
}

const mapStateToProps = (state, props) =>({
    shouldExecute: selectEvent(state, props.event)
})
const mapDispatchToProps = dispatch => ({
    unSetEvent: (key) =>{dispatch(unSetEvent(key))}
})

export default connect(mapStateToProps, mapDispatchToProps)(ReduxObserver);
