import React from 'react';
import './HubLayout.scss';

const HubLayout = ({children}) => {
    return (
        <React.Fragment>
        <div className="hublayout-background">
        </div>
        <div className="hublayout-wrapper">
            <div className="hub-layout-topbar">
                <div className="hub-layout-tobar-left">
                    
                </div>
                <div className="hub-layout-tobar-right">

                </div>
            </div>
            <div className="hublayout-page">
            {children} 
            </div>
        </div>
        </React.Fragment>
    );
}
 
export default HubLayout;