import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {selectRessources, selectOverDueRessources} from '../../../../store/selectors'
import RessourcesTable from '../../../../components/RessourcesTable';
import AppointmentDueRessourcesTable from '../../../../components/AppointmentDueRessourcesTable';
import MainHeaderWrapper from '../../../../components/MainHeaderWrapper'
import MainContentWrapper from '../../../../components/MainContentWrapper';
import H3 from '../../../../components/elements/H3'
import RessourceDetailDrawer from '../../../../components/RessourceDetailDrawer'
import Tabs, {Tab} from '../../../../components/Tabs'


const RessourcesPage = (props) =>{

    const [_selected, _setSelected] = useState()
    
 
    const handleRowSelect = rowInfo =>{
        _setSelected(rowInfo)
    }

    const handleCloseDrawerRequested = () =>{
        _setSelected()
    }


    return (
        <React.Fragment>
            <MainContentWrapper>
            <MainHeaderWrapper>
                <H3
                lineHeight="40px"
                >Rechner</H3>
            </MainHeaderWrapper>
            <Tabs>
                <Tab title="Alle Rechner">
                    <RessourcesTable selected={_selected && _selected.index} onRowSelect={handleRowSelect} data={props.ressources}/>
                </Tab>
                <Tab title="Terminprobleme">
                    <AppointmentDueRessourcesTable selected={_selected && _selected.index} onRowSelect={handleRowSelect} data={props.overDueRessources}/>
                </Tab>
            </Tabs>
            
            </MainContentWrapper>
            <RessourceDetailDrawer 
            id={_selected && _selected.original.id}
            onRequestClose={handleCloseDrawerRequested}
            />
        </React.Fragment>
    )
}

const mapStateToProps = state =>({
    ressources: selectRessources(state),
    overDueRessources: selectOverDueRessources(state),
})
export default connect(mapStateToProps, null)(RessourcesPage)