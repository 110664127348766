import React from 'react';
import GlobalNavigationItem from "../GlobalNavigationItem";
import '../GlobalNavigation.scss'

const GlobalNavigationMainSection = (props) => {
    return (
        <div className="global-navigation-main">
            <GlobalNavigationItem to="/app/home" title="Bibliothek" icon="home"/>
            <GlobalNavigationItem to="/logout" title="logout" icon="signOut"/>
            {/* <GlobalNavigationItem to="/app/settings" title="Einstellungen" icon="userAstronaut"/> */}
            
        </div>
    );
}
 
export default GlobalNavigationMainSection;

