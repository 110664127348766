import React from 'react'
import Avatar from './Avatar'



const getInitialien = (user) =>{
    if(!user){
        return null
    }
    const getInitial = (name)=>{
        return name ? name.charAt(0) : null
    }
    const first = getInitial(user.firstName)
    const second = getInitial(user.lastName)
    return first ? second ? first.concat(second) : first : second ? second : getInitial(user.handle);
}

export const UserAvatar = ({user, text: propText, ...rest})=>{
    
    const text = getInitialien(user)
    const image = user && user.image
    const icon = !image && !text ? 'userAstronaut' : null;
    console.log(icon)
    return(
        <Avatar text={text}  image={image} icon={icon} {...rest} />
    )
    
}
UserAvatar.defaultProps = {
    size: '40',
    
};