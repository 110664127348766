import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {logoutUser} from '../../store/actions'
const Logout = (props) => {

    useEffect(()=>{
        props.logoutUser()
    },[])
    return(null)
}   

const mapDispatchToProps = dispatch =>({
    logoutUser: () => dispatch(logoutUser())
})

export default connect(null, mapDispatchToProps)(Logout);

