import React from 'react'
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom'
import Icon from '../../elements/Icon'

const SubNavigationItem = (props) => {
    return (
        <div>
            <NavLink to={props.to ? props.to : '/courses/fjdsh'} exact={props.exact} className="subnav-link">
                {props.icon && <span className="subnav-icon-wrapper"><Icon icon={props.icon}></Icon></span>} 
                {props.title}
            </NavLink>
        </div>
    );
}

SubNavigationItem.defaultProps = {
    link: '/',
    title: 'Link',
}
 
SubNavigationItem.propTyps ={
    ...NavLink.propTyps,
    title: PropTypes.string
};

export default SubNavigationItem;