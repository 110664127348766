import React, {useState} from 'react';
import {connect} from 'react-redux'
import Modal from '../elements/Modal'
import {DialogBtnFooter, DialogHeader, DialogContent,DialogSuccessContent} from '../Dialog'
import { withFormik } from 'formik';
import Select from '../elements/Select'
import Field from '../elements/Field'
import DayPicker from '../elements/DayPicker'
import Switch from '../elements/Switch'
import {selectServiceRangeConfig, selectIsLoading} from '../../store/selectors'
import {createServiceRange, createServiceRangeForRessource} from '../../store/actions'
import './CreateRangeDialog.scss'
import {checkIfIsAfter} from "../../utils";
import ReduxObserver from '../ReduxObserver'
import validator from 'validator';





const Form = props =>{
    
    const [_prevSite, _setPrevSite] = useState("")
    const {
        values,
        touched,
        errors,
        isValid,
        handleChange,
        setFieldValue,
        handleSubmit,
        submitForm
      } = props;

      const handleSiteChange = (field, value) =>{
        const newValue = value && value.value || '';
        const shouldResetDependent = newValue !== _prevSite;

        _setPrevSite(newValue)
        setFieldValue(field, value)
        if(shouldResetDependent){
            setFieldValue('location.building', null)
        }
    }
      return (
        <React.Fragment>
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <div className="create-range-dialog">
                {props.ressource ?
                <p>Bitte Zeitraum auswhählen.</p>
                :
                <p>Bitte Werk, Gebäude und Zeitraum auswhählen.</p>
                }
                    {!props.ressource && <div className="information-block">
                        <div className="item">
                            <label>Werk auswählen</label>
                            <Select
                            name="location.site"
                            value={values.location.site} 
                            isSearchable={true} 
                            options={[...props.config.locations.sites.map(e=>({
                                value: e,
                                label: e === 'OTHER_SITE' ? 'Unbekannt' : e
                            })), {value: 'ALLE_WILDCARD', label: 'Alle'}]}
                            onChange={handleSiteChange}
                            />
                        </div>
                        <div className="item">
                            <label>Anzahl von Rechnern</label>
                            <Field type="text" name="limit" />
                        </div>
                  
                    </div>}
                    
                    <div className="information-block">
                        <div className="item">
                            <label>Startdatum</label>
                            <DayPicker
                            format="LL"
                            onDayChange={(value)=>{setFieldValue('startDate', value)}}
                            name="startDate"
                            value={values.startDate}
                            placeholder={props.placeholder}
                            dayPickerProps ={
                                {
                                fromMonth: new Date,
                                }
                            }
                            inputProps={{disabled: true}}                 
                            />
                        </div>
                        <div className="item">
                            <label>Enddatum</label>
                            <DayPicker
                            format="LL"
                            name="endDate"
                            value={values.endDate}
                            onDayChange={(value)=>{setFieldValue('endDate', value)}}
                            placeholder={props.placeholder}
                            dayPickerProps ={
                                {
                                fromMonth: new Date(),
                                }
                            }
                            inputProps={{disabled: true}}                 
                            />
                            
                        </div>
                    </div>
                    {props.ressource && <div className="information-block">
                        <div className="item">
                            <label>User per Mail benachrichtigen</label>                            
                            <Switch name="sendMail" isChecked={values.sendMail} onChange={setFieldValue}/>                        
                        </div>
                     
                    </div>
                    }
                 
                </div>
            </DialogContent>
            <DialogBtnFooter
                rightBtnTitle="Speichern"
                isLoading={props.isLoading}
                leftBtnTitle="Cancel"
                onRightBtnClick={submitForm}
                onLeftBtnClick={props.onRequestClose}
                rightBtnDisabled={!isValid}
                leftBtnDisabled={false}  
                />
        </form>
        
        </React.Fragment>
      )
}



const CreateRangeDialog = (props) => {


    const [_showSuccessMessage, _setShowSuccessMessage] = useState(false)

    const validate = (values)=>{
        let errors = {};
        
        if(!props.ressource){
           
            if(values.limit && (!validator.isInt(values.limit) || parseInt(values.limit) === 0)){
                errors = {
                    ...errors, 
                    limit: 'Zahl oder NO_LIMIT'
                }
            }
        }
        
        if(!values.startDate){
            errors = {
                ...errors,
                startDate: 'Startdatum wählen'
            }
        }else if(checkIfIsAfter(new Date(), values.startDate)){
            errors = {
                ...errors,
                startDate: 'Datum muss in Zukunft liegen'
            }
        }
        if(!values.endDate){
            errors = {
                ...errors,
                endDate: 'Enddatum wählen'
            }
        }
        
        if(values.startDate && values.endDate && checkIfIsAfter(values.startDate, values.endDate)){
            errors = {
                ...errors,
                endDate: 'Darf nicht vor Startdatum liegen'
            }
        } 
    
        return errors
    
    }

    const emptyOption = {value:"", label:""}
    const EnhancedForm = withFormik({
        handleSubmit: (values)=>{
            let location = {                
            }
            if(values.location.site.value !== 'ALLE_WILDCARD'){
                location = {
                    ...location,
                    site: values.location.site.value
                } 
        
            }

            const result = {
                ...values, 
                limit: values.limit ? values.limit : "NO_LIMIT",
                location
            }

            console.log(result)

            props.ressource ? props.createServiceRangeForRessource(props.ressource.id, {startDate: values.startDate, endDate: values.endDate, sendMail: values.sendMail})
            : props.createServiceRange(result)
        },
        validate: validate,
        mapPropsToValues: ({ressource}) => ({
        location: {
            site: emptyOption,
            building:  emptyOption
        },
        startDate: "",
        endDate: "",
        limit: null,
        sendMail: true
        }),
        })(Form)
    return (
        <Modal 
        onRequestClose={()=>{
            props.onRequestClose()
            _setShowSuccessMessage(false)
        }}
        isOpen={props.isOpen}>
            <ReduxObserver event="CREATE_SERVICE_RANGE" onEvent={()=>{_setShowSuccessMessage(true)}}/>
            
            
            {_showSuccessMessage ?
            <DialogSuccessContent 
            title="Erfolgreich"
            message="Rechner wurden zur Terminierung freigegeben."
            onRequestClose={()=>{
                props.onRequestClose()
                _setShowSuccessMessage(false)
            }}/>
            :
            <React.Fragment>
                <DialogHeader 
                title="Termine freigeben"
                onCloseBtnClick={props.onRequestClose}/> 
                <EnhancedForm {...props}/>
            </React.Fragment>
            }
        </Modal>
    );
}

const mapDispatchToProps = dispatch => ({
    createServiceRange: (data) => dispatch(createServiceRange(data)),
    createServiceRangeForRessource: (id, data) => dispatch(createServiceRangeForRessource(id, data))
})

const mapStateToProps = (state) =>({
    config: selectServiceRangeConfig(state),
    isLoading: selectIsLoading(state, "CREATE_SERVICE_RANGE"),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateRangeDialog);