import React from 'react'
import BaseTooltip from '@atlaskit/tooltip';



const Tooltip = (props) => {
    
    return (          
        <BaseTooltip position="right" content="Hello World">
            {props.children}
        </BaseTooltip>
    );
}

Tooltip.defaultProps ={
    effect: "solid"
};
 
export default Tooltip;