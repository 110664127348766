import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import HubLayout from '../../../hoc/Layouts/HubLayout';
import Icon from '../../../components/elements/Icon'
import AlertBox from '../../../components/AlertBox'
import {fetchServiceRange, requestAppointment} from '../../../store/actions'
import {selectServiceRangeRessource, selectServiceRangeSlots, selectIsLoading, selectServiceRangeConfig, selectServiceRangeStatus, isAuthenticated} from '../../../store/selectors'
import logo from '../../../assets/ci/logo.png'
import MakeAppointmentForm from './MakeAppointmentForm'
import './MakeAppointmentHub.scss';
import '../hub.scss';
import i18n from './../../../i18n';
import { useTranslation  } from 'react-i18next';
import LanguageSwitch from '../../../components/languageSwitch';
import ConfirmationDialog from '../../../components/ConfirmationDialog'


const Sidebar = (props) =>{
    const { t } = useTranslation();
    return (
        <div className="hub-sidebar">
                <div className="hub-sidebar-inner">
                    <div className="hub-sidebar-logo">
                        <img src={logo} alt="robot" />
                    </div>
               
                    <div className="hub-sidebar-text  ">
                    <h1>{t('hub:appointment.sidebar.title')}</h1>
                    <h2>{t('hub:appointment.sidebar.subheading')}</h2>
                    <p>{t('hub:appointment.sidebar.welcomeText')}</p>
                    <p>{t('hub:appointment.sidebar.contact')} <a href="mailto:rollout@int-group.de">rollout@int-group.de</a> {t('hub:appointment.sidebar.contact2')}</p>
                    <div>
                    </div>
                    
                    </div>                                    
                <LanguageSwitch />
                </div>
                </div>
    )
}



const MakeAppointmentHub = (props) => {
     useEffect(()=>{
            props.fetchServiceRange(props.match.params.token)         
    }, [])

    const handleSubmit = (data)=>{
        props.requestAppointment(props.ressource.id, data)
    }
    const { t } = useTranslation();
    
    return (
        <HubLayout>
            {true && <div className="hub">
                <Sidebar {...props}/>
                <div className="hub-content">
                    <div className="hub-row">
                        <div className="hub-block">
                            <div className="hub-block-left">
                                <div className="hub-block-icon variant1">
                                    <Icon icon="desktop"></Icon>
                                </div>
                            </div>
                                <div className="hub-block-right">
                                <h2>{t('hub:appointment.form.information')}</h2>
                                <div className="information-block">
                                    <div className="item">
                                        <label>{t('hub:appointment.form.user')}</label>
                                        <span>{props.ressource && props.ressource.customer && props.ressource.customer.firstName + ' ' + props.ressource.customer.lastName}</span>
                                    </div>
                                    <div className="item">
                                        <label>{t('Host Name')}</label>
                                        <span>{props.ressource && props.ressource.ciName}</span>
                                    </div>
                                    <div className="item">
                                        <label>{t('Seriennummer')}</label>
                                        <span>{props.ressource && props.ressource.serialNumber}</span>
                                    </div>
                                    <div className="item">
                                        <label>{t('hub:appointment.form.model')}</label>
                                        <span>{props.ressource && props.ressource.deviceModelName}</span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
                    {!props.ressource ? null
                        :
                        props.ressource.status ==="WAITING_FOR_APPOINTMENT" 
                        ? <MakeAppointmentForm 
                        slots={props.slots} 
                        ressource={props.ressource}
                        config={props.config} 
                        isLoading={props.isLoading}
                        onSubmit={handleSubmit}
                        />
                        :
                        props.ressource.status ==="APPOINTED"
                         ? <AlertBox>{t('hub:appointment.form.alertAppointmentSuccess')}</AlertBox>
                         :<AlertBox>{t('hub:appointment.form.alertAppointmentInquired')}</AlertBox>
                        
                    }                    
                </div>
            </div>}
        </HubLayout>
    );
}

const mapDispatchToProps = dispatch =>({
    fetchServiceRange: (token) => dispatch(fetchServiceRange(token)),
    requestAppointment: (id, data) => dispatch(requestAppointment(id, data))
})

const mapStateToProps = (state, props) =>({
    ressource: selectServiceRangeRessource(state),
    slots: selectServiceRangeSlots(state),
    config: selectServiceRangeConfig(state),
    isFetching: selectIsLoading(state, "FETCH_SERVICE_RANGE"),
    isLoading: selectIsLoading(state, "REQUEST_APPOINTMENT"),
    status: selectServiceRangeStatus(state),
    isAuthenticated: isAuthenticated(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(MakeAppointmentHub);