import React from 'react'

import Text from '../../../components/elements/Text'
import LoginForm from './LoginForm'

import '../auth.scss'
const LoginPage = (props) =>{

  

    return(
        <div>
            <div className="auth-header">
                <Text textStyle="headline">Anmelden</Text>
            </div>
            <LoginForm/>
        </div>
    )
}


export default LoginPage