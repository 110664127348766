import {AbilityBuilder} from '@casl/ability'

const subjectName = subject => (!subject || typeof subject === 'string' ? subject : subject.SUBJECT_NAME)

export const defineAbilitiesFor = authState =>{
    return AbilityBuilder.define({subjectName}, (allow, forbid) => {
        if(authState.user){
            switch (authState.user.baseRole) {
              case "USER":                
              case "MANAGER":
              case "ADMIN":
                  allow('create', 'ServiceRange')
                  allow('manage', 'Appointment')
                  allow('update', 'Ressource_user')
                  allow('set', 'Ressource_status_DONE')
                  allow('set', 'Ressource_status_HANDLED_EXTERNAL')
                  allow('set', 'Ressource_status_CONFIRMED_BY_USER')
                  allow('set', 'Ressource_status_APPOINTED')
                  allow('set', 'Ressource_status_OPEN')
              break;
              default:
              break;
            }
        }
    })
}
 
export const getSubject = (name, instance) =>(instance ? {...instance, SUBJECT_NAME: name} : name)
