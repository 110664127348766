import React from 'react'
import './authLayout.scss'

const AuthLayout = ({children}) => {
    return (

        <React.Fragment>
            <div className="auth-layout-background">
                
            </div>
            <div className="auth-layout-wrapper">
                <div className="auth-layout-page">
                {children} 
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default AuthLayout;