import React from 'react'

import './MainHeaderWrapper.scss'

const MainHeaderWrapper = (props)=>{
    return (
        <div className="main-header-wrapper">
            {props.children}
        </div>
    )
}

export default MainHeaderWrapper

