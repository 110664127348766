export const en = {
    translations: {
        buttons: {
            save: "Save"
        }
    }, 
    app: {
        library: {
            title: "Library"
        }
    }, 
    landing: {
        
    }, 
    marketplace: {
        
    }, 
    hub: {
        appointment: {
            sidebar: {
            disabled:{
                title: "Nicht verfügbar",
                description: "Leider können wir aktuell keine online Terminvereinbarungen mehr anbieten. Wir werden uns zeitnah mit Ihnen in Verbindung setzen. Wir bitten um Ihr Verständniss."
            },
            title: "Make Appointment", 
            subheading: "Hardware Exchange 2024 Zeppelin",
            welcomeText: "Please fill out the form below to make an appointment to receive your new hardware.",
            contact: "If you have any questions, please do not hesitate to contact us at any time at",
            contact2: ".",
            approvalTitle: "Pending confirmation",
            approvalText: "Thank you very much for your time! Since you have entered a different location, we cannot confirm your appointments yet. We will contact you shortly.",
            appointedTitle: "Appointment arranged",
            appointedText: "The appointments have been successfully registered. You will shortly receive a confirmation by e-mail."
            },
            form: {
                information: "Information",
                user: "User",
                computerName: "Computer Name",
                manufacturer: "Manufacturer",
                model: "Modell",
                loading: "Loading",
                alertAppointmentSuccess: "Appointment was successfully arranged.",
                alertAppointmentInquired: "Appointment was successfully requested.",
                location: "Location",
                choosePlant: "Choose plant",
                chooseBuilding: "Choose building",
                floor: "Floor",
                roomNumber: "Room number",
                otherInformation: "Please provide your location:",
                appointments: "Appointments",
                migration: "Update",
                migrationText: "Please select a date to shedule an appointment. This will be carried out by a service technician on site and will take approx. 30 minutes.",
                migrationText2: "During the change, it is",
                migrationText3: "not",
                migrationText4: "possible to work on the device!",
                backup: "Data Backup",
                backupText: "The date for the data backup must be before the update. This is done via telephone with a technician and takes about 15 minutes.",
                notes: "Notes",
                notesText1: "The update can only be carried out after a successful data backup!",
                notesText2: "As soon as the data backup has been started, you ",
                notesText3: "cannot work on the device until finished.",
                notesText4: "After the backup you must not store or change any more data on C: .",
                notesText5: "The duration of the data backup depends on the amount of local (hard disk \"C:\") data.",
                notesText6: "It is also possible to start the backup independently via an automatic backup tool. You will receive this link in an e-mail after confirmation of the appointments.",
                recovery: "Restore",
                recoveryText1: "The restore takes place after the update to restore your data.",
                recoveryText2: "This takes about 15 - 30 minutes and is done via phone.",
                recoveryText3: "The profile settings are set up at the same time.",
                others: "Other",
                computerType: "Type of computer",
                multipleUsers: "Are several users working on the device?",
                otherUserNr: "Add the employee registration numbers of the other users:",
                software: "Software packages (optional)",
                otherNotes: "Other remarks (optional)",
                error:"Unfortunately, a problem has occurred. Please try again or reload the page.",
                errorAlert: "Please fill in all mandatory fields (marked with a red box)!",
                submit: "Submit"
            }
        },
        form: {
            sidebar: {
            title: "Data Questionaire", 
            subheading: "ZF Friedrichshafen AG",
            welcomeText: "Please fill out the form below to update your computers information.",
            contact: "If you have any questions, please do not hesitate to contact us at any time at",
            contact2: ".",
            appointedTitle: "Update Successfull",
            appointedText: "Thank you for your time! Your data has successfully been updated."
            },
            form: {
                information: "Information",
                user: "User",
                computerName: "Computer Name",
                manufacturer: "Manufacturer",
                category: "Device Category",
                model: "Modell",
                loading: "Loading",
                alertAppointmentSuccess: "Data was alredy updated.",
                location: "Location",
                choosePlant: "Choose plant",
                chooseBuilding: "Choose building",
                floor: "Floor",
                roomNumber: "Room number",
                otherInformation: "Other Information",
                error:"Unfortunately, a problem has occurred. Please try again or reload the page.",
                errorAlert: "Please fill in all mandatory fields (marked with a red box)!",
                submit: "Submit",
                replace: "Device Replacement"
            }
        }
    }
}