import React from 'react';
import Text from '../elements/Text'

import './FieldGroup.scss'

const getNested = (theObject, path, separator) =>{
    try {
        separator = separator || '.';
    
        return path.
                replace('[', separator).replace(']','').
                split(separator).
                reduce(
                    function (obj, property) { 
                        return obj[property];
                    }, theObject
                );
                    
    } catch (err) {
        return undefined;
    }   
}

export const FieldError = (props) =>{
    const touched = getNested(props.touched, props.name)
    const error = getNested(props.errors, props.name)
    
    return(
        touched && error ?
            <div className="field-error-wrapper">
            <div className="error">
                <Text className="error-label" >{props.message || error}</Text>
    
            </div>
        </div> : null
    
    )
}

const FieldGroup = (props) => {
    
    
    
    return (
        <div className="field-group">
            <Text className="label">{props.title}</Text>
            {props.field}
            {props.error &&
            <div className="error-wrapper">
                <div className="error">
                    <Text className="error-label">{props.errorMessage}</Text>
        
                </div>
            </div>}
        </div>
    );
}
export default FieldGroup;
