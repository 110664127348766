import React from 'react';
import classNames from 'classnames'
import Icon from '../elements/Icon'
import './Avatar.scss'


function getRandom() {
    const num = Math.floor(Math.random() * (5 - 0) + 0)
    return num 
  }

const Avatar = React.memo((props) => {

    const variant = props.variant != "rainbow" ? props.variant : `rainbow-${getRandom()}`;

    const classes = classNames(
        'avatar',
        props.className,
        {   [`avatar-style-${variant}`]: props.variant && props.variant !== 'inherit' && !props.plain,
            'avatar-squared': props.shape && props.shape === 'squared',
            'avatar-img': props.image,
            [`avatar-size-${props.size}`]: props.size && props.size !== "inherit",
            [`avatar-font-size-${props.size}`]: props.fontSize,
        }
    )
    
    return (
        <div className={classes}>
        
            {!props.image ? props.icon ? 
                  <Icon icon={props.icon}/>
                : props.text 
                : <img alt="User" src={props.image}/>}
        </div>
    );
})

Avatar.defaultProps = {
    variant: 'default',
    size: '40'
};
 
export default Avatar;