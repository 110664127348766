import React from 'react'
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom'
import Icon from '../../elements/Icon'
import Tooltip from '../../Tooltip'
import Button from '../../elements/Button'

import '../GlobalNavigation.scss'


const GlobalNavigationItem = (props) => {
    return (
            <div className="global-navigation-item">
                <Tooltip title={props.title} place="top">
                    <NavLink to={props.to ? props.to : '/'}>
                        <div className="icon-wrapper">
                            <Icon icon={props.icon}/>
                        </div>
                    </NavLink>
                </Tooltip>
            </div>
        
    );
}

GlobalNavigationItem.propTpes = {
    to: PropTypes.string,
    title: PropTypes.string
}

export default GlobalNavigationItem;

