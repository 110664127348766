import moment from 'moment'



export const checkMinDifference= (slotOne, slotTwo, buffer, slotDuration) =>{
    var duration = moment.duration(moment(slotTwo.startDate).diff(moment(slotOne.startDate).add(slotDuration, 'm')));
    return duration.asMinutes() >= buffer;
}

export const checkIfIsAfter = (dateOne, dateTwo) =>{
    var duration = moment.duration(moment(dateOne).diff(moment(dateTwo)));
    return duration.asMinutes() >= 0;   
}

export const getTimeDifference = (dateOne, dateTwo, measure = 'd') =>{
    var duration = moment.duration(moment(dateOne).diff(moment(dateTwo)));
    return duration.asDays()
}

export const reportError = (tag, error)=>{
    // Do some error tracking
}
