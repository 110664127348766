/**
 * Color Formating: 
 * 100 - 200 - 300 - 400 - 500 - 600 - 700 - 800 - 900--
 * light -- 500 = default -- dark 
 * 
 */

export const colors = {
    brand:{
        primary: {
            300:'#67fed8',
            500:'#01dca4',
            700: '#019872'
        },
        secondary: {
            300:'#928fe0',
            500:'#514cce',
            700: '#fa715c'
        },
        tertiary: {
            300:'#ed86af',
            500:'#e44b88',
            700: '#831440'
        },
        quaternary: {
            500: '#65B1ED'
        }
    }, 
    status:{
        success: {
            300:'#67fed8',
            500:'#01dca4',
            700: '#019872'
        },
        danger: {
            300:'#ff6262',
            500:'#ea0000',
            700: '#950000'
        },
        info: {
            300:'#928fe0',
            500:'#514cce',
            700: '#fa715c'
        },
        warning: {
            300:'#ffc266',
            500:'#f90',
            700: '#995c00'
        }
    },
    rainbow:['#FF9900','#01dca4', '#514cce', '#1F0028', '#fa715c'],
    neutral:{
        grays: {
            100: '#ffffff',
            200: '#F8F9FF',
            250: '#ECEFFE',    
            300: '#E0E4FC',
            350: '#D3D8F4',
            400: '#AAB5DB',
            500: '#6F7898',
            600: '#32395E',
            700: '#1C2340',
            800: '#151935'
        }
    }
};

export default colors

