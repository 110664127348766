import actionTypes from "../../actionTypes";
const initialState = {}

const setEntities = (state, action) =>{
    return {
        ...action.payload
    }
};
const setSuccessStatus = (state, action) =>{
    return {
        ...state,
        ressource: action.payload
    }
}

export const formReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_FORM_SUCCESS: return setEntities(state, action);
        case actionTypes.SUBMIT_FORM_SUCCESS: return setEntities(state, action);
        default: return state;
    }
};

