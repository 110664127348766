import React from 'react'

export const DialogContent = (props) => {
    return(
        <div className="dialog-content">
            {props.children}
        </div>
    )
}


