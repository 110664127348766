import React from 'react';
import {UserAvatar} from '../../Avatar'
import {connect} from 'react-redux'
import {getAuthenticatedUser} from '../../../store/selectors'
 
const GlobalNavigationHeader = (props) => {
     return (
        <div>
            <UserAvatar user={props.user} bg="brand.primary.500"/>
        </div>
    );
}

const mapStateToProps = state =>({
    user: getAuthenticatedUser(state)
})

export default connect(mapStateToProps, null)(GlobalNavigationHeader);