import React from 'react';
import {connect} from 'react-redux'
import {selectUser} from '../../store/selectors'
import Text from '../elements/Text'
import {UserAvatar} from '../Avatar'
import './Comment.scss'

import moment from 'moment'

const Comment = props=>{
    console.log(props)
    return(
        <div>
            <div className="comment-wrapper">
                {props.user ?
                <div className="comment-author">
                    <div>
                        <UserAvatar user={props.user}   size={25}/>   
                    </div>
                    <div>          
                        <Text className="comment-author-label">{`${props.user.firstName} ${props.user.lastName}`}</Text>
                    </div>
                    <div className="date">
                    <Text textStyle="caption" textAlign="right">{moment(props.comment.createdAt).format('L')}</Text>
                </div>
                </div>
                :                
                props.comment.type === 'BY_CUSTOMER' ?
                <div className="comment-author">
                    <div>
                        <UserAvatar   variant="neutral" size={25}/>   
                    </div>
                    <div>          
                        <Text className="comment-author-label">User</Text>
                    </div>
                    <div className="date">
                        <Text textStyle="caption" textAlign="right">{moment(props.comment.createdAt).format('L')}</Text>
                    </div>
                </div>
                
                : 
                <div className="comment-author">
                    <div>
                        <UserAvatar variant="neutral" size={25}/>   
                    </div>
                    <div>          
                        <Text className="comment-author-label">User Gelöscht</Text>
                    </div>
                    <div className="date">
                        <Text textStyle="caption" textAlign="right">{moment(props.comment.createdAt).format('L')}</Text>
                    </div>
                </div>
                }
                <div className="comment-body">
                    <Text  textStyle="description">{props.comment.description}</Text>
                </div>
                
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) =>({
    user: props.comment && props.comment.user ? selectUser(state, props.comment.user) : null
})

export default connect(mapStateToProps, null)(Comment)