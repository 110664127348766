import actionTypes from '../actionTypes'
import {hubApi} from '../../api'
import {setEvent} from './uiActions'

const fetchFormStart = () => {
    return {
        type: actionTypes.FETCH_FORM_START
    }
}

const fetchFormSuccess = (data) =>{
    return {
        type: actionTypes.FETCH_FORM_SUCCESS,
        payload: data.data
    }
}


const fetchFormError = (error) =>{
    console.log(error)
    return {
        type: actionTypes.FETCH_FORM_ERROR
    }
}



export const fetchForm = (token) => async dispatch => {
    
    dispatch(fetchFormStart())
    try{
        const response = await hubApi.getForm(token)
        dispatch(fetchFormSuccess(response))
    }catch(error){
        dispatch(fetchFormError(error))
    }
    
};


const submitFormStart = () => {
    return {
        type: actionTypes.SUBMIT_FORM_START
    }
}

const submitFormSuccess = (data) =>{
    return {
        type: actionTypes.SUBMIT_FORM_SUCCESS,
        payload: data.data
    }
}


const submitFormError = (error) =>{
    console.log(error)
    return {
        type: actionTypes.SUBMIT_FORM_ERROR,
    }
}


export const submitForm = (token, data) => async dispatch  =>{
    dispatch(submitFormStart())
    try{
        const response = await hubApi.submitForm(token, data)
        dispatch(submitFormSuccess(response))
    }catch(error){
        dispatch(submitFormError(error))
    }
}

