import React from 'react'
import GlobalNavigation from '../../../components/GlobalNavigation'


import '../Layouts.scss'
const AppLayout = (props) => { 
    return (
        <div className="display-flex">
            
            <GlobalNavigation>
            </GlobalNavigation>
            <div className="app-flex-wrap">
                {props.children}
            </div>
        </div>
    );
}
 
export default AppLayout;