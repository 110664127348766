import config from "../config";
import axios from 'axios'

let _token;
export const baseInstance = axios.create({
    baseURL: config.api.url,
});

baseInstance.interceptors.request.use((config)=>{
    
    return {
        ...config,
        headers: {
            ...config.headers, 
            Authorization: _token
        }
    };
    
}, (error)=>{
    return Promise.reject(error);
})

export const setToken = token =>{
    _token = token; 
    
    
//    baseInstance.setHeaders()
}

export const removeToken = () =>{
    _token = ''; 
//    baseInstance.setHeaders()
}



