import React, {useState, useEffect} from 'react'
import './Drawer.scss';
import Button from '../elements/Button';
import Scrollbar from '../elements/Scrollbar'
import Text from '../elements/Text'
import classNames from 'classnames';

export const DrawerPage = (props) =>{
    return (props.children)
}

const MasterPage = props =>{
    const handleRequestClose = () =>{
        props.onRequestClose && props.onRequestClose()
    }

    const classes = classNames(
        'bd-drawer-page',
        'buffl-drawer-master',
           {
            'buffl-drawer-master-hide': props.isHidden,
            'buffl-drawer-master-show': !props.isHidden,
            
        }
      )
    return (
        <div className={classes}>
            
            <div className="bd-header">
                <div className="bd-header-left">
                    <Text textStyle="headline">{props.title}</Text>
                </div>
                <div className="bd-header-right">
                    <Button
                    onClick={handleRequestClose} 
                    icon="close" variant="blank-neutral" size="medium" quadratic />
                </div>
            </div>
            <div className="bd-content">
                <Scrollbar>
                <div className="bd-scrollbar-content">
                    {props.children}
                </div>
                </Scrollbar>
            </div>
        </div>
    )
}


const DetailPage = (props) =>{
    const handleRequestUnwind = () =>{
        props.onRequestUnwind && props.onRequestUnwind()
    }

    const classes = classNames(
        'bd-drawer-page',
        'buffl-drawer-slave',
           {
            'buffl-drawer-slave-show': props.isShown,
            'buffl-drawer-slave-hide': !props.isShown,
            
        }
      )
    return (
        <div className={classes}>
            <div className="bd-slave-header">
                <div className="bd-header-left">
                <Button
                    onClick={handleRequestUnwind} 
                    icon="arrowLeft" variant="blank-neutral" size="sm" >{props.title}</Button>
                    
                </div>
            </div>
            <div className="bd-content">
                <Scrollbar>
                <div className="bd-scrollbar-content">
                    {props.children}
                </div>
                </Scrollbar>
            </div>
        </div>
    )
}


const Drawer = (props) => {
    const [_currentDetail, _setCurentDetail] = useState(props.showDetail)

    const unwind = () =>{
        props.onRequestUnwind && props.onRequestUnwind()
    }

    useEffect(()=>{
        if(props.showDetail !== _currentDetail){
            _setCurentDetail(props.showDetail)    
        } 
    })
    
    const _isSingleMode = props.isSingleMode || props.children && React.isValidElement(props.children)
    
    let master = _isSingleMode ? props.children : ""
    const details = !_isSingleMode ? props.children.filter(e=>{
        if(React.isValidElement(e) && e.props.pageKey){
            
            if(e.props.pageKey === "MASTER" || e.props.isMaster){
                master = e
                
                return false
            }
            else{
                return true
            }
        }else{
            if(e.props.isMaster){
                master = e
            }
            return false
        }        
    }) : null
    
    return( 
        props.isOpen ? 
        <div className="buffl-drawer">                    
            {master && <MasterPage title={master.props.title} isHidden={_currentDetail !== "" && !_isSingleMode} onRequestClose={props.onRequestClose}>
                {master}
            </MasterPage>}
            {details && details.map(e=>{
            return( 
                <DetailPage isShown={_currentDetail === e.props.pageKey} key={e.props.pageKey} title={e.props.title} onRequestUnwind={unwind}>
                    {e}
                </DetailPage>
            )})}    
        </div> : null
     );
}
Drawer.defaultProps = {
    showCloseBtn: true,
    closeBtnDisabled: true, 
    title: 'Drawer Title'
    
}
export default Drawer;