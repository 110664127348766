import React from 'react';

import GlobalNavigationMainSection from "./GlobalNavigationMainSection";
import GlobalNavigationHeader from './GlobalNavigationHeader'


const GlobalNavigation = (props) =>{
    
    return (
        <div className="global-navigation">
            <div className="global-navigation-inner">
                <GlobalNavigationHeader/>
                <GlobalNavigationMainSection/>
            </div>
        </div>
    )   
}
export default GlobalNavigation