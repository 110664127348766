import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import Icon from '../Icon'
import Text from '../Text'
import './Button.scss'
import classNames from 'classnames'
import LoadingCircle from '../LoadingCircle';

const Button = React.memo((props) =>{

    const classes = classNames(
        'btn',
        props.className,
        `btn-${props.variant}`,
        {
            'btn-loading': props.isLoading,
            'btn-active': props.active,
            'btn-disabled': props.disabled,
            'btn-block': props.block, 
            'btn-rounded': props.borderStyle === 'round' || props.rounded, 
            'btn-quadratic': props.quadratic,
            'btn-condensed': props.condensed,
            'btn-spaced': props.spaced,
            'btn-no-padding': props.noPadding,
            'btn-icon-only':!props.children && props.children !== 0 && props.icon,
            [`btn-${props.size}`]: props.size,
        }
    )
    const iconClasses = classNames(
        'btn-icon',
        {
            'btn-icon-icon-only': !props.children && props.children !== 0 && props.icon,
        }
    )
   
    const children = props.children ? 
        <Text className="btn-content" component="span">
            {props.children}
        </Text> : null

    const iconNode =  props.icon ? 
        <span className={iconClasses}>
            <Icon icon={props.icon}/>
        </span> : null 
    const content =     
        <span>
            {iconNode}
            {children}
        </span>
    return(
        props.href ?
        <a className={classes} href={props.href}>
            {content}
        </a> 
        : 
        props.to ? 
            props.navLink ? 
            <NavLink className={classes} disabled={props.disabled} state={props.state} to={props.to}>
                {content}
            </NavLink>
            :
            <Link className={classes} disabled={props.disabled} state={props.state} target={props.target} to={props.to}>
                {content}
            </Link>
        :
        <button type={props.type} className={classes} disabled={props.disabled} onClick={(e) =>{
            e.stopPropagation()
            // e.preventDefault()
            props.onClick && props.onClick(e)
        }}>
            {content}
            <LoadingCircle />
        </button>
    )
})

Button.defaultProps = {
    size: 'default',
    block: false,
    isLoading: false,
    disabled: false,
    variant: 'default-primary',
    borderStyle: 'normal',
    rounded: false,
    quadratic: false, 
    condensed: false
}

export default Button;

