import React from 'react';
import { ModalProvider as BaseModalProvider, BaseModalBackground} from 'styled-react-modal'
import styled from 'styled-components'

const ModalBackground = styled(BaseModalBackground)`
    background: transparent;
`

export const ModalProvider = (props) => {
    return(
        <BaseModalProvider backgroundComponent={ModalBackground}>
            {props.children}
        </BaseModalProvider>
    )
}
 

