import actionTypes from "../../actionTypes";
const initialState = {}

const setShow = (state, key, value) =>{
    return {
        ...state,
        [key]: value
    }
}

export const showReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_SHOW: return setShow(state, action.key, action.payload.show);
        default: return state;
    }
};
