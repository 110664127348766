import actionTypes from '../actionTypes'
import {setToken as setApiToken, removeToken as removeApiToken} from '../../api/index'
import {initSocket} from '../../api'

import {authApi} from '../../api'
import {reportError} from '../../utils'
 
const setToken = (token) =>{
    return {
        type: actionTypes.SET_JWT_TOKEN,
        payload: token
    }
}

export const checkAuthStatus = () => (dispatch) =>{

    const token = localStorage.getItem('JWT_TOKEN')
    setApiToken(token)
    initSocket(token)
    dispatch(setToken(token))
    if(token){
        fetchProfile(dispatch)
    }
    

}


const loginUserStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}
const loginUserSuccess = (data) => {
    const token = data.data.token;
    localStorage.setItem('JWT_TOKEN',token)
    setApiToken(token)
    initSocket(token)
    return {
        type: actionTypes.LOGIN_SUCCESS,
        payload: data.data.token
    }
}

const loginUserError = (error) => {
    return {
        type: actionTypes.LOGIN_ERROR,
        payload: {
            error
        } 
    }
}

export const loginUser = (data) => async dispatch =>{
    dispatch(loginUserStart())
    try{
        const response = await authApi.authenticate(data)
        dispatch(loginUserSuccess(response))
        fetchProfile(dispatch)
    }catch(error){
        reportError(actionTypes.LOGIN_ERROR)
        dispatch(loginUserError(error))
    }
}

const fetchProfileStart = data =>{
    return {
        type: actionTypes.FETCH_PROFILE_START,
    }
}

const fetchProfileSuccess = data =>{
    return {
        type: actionTypes.FETCH_PROFILE_SUCCESS,
        payload: data.data.user
    }
}

const fetchUserError = error =>{
    return {
        type: actionTypes.FETCH_PROFILE_ERROR,
        payload: error
    }
}

const fetchProfile = async dispatch =>{
    dispatch(fetchProfileStart())
    try{
        const response = await authApi.getProfile()
        dispatch(fetchProfileSuccess(response))
    }catch(error){
        dispatch(fetchUserError(error))
    }
}



export const logoutUser = dispatch =>{
    localStorage.removeItem('JWT_TOKEN')
    removeApiToken()
    initSocket()
    return {
        type: actionTypes.LOGOUT
    }
}