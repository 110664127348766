import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'
import NotFoundPage from '../../pages/website/NotFoundPage'


export const NotFoundRoute = ({ 
    redirectPath,
    ...rest
    }) => (
    <Route {...rest} render={(props) => (
        redirectPath
            ? <Redirect to={redirectPath} />
                        : <NotFoundPage/>
    )} />
  )

