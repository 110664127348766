import actionTypes from "../../actionTypes";
const initialState = {}

const setLoading = (state, key, value) =>{
    return {
        ...state,
        [key]: value
    }
}

export const loadingReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        
        case actionTypes.FETCH_SERVICE_RANGE_START: return setLoading(state, `FETCH_SERVICE_RANGE`, true);
        case actionTypes.FETCH_SERVICE_RANGE_ERROR: return setLoading(state, `FETCH_SERVICE_RANGE`, false);
        case actionTypes.FETCH_SERVICE_RANGE_SUCCESS: return setLoading(state, `FETCH_SERVICE_RANGE`, false);
        
        case actionTypes.FETCH_FORM_START: return setLoading(state, `FETCH_FORM`, true);
        case actionTypes.FETCH_FORM_ERROR: return setLoading(state, `FETCH_FORM`, false);
        case actionTypes.FETCH_FORM_SUCCESS: return setLoading(state, `FETCH_FORM`, false);
        
        case actionTypes.SUBMIT_FORM_START: return setLoading(state, `SUBMIT_FORM`, true);
        case actionTypes.SUBMIT_FORM_ERROR: return setLoading(state, `SUBMIT_FORM`, false);
        case actionTypes.SUBMIT_FORM_SUCCESS: return setLoading(state, `SUBMIT_FORM`, false);
        
        case actionTypes.FETCH_REPORT_DATA_START: return setLoading(state, `FETCH_REPORT_DATA`, true);
        case actionTypes.FETCH_REPORT_DATA_ERROR: return setLoading(state, `FETCH_REPORT_DATA`, false);
        case actionTypes.FETCH_REPORT_DATA_SUCCESS: return setLoading(state, `FETCH_REPORT_DATA`, false);
        
        case actionTypes.FETCH_SERVICE_RANGES_START: return setLoading(state, `FETCH_SERVICE_RANGES`, true);
        case actionTypes.FETCH_SERVICE_RANGES_ERROR: return setLoading(state, `FETCH_SERVICE_RANGES`, false);
        case actionTypes.FETCH_SERVICE_RANGES_SUCCESS: return setLoading(state, `FETCH_SERVICE_RANGES`, false);
        
        case actionTypes.REQUEST_APPOINTMENT_START: return setLoading(state, `REQUEST_APPOINTMENT`, true);
        case actionTypes.REQUEST_APPOINTMENT_ERROR: return setLoading(state, `REQUEST_APPOINTMENT`, false);
        case actionTypes.REQUEST_APPOINTMENT_SUCCESS: return setLoading(state, `REQUEST_APPOINTMENT`, false);
        
        case actionTypes.CREATE_REPORT_START: return setLoading(state, `CREATE_REPORT`, true);
        case actionTypes.CREATE_REPORT_ERROR: return setLoading(state, `CREATE_REPORT`, false);
        case actionTypes.CREATE_REPORT_SUCCESS: return setLoading(state, `CREATE_REPORT`, false);

        case actionTypes.FETCH_RESSOURCES_START: return setLoading(state, `FETCH_RESSOURCES`, true);
        case actionTypes.FETCH_RESSOURCES_ERROR: return setLoading(state, `FETCH_RESSOURCES`, false);
        case actionTypes.FETCH_RESSOURCES_SUCCESS: return setLoading(state, `FETCH_RESSOURCES`, false);


        case actionTypes.FETCH_USERS_START: return setLoading(state, `FETCH_USERS`, true);
        case actionTypes.FETCH_USERS_ERROR: return setLoading(state, `FETCH_USERS`, false);
        case actionTypes.FETCH_USERS_SUCCESS: return setLoading(state, `FETCH_USERS`, false);
        
        case actionTypes.UPDATE_APPOINTMENT_START: return setLoading(state, `UPDATE_APPOINTMENT`, true);
        case actionTypes.UPDATE_APPOINTMENT_ERROR: return setLoading(state, `UPDATE_APPOINTMENT`, false);
        case actionTypes.UPDATE_APPOINTMENT_SUCCESS: return setLoading(state, `UPDATE_APPOINTMENT`, false);
        
        case actionTypes.UPDATE_RESSOURCE_START: return setLoading(state, `UPDATE_RESSOURCE`, true);
        case actionTypes.UPDATE_RESSOURCE_ERROR: return setLoading(state, `UPDATE_RESSOURCE`, false);
        case actionTypes.UPDATE_RESSOURCE_SUCCESS: return setLoading(state, `UPDATE_RESSOURCE`, false);
        
        case actionTypes.OPEN_REPORT_START: return setLoading(state, `OPEN_REPORT`, true);
        case actionTypes.OPEN_REPORT_ERROR: return setLoading(state, `OPEN_REPORT`, false);
        case actionTypes.OPEN_REPORT_SUCCESS: return setLoading(state, `OPEN_REPORT`, false);
        
        case actionTypes.ASSIGN_USER_START: return setLoading(state, `ASSIGN_USER`, true);
        case actionTypes.ASSIGN_USER_ERROR: return setLoading(state, `ASSIGN_USER`, false);
        case actionTypes.ASSIGN_USER_SUCCESS: return setLoading(state, `ASSIGN_USER`, false);
        
        case actionTypes.CREATE_SERVICE_RANGE_START: return setLoading(state, `CREATE_SERVICE_RANGE`, true);
        case actionTypes.CREATE_SERVICE_RANGE_ERROR: return setLoading(state, `CREATE_SERVICE_RANGE`, false);
        case actionTypes.CREATE_SERVICE_RANGE_SUCCESS: return setLoading(state, `CREATE_SERVICE_RANGE`, false);
        
        case actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_START: return setLoading(state, `CREATE_SERVICE_RANGE`, true);
        case actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_ERROR: return setLoading(state, `CREATE_SERVICE_RANGE`, false);
        case actionTypes.CREATE_SERVICE_WITH_RESSOURCE_RANGE_SUCCESS: return setLoading(state, `CREATE_SERVICE_RANGE`, false);
        
        case actionTypes.LOGIN_START: return setLoading(state, `LOGIN`, true);
        case actionTypes.LOGIN_ERROR: return setLoading(state, `LOGIN`, false);
        case actionTypes.LOGIN_SUCCESS: return setLoading(state, `LOGIN`, false);
        
        case actionTypes.FETCH_PROFILE_START: return setLoading(state, `FETCH_PROFILE`, true);
        case actionTypes.FETCH_PROFILE_ERROR: return setLoading(state, `FETCH_PROFILE`, false);
        case actionTypes.FETCH_PROFILE_SUCCESS: return setLoading(state, `FETCH_PROFILE`, false);
        
        case actionTypes.ADD_COMMENT_START: return setLoading(state, `ADD_COMMENT`, true);
        case actionTypes.ADD_COMMENT_ERROR: return setLoading(state, `ADD_COMMENT`, false);
        case actionTypes.ADD_COMMENT_SUCCESS: return setLoading(state, `ADD_COMMENT`, false);
        
        
        case actionTypes.ADD_CONTACT_ATTEMPT_START: return setLoading(state, `ADD_CONTACT_ATTEMPT`, true);
        case actionTypes.ADD_CONTACT_ATTEMPT_ERROR: return setLoading(state, `ADD_CONTACT_ATTEMPT`, false);
        case actionTypes.ADD_CONTACT_ATTEMPT_SUCCESS: return setLoading(state, `ADD_CONTACT_ATTEMPT`, false);
        default: return state;
    }
};
