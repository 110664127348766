import React from 'react'
import './Dialog.scss'
import H3 from '../elements/H3'
import Button from '../elements/Button'




export const DialogHeader = (props) => {
    return (
        <div className="dialog-header">
            <div className="title-wrapper">
                <H3 textStyle="inherit">{props.title}</H3>
            </div>
            {props.showCloseBtn 
                ? <div className="icon-container">
                <Button icon="close" noPadding variant="flat-neutral-light" onClick={props.onCloseBtnClick}></Button>
            </div> : null}
        </div> 
    );
}

DialogHeader.defaultProps = {
    showCloseBtn: true,
    closeBtnDisabled: true, 
    title: 'Dialog Title'
    
}
 


