import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Drawer, {
  DrawerSection,
  DrawerItem,
  DrawerInfoItem,
  DrawerForm,
  DrawerFormItem,
  DrawerCommentSection,
  DrawerActionItem,
  DrawerPage,
} from "../Drawer";
import CreateRangeDialog from "../CreateRangeDialog";
import {
  CreateAppointmentsDialog,
  UpdateAppointmentDialog,
} from "../AppointmentsDialog";
import {
  selectAppointmentsForRessource,
  selectRessource,
  selectIsLoading,
  selectServiceRangeConfig,
} from "../../store/selectors";
import {
  updateRessource,
  updateRessourceStatus,
  addComment,
  openReport,
  addContactAttempt,
} from "../../store/actions";
import { withFormik } from "formik";
import Badge from "../elements/Badge";
import Button from "../elements/Button";
import Field from "../elements/Field";
import Select from "../elements/Select";
import ReduxObserver from "../ReduxObserver";
import Can from "../Can";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from "../../config";
import { ContactAttemptList } from "./ContactAttemptList";
import { ContactAttemptForm } from "./ContactAttemptForm";
const EditLocationForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  const handleSiteChange = (field, value) => {
    const newValue = (value && value.value) || "";
    const shouldResetDependent = newValue !== _prevSite;
    _setPrevSite(newValue);
    setFieldValue(field, value);
    if (shouldResetDependent) {
      setFieldValue("building", null);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Zusätzlich Infos">
            <Field placeholder="Andere Infos" name="otherInformation" />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const EditLocationSection = (props) => {
  const emptyOption = null;
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit &&
        props.onSubmit({
          location: {
            ...values,
            site: values.site && values.site.value,
            building: values.building && values.building.value,
          },
        });
    },
    validate: (values) => {
      return {};
    },
    mapPropsToValues: ({ ressource }) => ({
      site: ressource.location.site
        ? { value: ressource.location.site, label: ressource.location.site }
        : emptyOption,
      building: ressource.location.building
        ? {
            value: ressource.location.building,
            label: ressource.location.building,
          }
        : emptyOption,
      floor: ressource.location.floor,
      room: ressource.location.room,
      otherInformation: ressource.location.otherInformation,
    }),
  })(EditLocationForm);
  return <EnhancedForm {...props} />;
};

const EditDeviceForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Ci Alt">
            <Field placeholder="Ci Alt" name="ciName" disabled />
          </DrawerFormItem>
          <DrawerFormItem title="Ci Neu">
            <Field placeholder="Ci Neu" name="ciNameNew" />
          </DrawerFormItem>
          <DrawerFormItem title="SN Alt">
            <Field placeholder="SN Alt" name="serialNumber" disabled />
          </DrawerFormItem>
          <DrawerFormItem title="SN Neu">
            <Field placeholder="SN Neu" name="serialNumberNew" />
          </DrawerFormItem>
          <DrawerFormItem title="Mietschein">
            <Select
              isCreatable={false}
              isMulti={false}
              onChange={setFieldValue}
              name="leasingGroup"
              value={values.leasingGroup}
              options={[
                {
                  value: "8",
                  label: "8",
                },
                {
                  value: "8-ZIF",
                  label: "8-ZIF",
                },
                {
                  value: "1534477",
                  label: "1534477",
                },
              ]}
            />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const EditDeviceSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit &&
        props.onSubmit({
          ciName: values.ciName,
          ciNameNew: values.ciNameNew,
          deviceModelName: values.deviceModelName,
          serialNumber: values.serialNumber,
          serialNumberNew: values.serialNumberNew,
          leasingGroup: values.leasingGroup ? values.leasingGroup.value : "",
        });
    },
    validate: (values) => {
      return {};
    },
    mapPropsToValues: ({ ressource }) => ({
      ciName: ressource.ciName,
      ciNameNew: ressource.ciNameNew,
      deviceModelName: ressource.deviceModelName,
      serialNumber: ressource.serialNumber,
      serialNumberNew: ressource.serialNumberNew,
      leasingGroup: ressource.leasingGroup
        ? { value: ressource.leasingGroup, label: ressource.leasingGroup }
        : null,
    }),
  })(EditDeviceForm);
  return <EnhancedForm {...props} />;
};

const CompleteDeviceForm = (props) => {
    const [_prevSite, _setPrevSite] = useState("");
    const {
      values,
      touched,
      errors,
      isValid,
      handleChange,
      setFieldValue,
      handleSubmit,
      submitForm,
    } = props;
  
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <DrawerForm>            
            <DrawerFormItem title="Bios PW Reset">
              <Select
                isCreatable={false}
                isMulti={false}
                onChange={setFieldValue}
                name="biosIsReset"
                value={values.biosIsReset}
                options={[
                  {
                    value: true,
                    label: "Erledigt",
                  },                 
                ]}
              />
            </DrawerFormItem>
            <DrawerFormItem title="Datenlöschung">
              <Select
                isCreatable={false}
                isMulti={false}
                onChange={setFieldValue}
                name="wipeDone"
                value={values.wipeDone}
                options={[
                  {
                    value: true,
                    label: "Erledigt",
                  },                 
                ]}
              />
            </DrawerFormItem>
            <DrawerFormItem title="Schäden erkennbar">
              <Select
                isCreatable={false}
                isMulti={false}
                onChange={setFieldValue}
                name="hasDamages"
                value={values.hasDamages}
                options={[
                  {
                    value: true,
                    label: "Ja",
                  },                 
                  {
                    value: false,
                    label: "Nein",
                  },                 
                ]}
              />
            </DrawerFormItem>
           {values.hasDamages && values.hasDamages.value && <DrawerFormItem title="Schäden">
            <Field
              placeholder="Schäden beschreiben"
              component="textarea"
              name="damageDescriptipon"
            />
          </DrawerFormItem>}
            <Button
              type="submit"
              block
              disabled={!isValid}
              isLoading={props.isLoading}
            >
              Abschließen
            </Button>
          </DrawerForm>
        </form>
      </React.Fragment>
    );
  };

const CompleteDeviceSection = (props) => {
    const EnhancedForm = withFormik({
      handleSubmit: (values) => {
        
        props.onSubmit &&
          props.onSubmit({
            status: "DONE",
            wipeDone: values.wipeDone,
            biosIsReset: values.biosIsReset,
            hasDamages: values.hasDamages,
            damageDescriptipon: values.damageDescriptipon,
          });
      },
      validate: (values) => {
            let errors = {};
            if(!values.wipeDone || values.wipeDone.value !== true){
                errors = {
                    ...errors,
                    wipeDone: "Datenlöschung muss erledigt sein."
                };
            }
            if(!values.biosIsReset || values.biosIsReset.value !== true){
                errors = {
                    ...errors,
                    biosIsReset: "Bios PW Reset muss erledigt sein."
                };
            }
            if(!values.hasDamages){
                errors = {
                    ...errors,
                    hasDamages: "Bitte angeben."
                };
            }
            if(values.hasDamages && values.hasDamages.value && !values.damageDescriptipon){
                errors = {
                    ...errors,
                    damageDescriptipon: "Bitte Schäden beschreiben."
                };
            }
            return errors;
      },
      mapPropsToValues: ({ ressource }) => ({
        biosIsReset: null,
        wipeDone: null,
        hasDamages: null,
        damageDescriptipon: "",
      }),
    })(CompleteDeviceForm);
    return <EnhancedForm {...props} />;
  };

const EditCustomerForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Vorname">
            <Field placeholder="Vorname" name="firstName" />
          </DrawerFormItem>
          <DrawerFormItem title="Nachname">
            <Field placeholder="Vorname" name="lastName" />
          </DrawerFormItem>
          <DrawerFormItem title="Email">
            <Field placeholder="Vorname" name="email" />
          </DrawerFormItem>
          <DrawerFormItem title="Phone">
            <Field placeholder="Vorname" name="phone" />
          </DrawerFormItem>
          <DrawerFormItem title="Stammnummer">
            <Field placeholder="Vorname" name="registrationNumber" />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const EditCustomerSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit && props.onSubmit({ customer: { ...values } });
    },
    validate: (values) => {
      return {};
    },
    mapPropsToValues: ({ ressource }) => ({
      email: ressource.customer.email,
      phone: ressource.customer.phone,
      registrationNumber: ressource.customer.registrationNumber,
      firstName: ressource.customer.firstName,
      lastName: ressource.customer.lastName,
    }),
  })(EditCustomerForm);
  return <EnhancedForm {...props} />;
};

const TrackOrderForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="CRQ">
            <Field placeholder="CRQ" name="crq" />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const TrackOrderSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit &&
        props.onSubmit({
          status: "ORDER_CREATED",
          order: {
            crq: values.crq,
          },
        });
    },
    validate: (values) => {
      let errors = {};
      return errors;
    },
    mapPropsToValues: ({ ressource }) => ({
      crq: "",
      status: "ORDER_CREATED",
    }),
  })(TrackOrderForm);
  return <EnhancedForm {...props} />;
};

const EditSoftwareForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Software hinzufügen">
            <Select
              isCreatable
              isMulti
              onChange={setFieldValue}
              name="software"
              value={values.software}
              options={[]}
            />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const EditSoftwareSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit &&
        props.onSubmit({ software: values.software.map((e) => e.value) });
    },
    validate: (values) => {
      console.log(values);
      return {};
    },
    mapPropsToValues: ({ ressource }) => ({
      software:
        ressource.software &&
        ressource.software.map((e) => ({ value: e, label: e })),
    }),
  })(EditSoftwareForm);
  return <EnhancedForm {...props} />;
};

const AddCommentForm = (props) => {
  const [_prevSite, _setPrevSite] = useState("");
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    setFieldValue,
    handleSubmit,
    submitForm,
  } = props;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <DrawerForm>
          <DrawerFormItem title="Kommentar">
            <Field
              placeholder="Kommentar eingeben"
              component="textarea"
              name="description"
            />
          </DrawerFormItem>
          <Button
            type="submit"
            block
            disabled={!isValid}
            isLoading={props.isLoading}
          >
            Speichern
          </Button>
        </DrawerForm>
      </form>
    </React.Fragment>
  );
};

const AddCommentSection = (props) => {
  const EnhancedForm = withFormik({
    handleSubmit: (values) => {
      props.onSubmit && props.onSubmit(values);
    },
    validate: (values) => {
      return !values.description ? { description: "Darf nicht leer sein" } : {};
    },
    mapPropsToValues: ({ ressource }) => ({
      description: "",
    }),
  })(AddCommentForm);
  return <EnhancedForm {...props} />;
};

const RessourceInfo = ({
  ressource,
  onRequestAddComment,
  onRequestEditSoftware,
  onRequestEditCustomer,
  onRequestEditLocation,
  onRequestAddContactAttempt,
  onRequestEditDevice,
}) => {
  return (
    ressource && (
      <React.Fragment>
        <DrawerSection title="Rechner">
          <DrawerInfoItem title="Ci Alt" value={ressource.ciName} />
          <DrawerInfoItem title="Ci Neu" value={ressource.ciNameNew} />
          <DrawerInfoItem
            title="Bezeichnung"
            value={ressource.deviceModelName}
          />
          <DrawerInfoItem title="SN Alt" value={ressource.serialNumber} />
          <DrawerInfoItem title="SN Neu" value={ressource.serialNumberNew} />
          <DrawerInfoItem title="Mietschein" value={ressource.leasingGroup} />
          <Button
            size="small"
            onClick={onRequestEditDevice}
            variant="flat-primary"
            noPadding
          >
            Rechner bearbeiten
          </Button>
        </DrawerSection>
        <DrawerSection title="Software">
          <DrawerItem>
            {ressource.software &&
              ressource.software.reduce((acc, e, i) => {
                return i == 0 ? e : `${acc},  ${e}`;
              }, "")}
          </DrawerItem>
          <Button
            size="small"
            onClick={onRequestEditSoftware}
            variant="flat-primary"
            noPadding
          >
            Software bearbeiten
          </Button>
        </DrawerSection>
        <DrawerSection title="Ort">
          <DrawerInfoItem
            title="Location"
            value={ressource.location.siteOriginal}
          />
          <DrawerInfoItem
            title="Zusätliche Infos"
            value={ressource.location.otherInformation}
          />
          <Button
            size="small"
            onClick={onRequestEditLocation}
            variant="flat-primary"
            noPadding
          >
            Ort bearbeiten
          </Button>
        </DrawerSection>
        <DrawerSection title="User">
          <DrawerInfoItem
            title="Name"
            value={`${ressource.customer.firstName || ""} ${ressource.customer
              .lastName || ""}`}
          />
          <DrawerInfoItem
            title="Stammnummer"
            value={ressource.customer.registrationNumber || "Nicht erfasst"}
          />
          <DrawerInfoItem title="Email" value={ressource.customer.email} />
          <DrawerInfoItem title="Phone" value={ressource.customer.phone} />
          <Can I="update" on="Ressource_user">
            <Button
              size="small"
              onClick={onRequestEditCustomer}
              variant="flat-primary"
              noPadding
            >
              User bearbeiten
            </Button>
          </Can>
        </DrawerSection>

        <DrawerCommentSection
          onRequestAddComment={onRequestAddComment}
          title="Kommentare"
          comments={ressource.comments}
        />
        <ContactAttemptList
          onRequestAddContactAttempt={onRequestAddContactAttempt}
          contactAttempts={ressource.activities.filter(
            (e) => e._TYPE === "ContactAttempt"
          )}
        />
      </React.Fragment>
    )
  );
};
const StatusInfo = ({
  ressource,
  changeAppointment,
  onRequestUnwind,
  saveAppointment,
  onRequestCompleteDevice
}) => {
  const mapStatusToClass = (status) => {
    return status == "DONE"
      ? "success"
      : status === "CANCELED"
      ? "disabled"
      : status === "NEEDS_REVIEW"
      ? "danger"
      : "progress";
  };

  const link = ressource.tokens
    ? ressource.tokens.filter((e) => e.type === "WIN_10_APPOINTMENT")[0]
    : "";
  const [_selectedAppointment, _setSelectedAppointment] = useState(false);
  
  const handleOpenConfirmationBtnPressed = () => {
    onRequestUnwind();
    window.open(`${config.links.report}${ressource.id}`, '_blank', 'noreferrer');
  };
  return (
    ressource && (
      <React.Fragment>
        <UpdateAppointmentDialog
          appointment={_selectedAppointment}
          isOpen={_selectedAppointment}
          onRequestClose={() => {
            _setSelectedAppointment(false);
          }}
          ressource={ressource}
        />
        <DrawerSection title="Prozess">
          <DrawerItem title="Status">
            <Badge value={ressource.status} />
            {ressource.isConfirmedByCustomer && <Badge value="Bestätigt" />}
          </DrawerItem>
          {saveAppointment && (
            <DrawerInfoItem
              onClickEdit={() => {
                _setSelectedAppointment(saveAppointment);
              }}
              title="Datensicherung"
              titleStatus={mapStatusToClass(saveAppointment.status)}
              value={moment(saveAppointment.startDate).format("LLL")}
            />
          )}
          {changeAppointment && (
            <DrawerInfoItem
              onClickEdit={() => {
                _setSelectedAppointment(changeAppointment);
              }}
              title="Replace"
              titleStatus={mapStatusToClass(changeAppointment.status)}
              value={moment(changeAppointment.startDate).format("LLL")}
            />
          )}
          {ressource.status === "IN_CUSTOMER_DEPOT" && (
            
            <div>
                <br></br>
              <DrawerActionItem
                onClick={handleOpenConfirmationBtnPressed}
                title="Bestätigen"
                description="Protokollieren und in INT Lager verschieben"
                btnTitle="Protokoll"
                block
              />
            </div>
          )}
          {ressource.status === "IN_VENDOR_DEPOT" && (
            
            <div>
                <br></br>
              <DrawerActionItem
                onClick={()=>{
                    onRequestCompleteDevice();
                }}
                title="Abschließen"
                description="Checkliste ausfüllen und Prozess abschließen."
                btnTitle="Checkliste"
                block
              />
            </div>
          )}
          {/*{restoreAppointment && <DrawerInfoItem onClickEdit={()=>{_setSelectedAppointment(restoreAppointment)}}title="Restore" titleStatus={mapStatusToClass(restoreAppointment.status)} value={moment(restoreAppointment.startDate).format('LLL')}/>}*/}
          {link && ressource.status === "WAITING_FOR_APPOINTMENT" && (
            <DrawerItem title="Link zu Formular">
              <CopyToClipboard text={`${config.links.appointment}${link.id}`}>
                <Button
                  size="small"
                  to={`/appointment/${link.id}`}
                  target="_blank"
                  variant="flat-primary"
                  noPadding
                >
                  Termine erfassen
                </Button>
              </CopyToClipboard>
            </DrawerItem>
          )}
        </DrawerSection>
      </React.Fragment>
    )
  );
};

const RessourceActions = (props) => {
  const {
    ressource,
    isUpdating,
    isOpeningReport,
    onRequestUpdate,
    onRequestUpdateStatus,
    onRequestCreateRange,
    onRequestOpenReport,
    onRequestTrackOrder,
    
  } = props;

  const handleConfirmationBtnPressed = () => {
    onRequestUpdateStatus && onRequestUpdateStatus({ status: "APPOINTED" });
  };

  

  const handleSendOrderRequestBtnPressed = () => {
    onRequestUpdateStatus &&
      onRequestUpdateStatus({ status: "WAITING_FOR_ORDER" });
  };

  const handleCancelBtnPressed = () => {
    onRequestUpdateStatus && onRequestUpdateStatus({ status: "OPEN" });
  };
  const handleOnHoldBtnPressed = () => {
    onRequestUpdateStatus && onRequestUpdateStatus({ status: "ON_HOLD" });
  };  
  const handleUserConfirmationBtnPressed = () => {
    onRequestOpenReport && onRequestOpenReport();
  };

  const handleWorFlowBtnPresses = () => {
    onRequestUpdateStatus &&
      onRequestUpdateStatus({ status: "HANDLED_EXTERNAL" });
  };
  const handleRequestCreateRange = () => {
    onRequestCreateRange && onRequestCreateRange();
  };

  const handleTrackTicketBtnPresses = () => {
    onRequestTrackOrder && onRequestTrackOrder();
  };
  return (
    <DrawerSection title="Aktionen">
      {["OPEN", "WAITING_FOR_APPOINTMENT"].indexOf(ressource.status) !== -1 &&
        ressource && (
          <DrawerActionItem
            onClick={handleRequestCreateRange}
            title="Terminfenster eröffnen"
            description="Fenster für Terminierung erlauben"
            btnTitle="Termin eröffnen"
            block
          />
        )}
      {ressource.status == "APPOINTED_NEEDS_CHECK" && (
        <Can I="set" on="Ressource_status_APPOINTED">
          <DrawerActionItem
            onClick={handleConfirmationBtnPressed}
            title="Bestätigen"
            description="Ortsabweichung bestätigen"
            btnTitle="Bestätigen"
            block
          />
        </Can>
      )}
      {[
        "WAITING_FOR_APPOINTMENT",
        "APPOINTED_NEEDS_CHECK",
        "APPOINTED",
        "HANDLED_EXTERNAL",
      ].indexOf(ressource.status) !== -1 &&
        !ressource.isConfirmedByCustomer && (
          <Can I="set" on="Ressource_status_OPEN">
            <DrawerActionItem
              onClick={handleCancelBtnPressed}
              title="Canceln"
              confirmationMessage="User müssen manuell benachrichtigt werden!"
              confirmationTitle="Bist du sicher?"
              confirmation
              closeDialogOnConfirm={true}
              description="Termine Canceln"
              btnTitle="Canceln"
              block
            />
          </Can>
        )}
      {["OPEN", "WAITING_FOR_ORDER_APPROVAL", "WAITING_FOR_ORDER"].indexOf(
        ressource.status
      ) !== -1 && (
        <Can I="set" on="Ressource_status_OPEN">
          <DrawerActionItem
            onClick={handleOnHoldBtnPressed}
            title="On Hold setzen"
            confirmationMessage="Rechner bis auf weiteres pausieren?"
            confirmationTitle="Bist du sicher?"
            confirmation
            closeDialogOnConfirm={true}
            description="Status on Hold setzen"
            btnTitle="On Hold"
            block
          />
        </Can>
      )}
      {["APPOINTED", "WORK_STEPS_DONE"].indexOf(ressource.status) !== -1 &&
        !ressource.isConfirmedByCustomer && (
          <DrawerActionItem
            onClick={handleUserConfirmationBtnPressed}
            isLoading={isOpeningReport}
            confirmationMessage="Der Nutzer erhält sofort ein Formular, das er selbständig ausfüllen kann."
            confirmationTitle="Bist du sicher?"
            confirmation
            closeDialogOnConfirm={true}
            title="Protokoll"
            description="Link mit Formular an User senden."
            btnTitle="Senden"
            block
          />
        )}
      {ressource.status !== "HANDLED_EXTERNAL" && (
        <Can I="set" on="Ressource_status_HANDLED_EXTERNAL">
          <DrawerActionItem
            onClick={handleWorFlowBtnPresses}
            title="Workflow"
            confirmationMessage="Der Rechner wird dann nicht mehr exportiert."
            confirmationTitle="Bist du sicher?"
            confirmation
            closeDialogOnConfirm={true}
            description="Aus Workflow Entfernen"
            btnTitle="Entfernen"
            block
          />
        </Can>
      )}
    </DrawerSection>
  );
};

const RessourceDetailDrawer = (props) => {
  const {
    ressource,
    appointments,
    isAddingComment,
    isAddingContactAttempt,
    isUpdating,
    isOpeningReport,
    onRequestUpdateStatus
  } = props;
  const [_currentDetailPage, _setCurrentDetailPage] = useState("");
  const [_showCreateRangeDialog, _setShowCreateRangeDialog] = useState(false);
  const [
    _showCreateAppointmentsDialog,
    _setShowCreateAppointmentsDialog,
  ] = useState(true);

  const handleRequestClose = () => {
    props.onRequestClose && props.onRequestClose();
  };

  const handleRequestUpdateStatus = (data) => {
    props.updateStatus(ressource.id, data);
  };

  const handleRequestCreateRange = () => {
    _setShowCreateRangeDialog(true);
  };

  const handleRequestOpenReport = () => {
    props.openReport(ressource.id);
  };

  const handleCommentSubmit = (values) => {
    props.addComment(ressource.id, values);
  };

  const handleContactAttemptSubmit = (values) => {
    props.addContactAttempt(ressource.id, values);
  };
  const handleRequestUpdate = (values) => {
    props.updateRessource(ressource.id, values);
  };
  const handleRequestUnwind = () => {
    _setCurrentDetailPage("");
  };
  const handleRequestCompleteChecklist = (values) =>{
    onRequestUpdateStatus && onRequestUpdateStatus({ status: "DONE" });
  }

  // _setCurrentDetailPage("ONE")
  return (
    <React.Fragment>
      <ReduxObserver
        event="ADD_COMMENT_SUCCESS"
        onEvent={handleRequestUnwind}
      />
      <ReduxObserver
        event="UPDATE_RESSOURCE_SUCCESS"
        onEvent={handleRequestUnwind}
      />
      <ReduxObserver
        event="ADD_CONTACT_ATTEMPT_SUCCESS"
        onEvent={handleRequestUnwind}
      />
      <CreateRangeDialog
        isOpen={_showCreateRangeDialog}
        onRequestClose={() => {
          _setShowCreateRangeDialog(false);
        }}
        ressource={ressource}
      />

      <Drawer
        onRequestUnwind={handleRequestUnwind}
        onRequestClose={handleRequestClose}
        isOpen={props.id}
        showDetail={_currentDetailPage}
      >
        <DrawerPage title="Rechner" pageKey="MASTER">
          <StatusInfo
            ressource={ressource}
            onRequestUnwind={handleRequestUnwind}
            changeAppointment={appointments && appointments.changeAppointment}
            restoreAppointment={appointments && appointments.restoreAppointment}
            saveAppointment={appointments && appointments.saveAppointment}
            onRequestCompleteDevice={()=>{
                _setCurrentDetailPage("COMPLETE_DEVICE");
            }}
          />
          <RessourceInfo
            ressource={ressource}
            onRequestAddComment={() => {
              _setCurrentDetailPage("ADD_COMMENT");
            }}
            onRequestEditSoftware={() => {
              _setCurrentDetailPage("EDIT_SOFTWARE");
            }}
            onRequestEditCustomer={() => {
              _setCurrentDetailPage("EDIT_CUSTOMER");
            }}
            onRequestEditLocation={() => {
              _setCurrentDetailPage("EDIT_LOCATION");
            }}
            onRequestEditDevice={() => {
              _setCurrentDetailPage("EDIT_DEVICE");
            }}
            onRequestAddContactAttempt={() => {
              _setCurrentDetailPage("ADD_CONTACT_ATTEMPT");
            }}
          />
          <RessourceActions
            onRequestUpdateStatus={handleRequestUpdateStatus}
            onRequestTrackOrder={() => {
              _setCurrentDetailPage("TRACK_ORDER");
            }}
            onRequestOpenReport={handleRequestOpenReport}
            onRequestUpdate={handleRequestUpdate}
            isOpeningReport={isOpeningReport}
            onRequestCreateRange={handleRequestCreateRange}
            onRequestUnwind={handleRequestUnwind}
            ressource={ressource}
          />
        </DrawerPage>
        <DrawerPage title="Rechner" pageKey="ADD_COMMENT">
          <AddCommentSection
            isLoading={isAddingComment}
            onSubmit={handleCommentSubmit}
          />
        </DrawerPage>
        <DrawerPage title="Rechner" pageKey="ADD_CONTACT_ATTEMPT">
          <ContactAttemptForm
            isLoading={isAddingContactAttempt}
            onSubmit={handleContactAttemptSubmit}
          />
        </DrawerPage>

        <DrawerPage title="Rechner" pageKey="EDIT_SOFTWARE">
          <EditSoftwareSection
            isLoading={isUpdating}
            ressource={props.ressource}
            onSubmit={handleRequestUpdate}
          />
        </DrawerPage>
        <DrawerPage title="Rechner" pageKey="TRACK_ORDER">
          <TrackOrderSection
            isLoading={isUpdating}
            ressource={props.ressource}
            onSubmit={handleRequestUpdate}
          />
        </DrawerPage>

        <DrawerPage title="Rechner" pageKey="EDIT_CUSTOMER">
          <EditCustomerSection
            isLoading={isUpdating}
            ressource={props.ressource}
            onSubmit={handleRequestUpdate}
          />
        </DrawerPage>
        <DrawerPage title="Rechner" pageKey="EDIT_LOCATION">
          <EditLocationSection
            isLoading={isUpdating}
            ressource={props.ressource}
            config={props.config}
            onSubmit={handleRequestUpdate}
          />
        </DrawerPage>
        <DrawerPage title="Rechner" pageKey="EDIT_DEVICE">
          <EditDeviceSection
            isLoading={isUpdating}
            ressource={props.ressource}
            config={props.config}
            onSubmit={handleRequestUpdate}
          />
        </DrawerPage>
        <DrawerPage title="Abschluss Checklist" pageKey="COMPLETE_DEVICE">
          <CompleteDeviceSection
            isLoading={isUpdating}
            ressource={props.ressource}
            config={props.config}            
            onSubmit={handleRequestUpdate}
          />
        </DrawerPage>
      </Drawer>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateRessource: (id, values) => dispatch(updateRessource(id, values)),
  addComment: (id, values) => dispatch(addComment(id, values)),
  updateStatus: (id, data) => dispatch(updateRessourceStatus(id, data)),
  openReport: (id) => dispatch(openReport(id)),
  addContactAttempt: (id, data) => dispatch(addContactAttempt(id, data)),
});

const mapStateToProps = (state, { id }) => ({
  ressource: selectRessource(state, id),
  appointments: selectAppointmentsForRessource(state, id),
  isAddingComment: selectIsLoading(state, "ADD_COMMENT"),
  isAddingContactAttempt: selectIsLoading(state, "ADD_CONTACT_ATTEMPT"),
  isUpdating: selectIsLoading(state, "UPDATE_RESSOURCE"),
  isOpeningReport: selectIsLoading(state, "OPEN_REPORT"),
  config: selectServiceRangeConfig(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RessourceDetailDrawer);
