import { createSelector } from "reselect";
import {selectRessource} from './ressourcesSelector'
const _selectServiceRanges = state => (Object.values(state.entities.serviceRanges))

export const selectServiceRanges = (state)=>{
        
    const items = _selectServiceRanges(state)
    
    return items.map(e=>{
        

        if(e.ressource){
            const ressource = selectRessource(state, e.ressource)
            
            return {
                ...e, 
                location: ressource ? ressource.location : {},
                ressource: ressource ? ressource.ciName: "" ,
                type: 'INDIVIDUAL_WITH_RESSOURCE'
            }
        }else{
            return {
                ...e,
                type: 'BATCH_WITH_LOCATION' 
             }   
    }})
    
}
