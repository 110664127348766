import actionTypes from "../../actionTypes";
const initialState = {}

const setEntities = (state, action) =>{
    return {
        ...state, 
        ...action.payload.entities.users
    }
};

export const usersReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_USERS_SUCCESS: return setEntities(state, action);
        case actionTypes.FETCH_RESSOURCES_SUCCESS: return setEntities(state, action);
        default: return state;
    }
};

