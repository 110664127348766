import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import entitiesReducer from './reducers/entities'
import authReducer from './reducers/auth'
import uiReducer from './reducers/ui'
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    entities: entitiesReducer,
    ui: uiReducer,
    auth: authReducer,
});

const composeEnhancers = process.env.NODE_ENV === 'develdopment' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

export default store