import React, {useState} from 'react';
import Icon from '../../../components/elements/Icon'
import Field from '../../../components/elements/Field'
import ErrorMessage from '../../../components/ErrorMessage'
import {FieldError} from '../../../components/FieldGroup'
 
import Button from '../../../components/elements/Button'
 
import validator from 'validator';

import '../hub.scss';
import { withFormik } from 'formik';
import { useTranslation  } from 'react-i18next';

import Select from '../../../components/elements/Select'

const Form = (props) => {

    const { t } = useTranslation();
    const [_prevSite, _setPrevSite] = useState("")

    const handleSiteChange = (field, value) =>{
        const newValue = value && value.value || '';
        const shouldResetDependent = newValue !== _prevSite;
        _setPrevSite(newValue)
        setFieldValue(field, value)
        if(shouldResetDependent){
            setFieldValue('location.building', null)
        }
    }
    const {
        values,
        touched,
        errors,
        isValid,
        setFieldValue,
        handleSubmit,
        submitForm,
        ressource,
        setFieldTouched
      } = props;


    
    
    return (
        <form onSubmit={handleSubmit}>
            <div className="hub-row">
                <div className="hub-block">
                    <div className="hub-block-left">
                        <div className="hub-block-icon variant1">
                            <Icon icon="building"></Icon>
                        </div>
                    </div>
                    <div className="hub-block-right">
                        <h2>{t('hub:form.form.replace')}</h2>
                        <div className="hub-formarea">
                            {/*<p>{t('hub:appointment.form.backupText')}</p>*/}
                            <br></br>
                            <h5>Ersatzgerät?</h5>
                            <div className="information-block">

                                <div className="item">
                                    <p>Bitte geben Sie an, falls Sie dieses Gerät nur zurück geben möchten.</p>
                                    <p>Sie erhalten dann kein Ersatzgerät.</p>

                                </div>
                                <div className="item">
                                    <label>Ersatzgerät</label>
                                    <Select
                                        onChange={setFieldValue}
                                        name="isRemove"
                                        value={values.isRemove}
                                        isSearchable={true}
                                        options={[
                                            {
                                                label: "Ja ich benötige ein neues Gerät.",
                                                value: "NO"
                                            },
                                            {
                                                label: "Gerät musssharepoint  nicht esetzt werden.",
                                                value: "YES"
                                            }
                                        ]}/>
                                    <FieldError errors={errors} touched={touched} name="replacementCategory"/>
                                </div>
                            </div>

                            { values.isRemove.value == "NO" &&
                                <React.Fragment>
                                    <br></br>
                                    <h5>Gerätekategorie</h5>
                                    <div className="information-block">

                                        <div className="item">
                                            <p>Bitte wählen Sie die Gerätekategorie, mit der Ihr Gerät ersetzt werden soll.</p>
                                            <p>Die Vorauswahl entspricht Ihrer aktuellen Kategorie.</p>
                                            <p>Die entsprechenden Geräte können Sie im <a href="www.apple.de">Warenkorb</a> prüfen.</p>
                                        </div>
                                        <div className="item">
                                            <label>Gerätekategorie</label>
                                            <Select
                                                onChange={setFieldValue}
                                                name="replacementCategory"
                                                value={values.replacementCategory}
                                                isSearchable={true}
                                                options={props.config.deviceCategories || []}/>
                                            <FieldError errors={errors} touched={touched} name="replacementCategory"/>
                                        </div>
                                    </div>
                                    {values.replacementCategory.value !== ressource.tier &&<div className="hub-info-box">
                                        <Icon icon="exclamation"></Icon> <span
                                        className="hub-info-box-title">{t('hub:appointment.form.notes')}</span>
                                        <ul className="hub-info-list">
                                            <li className="hub-info-list-item">Bei einem Kategoriewechsel ist eine Genehmigung
                                                erforderlich. Bitte wenden Sie sich an Ihren oorgesetzten.
                                            </li>
                                            <li className="hub-info-list-item">Bitte beachten Sie außerdem dass nur eine Auswahl
                                                einer Hardwarekategorie (z.B. Computer, Notebook, Workstation) möglich ist.
                                            </li>
                                            <li className="hub-info-list-item">Weitere Informationen finden Sie unter diesem
                                                Link.
                                            </li>
                                        </ul>
                                    </div>}

                                    <br></br>
                                    <h5>Verbleib Altgerät</h5>
                                    <div className="information-block">

                                        <div className="item">
                                            <p>Benötigen Sie das aktuelle Gerät weiterhin nachdem Sie ein neues bekommen haben?</p>
                                            <p>Beide Geräte belasten dann die Kostenstelle</p>

                                        </div>
                                        <div className="item">
                                            <label>Benötigen Sie das aktuelle Gerät weiterhin?</label>
                                            <Select
                                                value={values.retained}
                                                placeholder="Auswählen"
                                                onChange={(name, value)=>{
                                                    setFieldValue(name, value)
                                                    setFieldTouched(name, value)
                                                }}
                                                name="retained"
                                                options={[{value: 'YES', label: 'Ja'}, {value: 'NO', label: 'Nein'}]}
                                                isSearchable={false}
                                            />
                                            <FieldError errors={errors}  touched={touched} name="retained"/>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="hub-row">
            <div className="hub-block">
                <div className="hub-block-left">
                    <div className="hub-block-icon variant1">
                        <Icon icon="building"></Icon>
                    </div>
                </div>
                <div className="hub-block-right">
                    <h2>{t('hub:form.form.location')}</h2>
                    <div className="hub-formarea">
                        <div className="information-block">
                            <div className="item">
                                <label>{t('hub:form.form.choosePlant')}</label>
                                <Select
                                name="location.site"
                                value={values.location.site} 
                                isSearchable={true} 
                                options={props.config.locations.sites.map(e=>({
                                    value: e,
                                    label: e === 'OTHER_SITE' ? 'Anderes' : e
                                }))}
                                onChange={handleSiteChange}
                                />
                                <FieldError errors={errors} touched={touched} name="location.site"/>
                            </div>
                            <div className="item">
                                <label>{t('hub:form.form.chooseBuilding')}</label>
                                <Select 
                                onChange={setFieldValue}
                                name="location.building"
                                value={values.location.building}
                                isSearchable={true} 
                                options={
                                    values.location.site ? 
                                        props.config.locations.buildings[values.location.site.value] 
                                        ?
                                        props.config.locations.buildings[values.location.site.value].map(e=>({label:e === 'OTHER_BUILDING' ? 'Anderes' : e === '100' ? 'Forum' : e, value:e})) 
                                        :
                                        []
                                    : 
                                    []}/>
                                    <FieldError errors={errors} touched={touched} name="location.building"/>
                            </div>
                        </div>
                        <div className="information-block">
                            <div className="item">
                                <label>{t('hub:form.form.floor')}</label>
                                <Field fast onChange={setFieldValue}  onBlur={setFieldTouched} type="text" name="location.floor" />
                                <FieldError errors={errors} touched={touched} name="location.floor"/>
                            </div>
                            <div className="item">
                                <label>{t('hub:form.form.roomNumber')}</label>
                                <Field fast onChange={setFieldValue}  onBlur={setFieldTouched}  type="text" name="location.room"/>
                                <FieldError errors={errors} touched={touched} name="location.room"/>
                            </div>
                        </div>
                        <div>
                            <label>{t('hub:form.form.otherInformation')} {values.location.site.value === 'OTHER_SITE' || values.location.building && values.location.building.value === 'OTHER_BUILDING' ? '' : '(optional)'}</label>
                            <Field onChange={setFieldValue} onBlur={setFieldTouched} type="text" name="location.otherInformation" component="textarea"/>
                            <FieldError errors={errors} touched={touched} name="location.otherInformation"/>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
            <ErrorMessage errorKey="SUBMIT_FORM" message="Leider ist ein Problem aufgetreten. Bitte versuchen Sie es erneut oder laden Sie die Seite neu."/>
            <div className="submit">
            <Button type="submit" isLoading={props.isLoading} size="large" type="submit" variant="default-secondary"  borderStyle="round" height="35" spaced="true">{t('hub:form.form.submit')}</Button>
            </div>
        </form>
    );
}



    const FormHubForm = (props) => {

    const emptyOption = null
    const validate = (values) =>{
        let errors = {}
        if(!values.location.room){
            
            errors= {
                ...errors, 
                location: {
                    ...errors.location,
                    room: "Bitte Raum angeben."
                }
            }
        }
        if(!values.location.floor){
            errors= {
                ...errors, 
                location: {
                    ...errors.location,
                    floor: "Bitte Etage angeben."
                }
            }
        }
        if(!values.location.site){
            
            errors= {
                ...errors, 
                location: {
                    ...errors.location,
                    site: "Bitte Werk angeben."
                }
            }
        }
        if(!values.location.building){
            errors= {
                ...errors, 
                location: {
                    ...errors.location,
                    building: "Bitte Gebäude angeben."
                }
            }
        }

        if(!values.replacementCategory){

            errors= {
                ...errors,
                replacementCategory: "Bitte Kategorie auswhählen"
            }
        }
        if(!values.retained){
            errors= {
                ...errors,
                retained: "Bitte ausfüllen."
            }
        }
        if(values.location.site.value === 'OTHER_SITE' || values.location.building.value === 'OTHER_BUILDING'){
            
            if(!values.location.otherInformation || values.location.otherInformation === ""){
                
                errors = {
                    ...errors,
                    location: {
                        ...errors.location,
                        otherInformation: "Bitte genauere Angaben machen."
                    }
                }
            }
        }
        return errors
    }

    const EnhancedForm = withFormik({
        isInitialValid: true,
        handleSubmit: (values)=>{
            const result = {
                location:{
                    site: values.location.site.value, 
                    building: values.location.building.value,
                    otherInformation: values.location.otherInformation,
                    room: values.location.room,
                    floor: values.location.floor
                },
                order: {
                    retained: values.retained,
                    replacementCategory: values.replacementCategory.value,
                    // isRemove: values.isRemove.value,
                }
            }
            props.onSubmit(result)
        },
        validate: validate,
        mapPropsToValues: ({ressource}) => ({
            location: {
                site: ressource.location.site ? {value: ressource.location.site, label: ressource.location.site} : emptyOption,
                building: ressource.location.building ? {value: ressource.location.building, label: ressource.location.building} : emptyOption,
                otherInformation: ressource.location.otherInformation || "",
                floor: "",
                room: "",
            },
            retained: {value: "NO", label: "Nein"},
            isRemove: {
                label: "Ja ich benötige ein neues Gerät.",
                value: "NO"
            },
            replacementCategory: props.config.deviceCategories.find(e => e.value === ressource.tier) || null

        }),
        })(Form)
        return (
            <EnhancedForm {...props}/>
        );
    }
     
 
export default FormHubForm;












