
export const selectIsLoading = (state, activity, id) => {
    const key = `${activity}${id ? `_${id}` : ''}`;
    const loading = state.ui.loading[key]     
    return loading
}

export const selectIsShownRenameCardset = (state, id) =>{
    return state.ui.selected.RENAME_CARDSET === id
}
export const selectIsShownRenameCourse = (state, id) =>{
    return state.ui.selected.RENAME_COURSE === id
}

export const selectError = (state, key) =>{
    return state.ui.errors[key]
}

export const selectIsShownCreateCourse = state => (state.ui.show["CREATE_COURSE_DIALOG"])
export const selectIsShownEditCourse = state => (state.ui.selected.EDIT_COURSE_TITLE ? true : false)
export const selectSelectedCourse = state => (state.ui.selected.EDIT_COURSE_TITLE)
export const selectRedirect = state =>(state.ui.selected.REDIRECT)
export const selectEvent = (state, key) =>(state.ui.selected[key])