import React from 'react'
import {withFormik} from 'formik'
import {DrawerForm, DrawerFormItem} from '../Drawer'
import Button from '../elements/Button';
import Field from '../elements/Field'
import Select from '../elements/Select'

const options = [
    {
        value: 'EMAIL',
        label: 'e-Mail'
    },
    {
        value: 'PHONE',
        label: 'Telefon'
    },
    {
        value: 'SKYPE',
        label: 'Skype'
    }
]

const BaseForm = props =>{
    
    
    const {
        isValid,
        setFieldValue,
        handleSubmit,
      } = props;

      return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <DrawerForm>
                <DrawerFormItem title="Channel">
                <Select
                name="channel"
                onChange={setFieldValue}
                options={options}
                />
                </DrawerFormItem>
                <DrawerFormItem title="Notiz">
                <Field placeholder="Notiz / Status" component="textarea" name="note"/>
                </DrawerFormItem>
                <Button type="submit" block disabled={!isValid} isLoading={props.isLoading}>Speichern</Button>
                </DrawerForm>
            </form>
        
        </React.Fragment>
      )
}

export const ContactAttemptForm = (props) =>{
    const EnhancedForm = withFormik({
        handleSubmit: (values)=>{

            props.onSubmit && props.onSubmit({
                ...values, 
                channel: values.channel.value,
                startDate: new Date()})
        },
        validate: (values)=>{return !values.note || !values.channel ? {description: 'Darf nicht leer sein'} : {}},
        mapPropsToValues: ({ressource}) => ({
            note: "",
            channel: ""
        }),
        })(BaseForm)
    return(
        <EnhancedForm {...props}/>
    )
}