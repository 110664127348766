import React from 'react';
// import {Switch} from 'react-router-dom'
// import PublicLayout from '../hoc/Layouts/PublicLayout'
import {
    Redirect
  } from 'react-router-dom'
import {PublicRoute, NotFoundRoute} from '../hoc/routes'


import HomePage from '../pages/website/HomePage'

export const WebsiteRouter = (props) => {
    // const path = props.match.path
    return (
        <Redirect to='/auth/login' />
        // <PublicLayout>            
        //     <Switch>
        //         <PublicRoute path={path} exact={true} component={HomePage}/>
        //         <NotFoundRoute path="/"/>
        //     </Switch>
        // </PublicLayout>
    )
}
