import React, {useState, useEffect} from 'react'

import {connect} from 'react-redux'
import {selectServiceRanges} from '../../../../store/selectors'
import MainHeaderWrapper from '../../../../components/MainHeaderWrapper'
import MainContentWrapper from '../../../../components/MainContentWrapper';
import ServiceRangesTable from '../../../../components/ServiceRangesTable';
import H3 from '../../../../components/elements/H3'



const AppointmentsPage = (props) =>{

    

    
    




    return (
        <React.Fragment>
            <MainContentWrapper>
            <MainHeaderWrapper>
                <H3
                lineHeight="40px"
                >Alle Zeitfenster</H3>
            </MainHeaderWrapper>
                <ServiceRangesTable  data={props.serviceRanges}/>
            </MainContentWrapper>
           
        </React.Fragment>
    )
}

const mapStateToProps = state =>({
    serviceRanges: selectServiceRanges(state)
})

export default connect(mapStateToProps, null)(AppointmentsPage)