import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Text.scss'

const styleMapping = {
  headline: 'h2',
  modalHeading: 'h3'
} 


const Text = props => {

  const classes = props.plain ? 'text' : classNames(
    'text',
    props.className,
       {
        [`text-style-${props.textStyle}`]: props.textStyle && props.textStyle !== 'inherit' && !props.plain,
        [`text-font-size-${props.fontSize}`]: props.fontSize,
        [`text-font-weight-${props.fontWeight}`]: props.fontWeight,
        [`text-line-height-${props.lineHeight}`]: props.lineHeight,
        [`text-align-${props.textAlign}`]: props.textAlign,
        [`text-status-${props.status}`]: props.status,
        'text-loading': props.isLoading,
        'truncate': props.truncate,
        'text-status': props.status,
        'text-paragraph': props.paragraph,
        'text-font-serif': props.font && props.font === 'serif'
    }
  )
    

  const Component =
    props.component ||
    (props.paragraph ? 'p' : styleMapping[props.textStyle]) ||
    'span';
  
  return (
    <Component className={classes} to={props.to} href={props.href} onClick={props.onClick}>
      {props.children}
    </Component>
  )

}

Text.defaultProps = {
  fontSize: null, 
  fontWeight: null,
  textAlign: null,
  lineHeight: null,
  font: null,
  paragraph: false,
  isLoading: false,
  plain: false
}

Text.prototypes={
  isLoading: PropTypes.bool
}


 

export default Text

