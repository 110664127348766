export const LOCATION_OPTIONS = {
    "DE": {
        "Friedrichshafen": [],
        "Rödermark":[],
        "Hamburg":[],
        "Kassel":[],
        "St.Augustin":[],
    },
     
}

export const SOFTWARE_OPTIONS = [];

export const DEVICE_CATEGORY_OPTIONS = [
    {
        value:"Computer",
        label: "Computer"
    },
    {
        value:"Notebook",
        label: "Notebook"
    },
    {
        value:"Workstation",
        label: "Workstation"
    },
    {
        value:"Notebook-Special",
        label: "Notebook-Special"
    },
];

export const APPOINTMENT_CONFIG ={ 
	CHANGE:{
		timeFrame: {
			start: "9:00",

			end: "17:00"
		},
		intervalLength:15,
		includeWeekends: false,
		duration: 60,
		allowedOverlap: 2,
		buffer:60,
		exceptions:[
			{
				type: "TIME",
				startTime: "12:00",
				endTime: "13:00"
			}
		]

	},
	SAVE: {
		timeFrame: {
			start: "9:00",
			end: "17:00"
		},
		intervalLength:15,
		includeWeekends: false,
		duration: 15,
		allowedDaysAdvance: 7,
		allowedOverlap: 1,
		buffer: 60,
		exceptions:[
			{
				type: "TIME",
				startTime: "12:00",
				endTime: "13:00"
			}
		]
	},
	RESTORE: {
		timeFrame: {
			start: "9:00",
			end: "17:00"
		},
		intervalLength:15,
		includeWeekends: false,
		duration: 15,
		allowedDaysDelay: 7,
		allowedOverlap: 1,
		exceptions: [
			{
				type: "TIME",
				startTime: "12:00",
				endTime: "13:00"
			}
		]
	}
	
}