import {colors} from './colors'
export const buttons = {
    default: {
        primary: {
            color: colors.neutral.grays[200],
            fill: 'white',
            backgroundColor: colors.brand.primary[500],
            border: 'none',
            '&:hover': {
                backgroundColor: colors.brand.primary[300],
            },
            '&:active': {
                backgroundColor: colors.brand.primary[300],
            },
            '&:disabled': {
                opacity: '0.5'
            },
            '&.active': {
                backgroundColor: colors.brand.primary[700],
            },
        },
        secondary: {
            color: colors.neutral.grays[200],
            fill: 'white',
            backgroundColor: colors.brand.secondary[500],
            border: 'none',
            '&:hover': {
                backgroundColor: colors.brand.secondary[300],
            },
            '&:active': {
                backgroundColor: colors.brand.secondary[300],
            },
            '&:disabled': {
                opacity: '0.5'
            },
            '&.active': {
                backgroundColor: colors.brand.secondary[700],
            },
        },
        neutral:{
            color: colors.neutral.grays[700],
            fill: 'white',
            backgroundColor: colors.neutral.grays[400],
            border: 'none',
            '&:hover': {
                backgroundColor: colors.neutral.grays[300],
            },
            '&:active': {
                backgroundColor: colors.neutral.grays[500],
            },
            '&:disabled': {
                opacity: '0.5'
            },
            '&.active': {
                backgroundColor: colors.neutral.grays[500],
            },
        },
        danger: {
            color: colors.neutral.grays[200],
            fill: 'white',
            backgroundColor: colors.status.danger[300],
            border: 'none',
            '&:hover': {
                backgroundColor: colors.status.danger[500],
            },
            '&:active': {
                backgroundColor: colors.status.danger[500],
            },
            '&:disabled': {
                opacity: '0.5'
            },
            '&.active': {
                backgroundColor: colors.status.danger[500],
            },
        },

    }, 
    pastel: {
        secondary: {
            color: colors.brand.secondary[500],
            fill: colors.brand.secondary[500],
            backgroundColor: colors.neutral.grays[300],
            fontWeight: '600',
            border: 'none',
            '&:hover': {
                backgroundColor: colors.neutral.grays[350],
            },
            '&:active': {
                backgroundColor: colors.neutral.grays[350],
            },
            '&:disabled': {
                opacity: '0.5'
            },
            '&.active': {
                backgroundColor: colors.neutral.grays[350],
            },
        }

    },
    flat: {
        neutral:{
            color: colors.neutral.grays[500],
            fill: colors.neutral.grays[500],
            border: 'none',
            background: 'transparent', 
            '&:hover': {
                fill: colors.neutral.grays[600],
                color: colors.neutral.grays[600],  
            },
            '&:active': {
                fill: colors.neutral.grays[600],
                color: colors.neutral.grays[600],
            },
            '&:disabled': {
                
                opacity: '0.5'
            },
            '&.active': {
                fill: colors.neutral.grays[600],
                color: colors.neutral.grays[600],
            },
        },
        neutralLight:{
            color: colors.neutral.grays[400],
            fill: colors.neutral.grays[400],
            border: 'none',
            background: 'transparent', 
            '&:hover': {
                fill: colors.neutral.grays[500],
                color: colors.neutral.grays[500],  
            },
            '&:active': {
                fill: colors.neutral.grays[500],
                color: colors.neutral.grays[500],
            },
            '&:disabled': { 
                
                opacity: '0.5'
            },
            '&.active': {
                fill: colors.neutral.grays[500],
                color: colors.neutral.grays[500],
            },
        },
        neutralPrimary:{
            color: colors.neutral.grays[500],
            fill: colors.neutral.grays[500],
            border: 'none',
            background: 'transparent', 
            '&:hover': {
                fill: colors.brand.primary[500],
                color: colors.brand.primary[500],
            },
            '&.active': {
                fill: colors.brand.primary[800],
                color: colors.brand.primary[800],
            },
            '&:active': {
                fill: colors.brand.primary[800],
                color: colors.brand.primary[800],
            },
            '&:disabled': {
                opacity: '0.5'
            }
        },
    },
    outlined: {
        neutral:{
            color: colors.neutral.grays[400],
            fill: colors.neutral.grays[400],
            background: 'none',
            border: `2px solid ${colors.neutral.grays[400]}`,
            transition: 'all 0.15s ease-in-out',
            '&:hover': {
                fill: colors.neutral.grays[200],
            background: colors.neutral.grays[500],
            color: colors.neutral.grays[200],   
            
            border: `2px solid rgba(0,0,0,0.1)`
            },
            '&:active': {
                fill: colors.neutral.grays[200],
                background: colors.neutral.grays[500],
            color: colors.neutral.grays[200],
            },
            '&:disabled': {
                opacity: '0.5'
            },
            '&.active': {
                fill: colors.neutral.grays[200],
                background: colors.neutral.grays[500],
            color: colors.neutral.grays[200],
            },
        },
        light:{
            color: colors.neutral.grays[400],
            fill: colors.neutral.grays[400],
            background: 'none',
            border: `1px solid ${colors.neutral.grays[400]}`,
            transition: 'all 0.15s ease-in-out',
            '&:hover': {
            fill: colors.neutral.grays[500],
            background: 'none',
            color: colors.neutral.grays[500],
            border: `1px solid ${colors.neutral.grays[500]}`
            },
            '&:active': {
            fill: colors.neutral.grays[200],
            background: colors.neutral.grays[500],
            color: colors.neutral.grays[200],
            },
            '&:disabled': {
                opacity: '0.5'
            },
            '&.active': {
            fill: colors.neutral.grays[200],
            background: colors.neutral.grays[500],
            color: colors.neutral.grays[200],
            },
        },
    }
}