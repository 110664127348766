
import {connect} from 'react-redux'
import {selectAbilities} from '../../store/selectors/authSelector'
import {getSubject} from '../../authManager'


const Can = (props) => {

    return ( 
        props.abilities && props.abilities.can(props.I, getSubject(props.on, props.with)) && props.children
     ); 
}

const mapStateToProps = state => ({
    abilities: selectAbilities(state)
})
 
export default connect(mapStateToProps, null)(Can);