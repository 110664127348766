import React from 'react'
import {connect} from 'react-redux'
import {
  Route,
  Redirect
} from 'react-router-dom'

import {isAuthenticated} from '../../store/selectors/authSelector'

const mapStateToProps = state =>({
  isAuthenticated: isAuthenticated(state)
})

export const PublicRoute = connect(mapStateToProps, null)(({ 
    component: Component,
    isAuthenticated, 
    ...rest }) => (
    <Route {...rest} render={(props) => (
      !isAuthenticated
        ? <Component {...props} />
        : <Redirect to='/app/home/my-appointments' />
    )} />
))

PublicRoute.propTypes = {
    ...Route.propTypes
}