import React, {useState} from 'react';
import './LanguageSwitch.scss'
import i18n from './../../i18n';

import {components} from 'react-select'
import Select from './../elements/Select';
import ReactCountryFlag from "react-country-flag";
import { lang } from 'moment';


const languageFlagMap = {
    de: 'de',
    en: 'gb'
}

const languageOptions = [
    {value: "de", label: "Deutsch"},
    {value: "en", label: "English"},
];

const getOptionForLanguage = code => languageOptions.filter(e=>(e.value === code.substring(0,2)))[0]

const Option = (props) => {
    return (
      <components.Option  {...props}>
            <ReactCountryFlag code={languageFlagMap[props.data.value]} /> {props.children}
      </components.Option>
    );
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <ReactCountryFlag code={languageFlagMap[props.data.value]} /> {children}
    </components.SingleValue>
  );
  

const LanguageSwitch = (props) => {

    const [_selectedLanguage, _setSelectedLanguage] = useState(getOptionForLanguage(i18n.language))


    const currentLang = i18n.language;
   
    const handleLanguageChange = (value) =>{
        _setSelectedLanguage(value)
        i18n.changeLanguage(value.value);
    }
    return (
        <div>
            
            <Select
            value={_selectedLanguage}
            isSearchable={false}
            onChange={handleLanguageChange}
            options={languageOptions}
            components={{
                Option,
                SingleValue
            }}
            />  
        </div>
    );
}
export default LanguageSwitch;