import React from 'react';
import { Switch, Route } from "react-router-dom";
import {PublicRoute, PrivateRoute} from '../hoc/routes'
import {WebsiteRouter} from './WebsiteRouter'
import {AppRouter} from './AppRouter'
import {AuthRouter} from './AuthRouter'

import Logout from '../components/Logout'
import MakeAppointmentHub from '../pages/hub/MakeAppointmentHub'
import FormHub from '../pages/hub/FormHub'
import MakeReportHub from '../pages/hub/MakeReportHub'
import {socket} from '../api/socketManager'




 export const MainRouter = () => {
    socket.on('news', (data)=>{
        
    })
    return (
        <Switch>          

            <PrivateRoute path="/app" component={AppRouter}/>
            <PrivateRoute path="/logout" component={Logout}/>
            <PublicRoute path="/auth" component={AuthRouter}/>
            <Route path="/appointment/:token" component={MakeAppointmentHub}/>
            <Route path="/report/:token" component={MakeReportHub}/>
            <Route path="/questionaire/:token" component={FormHub}/>
            <Route path="/" component={WebsiteRouter}/>

            
            
        </Switch>
    );
}


