import {combineReducers} from 'redux'
import {loadingReducer} from './loadingReducer'
import {errorsReducer} from './errorsReducer'
import {showReducer} from './showReducer'
import {selectedReducer} from './selectedReducer'

const uiReducer = combineReducers({
    loading: loadingReducer,
    errors: errorsReducer,
    show: showReducer,
    selected: selectedReducer
})

export default uiReducer
