import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import HubLayout from '../../../hoc/Layouts/HubLayout';
import Icon from '../../../components/elements/Icon'
import AlertBox from '../../../components/AlertBox'
import {fetchReportData, createReport} from '../../../store/actions'
import {selectReportRessource, selectServiceRangeSlots, selectIsLoading, selectServiceRangeConfig, selectServiceRangeStatus} from '../../../store/selectors'
import logo from '../../../assets/ci/logo.png'
import MakeReportForm from './MakeReportForm'

import '../hub.scss';



const MakeReportHub = (props) => {
     useEffect(()=>{
        props.fetchReportData(props.match.params.token)
    }, [])

    const handleSubmit = (data)=>{
        props.createReport(props.ressource.id, data)
    }

    return (
        <HubLayout>
            {true && <div className="hub">
                <div className="hub-sidebar">
                <div className="hub-sidebar-inner">
                    <div className="hub-sidebar-logo">
                        <img src={logo} alt="robot" />
                    </div>
                    <div className="hub-sidebar-text  ">
                    <h1>Abschluss Report </h1>
                    <h2>Hardwaretausch 2024 Zeppelin</h2>
                    <p>Bitte füllen Sie das Formular zusammen mit dem Servicetechniker aus.</p>
                    <p>Bei Fragen stehen wir Ihnen jederzeit unter <a href="mailto:rollout@int-group.de">rollout@int-group.de</a> Verfügung.</p>
                    </div>
                     
                    
                </div>
                </div>
                <div className="hub-content">                    
                        {
                        props.ressource && !props.ressource.isConfirmedByCustomer
                        ?
                        <MakeReportForm 
                        slots={props.slots} 
                        ressource={props.ressource}
                        config={props.config} 
                        isLoading={props.isLoading}
                        onSubmit={handleSubmit}
                        />
                        :
                        <AlertBox>Protokoll erfolgreich eingereicht.</AlertBox>
                        }                 
                </div>
            </div>}
        </HubLayout>
    );
}

const mapDispatchToProps = dispatch =>({
    fetchReportData: (token) => dispatch(fetchReportData(token)),
    createReport: (id, data) => dispatch(createReport(id, data))
})

const mapStateToProps = (state, props) =>({
    ressource: selectReportRessource(state),
    isFetching: selectIsLoading(state, "FETCH_REPORT_DATA"),
    isLoading: selectIsLoading(state, "CREATE_REPORT"),
})

export default connect(mapStateToProps, mapDispatchToProps)(MakeReportHub);