import React from 'react';
import { useTranslation  } from 'react-i18next';

export const HubRessourceInformation = (props) => {
    const { t } = useTranslation();
    return (
        <div>
             <h2>{t('hub:appointment.form.information')}</h2>
                <div className="information-block">
                    <div className="item">
                        <label>{t('hub:appointment.form.user')}</label>
                        <span>{props.ressource && props.ressource.customer && props.ressource.customer.firstName + ' ' + props.ressource.customer.lastName}</span>
                    </div>
                    <div className="item">
                        <label>Ci-Name</label>
                        <span>{props.ressource && props.ressource.ciName}</span>
                    </div>
                    <div className="item">
                        <label>Kategorie</label>
                        <span>{props.ressource && props.ressource.tier}</span>
                    </div>
                    <div className="item">
                        <label>{t('hub:appointment.form.model')}</label>
                        <span>{props.ressource && props.ressource.model}</span>
                    </div>
                    
                </div>
        </div>
    );
}