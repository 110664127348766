import React from 'react'
import {Field as BaseField} from 'formik'
import './Field.scss'
import classNames from 'classnames'
const FastField = (props)=>{

    const Component = props.component ? props.component : 'input'; 
    const handleOnBlur = (value) =>{
        if(props.onBlur){
            props.name ? props.onBlur(props.name)
            : props.onBlur()
        }
        if(props.onChange){
            
            props.name ? props.onChange(props.name, value)
            : props.onChange(value)
        }
        
    }
    return(
        <Component className={props.className} onBlur={(e)=>{
            handleOnBlur(e.target.value)
        }} placeholder={props.placeholder} type={props.type} name={props.name}/>
    )
}


const PlainField = props =>{
    const Component = props.component ? props.component : 'input';
    const handleChange = (value) =>{
        props.onChange && props.onChange(value)
    }
    return(
        <Component className={props.className} placeholder={props.placeholder} onChange={(e)=>{handleChange(e.target.value)}}value={props.value} name={props.name} type={props.type}/>
    )
}

const Field = (props) => {
    

    const Base = props.fast ? FastField : !props.plain ? BaseField : PlainField;

    const classes = classNames(
        'input-field',
        props.className,
        {
            'disabled': props.disabled,
        }

    )
    return (
        props.fast ?
        <FastField component={props.component ? props.component : 'input'} value={props.value} onChange={props.onChange} onBlur={props.onBlur} className={classes} error={props.error ?  "true": null} placeholder={props.placeholder} type={props.type} name={props.name}/>
        :
        props.plain ?
        <PlainField component={props.component ? props.component : 'input'} onChange={props.onChange} onBlur={props.onBlur} className={classes} value={props.value} error={props.error ?  "true": null} placeholder={props.placeholder} type={props.type} name={props.name}/>
        :
        <BaseField component={props.component ? props.component : 'input'} className={classes} error={props.error ?  "true": null} placeholder={props.placeholder} type={props.type} name={props.name}/>
    );
}
 
export default Field;
