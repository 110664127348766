import {useEffect} from 'react';
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom'
import { selectRedirect } from "../../store/selectors";
import { unSetRedirect } from "../../store/actions/uiActions";

const ReduxRedirect = (props) => {

    useEffect(()=>{ 
        if(props.redirect){
            props.history.push(props.redirect)
            props.unSetRedirect()
        }
    }, [props.redirect])

    return (
        null
    );
}

const mapStateToProps = (state, props) =>({
    redirect: selectRedirect(state, props.event)
})
const mapDispatchToProps = dispatch => ({
    unSetRedirect: () =>{dispatch(unSetRedirect())}
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReduxRedirect));
