import React from 'react'
import {UserAvatar} from './UserAvatar'
import Avatar from './Avatar'

import classNames from 'classnames'
import './AvatarGroup.scss'

export const AvatarGroup = React.memo((props) => {
    const classes = classNames(
        'avatar-group',
        props.className,
        {  
             [`avatar-group-size-${props.size}`]: props.size && props.size !== "inherit",
        }
    )

    return ( 
        <div className={classes}>
            {props.users &&
            <ul>
                {
                 props.users.slice(0,4).map((user, i)=>(
                    <li key={user.id}>
                        <UserAvatar size="inherit" variant={`rainbow-${i}`} user={user}  />
                    </li>
                ))
                }
                {
                props.users.length === 5 ? 
                    <li key={props.users[4].id}>
                        <UserAvatar size="inherit" text="from" user={props.users[4]} variant="rainbow-4"/>
                    </li> : null
                }
                {
                props.users.length > 5 ? 
                    <li>
                        <Avatar text={`+${props.users.length-4}`} size={`${props.size}`} variant="neutral" fontSize="11"></Avatar>
                    </li> : null
                }
                
            </ul>
            }
        </div>
     );
})
AvatarGroup.defaultProps = {
    size: 40
};
 