import React from 'react'
import SubNavigationItem from '../SubNavigationItem'
import Text from '../../elements/Text'
import {uid} from 'react-uid';

const SubNavigationSection = (props) => {
    return ( 
        <ul>
            {props.title && <li><Text>{props.title}</Text></li>}
            {
                props.links.length > 0 && props.links.map((e)=>{
                    return (
                        <li key={e.id ? e.id : uid(e)}><SubNavigationItem exact title={e.title} icon={e.icon} to={e.to}/></li>
                    )
                })
            }
        </ul>
     );
}
 
export default SubNavigationSection;