import React, {useState} from 'react'
import BaseSwitch from "react-switch";


const Switch = props => {


        const [_checked, _setChecked] = useState(props.isChecked || false)
        const handleToggle = value =>{
            _setChecked(value)
            
            if(props.onChange){
                props.name ? props.onChange(props.name, value) : props.onChange(value)
            }
            
        }

        return(
            <BaseSwitch offColor="#D3D8F4" checked={_checked} onChange={handleToggle} onColor="#1E86FF" height={24} uncheckedIcon={false} checkedIcon={false}/>
        );

}
export default Switch
