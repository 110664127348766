import {useState} from 'react'

export const useShowHide = (initialState) =>{
    let init = initialState ? initialState : {};
    const [isShown, setIsShown] = useState(init)
    const show = (key) => {
        setIsShown({
            ...isShown,
            [key]: true           
        })
    }
    const hide = (key) => {
        setIsShown({
            ...isShown,
            [key]: false           
        })
    }

    return {
        isShown, 
        show, 
        hide
    }

}