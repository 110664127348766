import {schema} from 'normalizr';


const assigneeProcessStrategy = (value, {location, id, type, ciName, ciNameNew, customer, removeOnly}, key) =>{
    const processed = {
        ...value, 
        ressource: {
            location,
            customer,
            id, 
            type, 
            ciName,
            ciNameNew,
            removeOnly,
        }
    }
    return processed
}

export const User = new schema.Entity('users')

export const ServiceRange= new schema.Entity('serviceRanges')

export const Appointment = new schema.Entity('appointments',{
    
}, 
{
    processStrategy: assigneeProcessStrategy
})

export const Ressource = new schema.Entity('ressources', {
    restoreAppointment: Appointment,
    saveAppointment: Appointment,
    changeAppointment: Appointment

})

