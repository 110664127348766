import React from 'react'
import './Badge.scss';
import classNames from 'classnames';

const Badge = (props) => {
    
    const getLabel = () =>{
         return props.valueMap[props.value] ? props.valueMap[props.value].label : props.value;
    }

    const getVariant = () =>{
        return props.variant ? props.variant 
            : props.valueMap[props.value] ? props.valueMap[props.value].variant : 'default'
    }


    const classes = props.plain ? 'text' : classNames(
        'buffl-badge',
        props.className,
        `bb-style-${getVariant()}`
      )

    return ( 
        <div className={classes}>
            {props.value ? getLabel(props.value) : props.children}
        </div>
     );
}

Badge.defaultProps={
    valueMap:{
        'OPEN':{
            label: 'Offen',
            variant: 'neutral'
        },
        'WAITING_FOR_ORDER':{
            label: 'User Angefragt',
            variant: 'secondary'
        },
        'WAITING_FOR_ORDER_APPROVAL':{
            label: 'Ticket ausstehend',
            variant: 'secondary'
        },
        'ORDER_CREATED':{
            label: 'Ticket bereit',
            variant: 'secondary'
        },

        'WAITING_FOR_APPOINTMENT':{
            label: 'Terminierung',
            variant: 'secondary'
        },
        'APPOINTED_NEEDS_CHECK':{
            label: 'Falsche Ort',
            variant: 'danger'
        },
        'NEEDS_REVIEW':{
            label: 'Falsche Ort',
            variant: 'danger'
        },
        'IN_PROGRESS':{
            label: 'In Arbeit',
            variant: 'warning'
        },
        'INDIVIDUAL_WITH_RESSOURCE':{
            label: 'Individuell',
            variant: 'secondary'
        },
        'BATCH_WITH_LOCATION':{
            label: 'Batch',
            variant: 'neutral'
        },
        'WORK_STEPS_DONE':{
            label: 'Bearbeitet ',
            variant: 'success'
        },
        'DONE':{
            label: 'Abgeschlossen ',
            variant: 'success'
        },
        'OTHER':{
            label: 'Sonstige ',
            variant: 'danger'
        },
        'IN_CUSTOMER_DEPOT':{
            label: 'Im Kundenlager',
            variant: 'warning'
        },        
        'IN_VENDOR_DEPOT':{
            label: 'INT Lager',
            variant: 'warning'
        },        
    }
}
 
export default Badge;


