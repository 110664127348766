import React, {useEffect} from 'react';
import {MainRouter} from './routers'
import ReduxRedirect from "./components/ReduxRedirect";
import {connect} from 'react-redux'
import {checkAuthStatus} from './store/actions'
import {authIsMounted} from './store/selectors'
import config from './config'
import { withRouter } from 'react-router-dom'

import {SocketDispatcher} from "./store";
const App = (props) =>{
    
  
  
  useEffect(()=>{
    props.checkAuthStatus()
  },[props.authIsMounted])

  return (
    props.authIsMounted
    ?
    <React.Fragment>
      <SocketDispatcher/>
      <ReduxRedirect/>
      <MainRouter/>
    </React.Fragment>
    : 
    null
  )
}

const mapDispatchToProps = dispatch =>({
    checkAuthStatus: () => dispatch(checkAuthStatus()),
})

const mapStateToProps = state =>({
  authIsMounted: authIsMounted(state)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
