import React, {useState} from 'react'
import './Tabs.scss'
import Menu, {MenuItem} from '../Menu'
import {uid} from 'react-uid'

export const TabsMenu = (props) => {

    const _children = props.children && props.children.length > 0 && props.children.filter(e=>(React.isValidElement(e)))
    const [_selected, _setSelected] = useState(0)

    const handleClick = (i, e) =>{
        _setSelected(i)
        
        
        if(e.props.onClick){
            e.props.name ? e.props.onClick(e.props.name) : e.props.onClick()
        }
        
    }
    
    return (
        _children 
        ?
        <div className="tabs">
            <div className="tabs-bar">
                <Menu>
                    {_children.map((e, i) => {
                        return <MenuItem
                        active={_selected === i}
                        key={uid(e)}
                        onClick={()=>{handleClick(i, e)}}
                        >
                        {e.props.title || `Tab ${i}`}
                        </MenuItem>
                    })}
                </Menu>
            </div>
            {props.children && <div className="tabs-content">
                {_children[_selected]}
            </div>}
        </div>
        : null 
    );
}

const Tabs = (props) => {

    const _children = props.children && props.children.length > 0 && props.children.filter(e=>(React.isValidElement(e)))
    const [_selected, _setSelected] = useState(0)

    
    return (
        _children 
        ?
        <div className="tabs">
            <div className="tabs-bar">
                <Menu>
                    {_children.map((e, i) => {
                        return <MenuItem
                        active={_selected === i}
                        key={uid(e)}
                        onClick={()=>{_setSelected(i)}}
                        >
                        {e.props.title || `Tab ${i}`}
                        </MenuItem>
                    })}
                </Menu>
            </div>
            {props.children && <div className="tabs-content">
                {_children[_selected]}
            </div>}
        </div>
        : null 
    );
}

export default Tabs