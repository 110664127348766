import { createSelector } from "reselect";

const _selectUsers = state => (Object.values(state.entities.users))

export const selectUser = (state, id) => (state.entities.users[id])

export const selectUsers = createSelector(
    [_selectUsers],
    (items)=>(items.filter(e=>(true)))
)

