import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom'
import HomeLayout from '../hoc/Layouts/HomeLayout'
import {NotFoundRoute} from '../hoc/routes'
import MyAppointmentsPage from '../pages/app/home/MyAppointmentsPage';
import AppointmentsPage from '../pages/app/home/AppointmentsPage';
import ServiceRangesPage from '../pages/app/home/ServiceRangesPage';
import AppointmentsDonePage from '../pages/app/home/AppointmentsDonePage';
import RessourcesPage from '../pages/app/home/RessourcesPage';

export const HomeRouter = (props) => {
    const path = props.match.path
    return (
        <HomeLayout>            
            <Switch>
                <Route path={`${path}/my-appointments`} component={MyAppointmentsPage}/>
                <Route path={`${path}/appointments`} component={AppointmentsPage} />
                <Route path={`${path}/machines`} component={RessourcesPage} />
                <Route path={`${path}/service-ranges`} component={ServiceRangesPage} />
                <Route path={`${path}/appointments-done`} component={AppointmentsDonePage} />
                <Route path={`${path}`} render={
                    (props)=>{
                    return(<Redirect to={`${props.match.path}/my-appointments`} />)
                }
                }/>
                <NotFoundRoute  path={`${path}`} />
            </Switch>
        </HomeLayout>
    )
}
