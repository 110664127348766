import React, {useState, useEffect} from 'react'
import {TabsMenu, Tab} from '../../../../components/Tabs'
import {connect} from 'react-redux'
import {selectAppointments} from '../../../../store/selectors'
import MainHeaderWrapper from '../../../../components/MainHeaderWrapper'
import MainContentWrapper from '../../../../components/MainContentWrapper';
import AppointmentsTable from '../../../../components/AppointmentsTable';
import H3 from '../../../../components/elements/H3'
import AppointmentDetailDrawer from '../../../../components/AppointmentDetailDrawer'


const AppointmentsPage = (props) =>{

    

    const [_selected, _setSelected] = useState()
    const [_section, _setSection] = useState("TODAY")
    
    
    useEffect((props)=>{
        
        if(_selected){
            
        }
    }, [])
    const handleRowSelect = rowInfo =>{
        _setSelected(rowInfo)
    }

    const handleCloseDrawerRequested = () =>{
        _setSelected()
    }

    const handleTabClicked = (section) => {
        _setSection(section)
    }
    console.log(props.appointments)

    return (
        <React.Fragment>
            <MainContentWrapper>
            <MainHeaderWrapper>
                <H3
                lineHeight="40px"
                >Alle Aufgaben</H3>
            </MainHeaderWrapper>
                <TabsMenu>
                    <Tab onClick={handleTabClicked} name="TODAY" title="Heute"></Tab>
                    <Tab onClick={handleTabClicked} name="OPEN" title="Offen"></Tab>
                    <Tab onClick={handleTabClicked} name="DONE" title="Erledigt"></Tab>
                    
                </TabsMenu>
                <AppointmentsTable section={_section} selected={_selected && _selected.index} onRowSelect={handleRowSelect} data={props.appointments}/>
            </MainContentWrapper>
            <AppointmentDetailDrawer 
            id={_selected && _selected.original.id}
            onRequestClose={handleCloseDrawerRequested}
            />
        </React.Fragment>
    )
}

const mapStateToProps = state =>({
    appointments: selectAppointments(state)
})

export default connect(mapStateToProps, null)(AppointmentsPage)