import React from "react";
import Field from "../../../components/elements/Field";
import Icon from "../../../components/elements/Icon";
import Switch from "../../../components/elements/Switch";
import ErrorMessage from "../../../components/ErrorMessage";
import { FieldError } from "../../../components/FieldGroup";

import ConfirmationButton from "../../../components/ConfirmationButton";

import { withFormik } from "formik";
import Select from "../../../components/elements/Select";
import "../hub.scss";

const _tierOptions = [
  {
    value: "NOTEBOOK",
    label: "Notebook",
  },
  {
    value: "DESKTOP",
    label: "Dekstop",
  },
];

const Form = (props) => {
  const {
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleSubmit,
    submitForm,
    setFieldTouched,
    ressource,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="hub-row">
        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-block-icon variant1">
              <Icon icon="calendar"></Icon>
            </div>
          </div>
          <div className="hub-block-right">
            <h2>Allgemein</h2>
          </div>
        </div>

        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-circle variant3">1</div>
          </div>
          <div className="hub-block-right">
            <h3>Altgerät </h3>
            <div className="hub-formarea">
              <div className="information-block">
                <div className="item">
                  <label>Hostname</label>
                  <Field className="short-input" name="STR_CINAME" />
                </div>
                <div className="item">
                  <label>Seriennummer</label>
                  <Field className="short-input" name="STR_SERIAL" />
                  <FieldError
                    errors={errors}
                    touched={touched}
                    name="hasMoreUsers"
                  />
                </div>
              </div>
              <div className="item">
                <label>Gerätetyp</label>
                <Select
                  skipSort
                  value={values.tier}
                  placeholder="Auswählen"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                    setFieldTouched(name, value);
                  }}
                  name="tier"
                  options={_tierOptions}
                  isSearchable={false}
                />
                <FieldError errors={errors} touched={touched} name="tier" />
              </div>
            </div>
          </div>
        </div>
        {ressource && !ressource.removeOnly &&   <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-circle variant3">2</div>
          </div>
          <div className="hub-block-right">
            <h3>Neugerät </h3>
            <div className="hub-formarea">
              <div className="information-block">
                <div className="item">
                  <label>Hostname</label>
                  <Field className="short-input" name="STR_CINAME_NEW" />
                </div>
                <div className="item">
                  <label>Seriennummer</label>
                  <Field className="short-input" name="STR_SERIAL_NEW" />
                  <FieldError
                    errors={errors}
                    touched={touched}
                    name="hasMoreUsers"
                  />
                </div>
              </div>
              <div className="item">
                <label>Gerätetyp</label>
                <Select
                  skipSort
                  value={values.tierNew}
                  placeholder="Auswählen"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                    setFieldTouched(name, value);
                  }}
                  name="tierNew"
                  options={_tierOptions}
                  isSearchable={false}
                />
                <FieldError errors={errors} touched={touched} name="tierNew" />
              </div>
            </div>
          </div>
        </div>}
      </div>

      <div className="hub-row">
        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-block-icon variant1">
              <Icon icon="building"></Icon>
            </div>
          </div>
          <div className="hub-block-right">
            <h2>Rückgabe Checkliste</h2>
            <p>Bitte wählen Sie alle zurückgegebenen Items aus.</p>
            <div className="hub-formarea">
              <div className="hub-checklist-item">
                <div className="hub-checklist-text">
                  <span>Tastatur</span>
                </div>
                <div className="hub-checklist-action">
                  <Switch
                    name="BOOL_KEYBOARD_RETURNED"
                    onChange={setFieldValue}
                  />
                </div>
              </div>
              <div className="hub-checklist-item">
                <div className="hub-checklist-text">
                  <span>Maus</span>
                </div>
                <div className="hub-checklist-action">
                  <Switch
                    name="BOOL_MICE_1_RETURNED"
                    onChange={setFieldValue}
                  />
                </div>
              </div>
              {values.tier && values.tier.value === "NOTEBOOK" && (
                <React.Fragment>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Zusätzliche Maus</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_MICE_2_RETURNED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Netzteil Dockingstation</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_CHARGER_1_RETURNED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Reisenetzteil Notebook</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_CHARGER_2_RETURNED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Dockingstation</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_DOCK_RETURNED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {values.tier && values.tier.value === "DESKTOP" && (
                <React.Fragment>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Schutzstecker</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_SCHUKO_RETURNED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
     {ressource && !ressource.removeOnly && <div className="hub-row">
        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-block-icon variant1">
              <Icon icon="desktop"></Icon>
            </div>
          </div>
          <div className="hub-block-right">
            <h2>Neugerät Checkliste</h2>
            <p>Bitte wählen Sie alle erhaltenen Items aus.</p>
            <div className="hub-formarea">
              <div className="hub-checklist-item">
                <div className="hub-checklist-text">
                  <span>Tastatur</span>
                </div>
                <div className="hub-checklist-action">
                  <Switch
                    name="BOOL_KEYBOARD_DELIVERED"
                    onChange={setFieldValue}
                  />
                </div>
              </div>
              <div className="hub-checklist-item">
                <div className="hub-checklist-text">
                  <span>Maus</span>
                </div>
                <div className="hub-checklist-action">
                  <Switch
                    name="BOOL_MICE_1_DELIVERED"
                    onChange={setFieldValue}
                  />
                </div>
              </div>
              {values.tierNew && values.tierNew.value === "NOTEBOOK" && (
                <React.Fragment>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Zusätzliche Maus</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_MICE_2_DELIVERED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Netzteil Dockingstation</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_CHARGER_1_DELIVERED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Reisenetzteil Notebook</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_CHARGER_2_DELIVERED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Dockingstation</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_DOCK_DELIVERED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {values.tierNew && values.tierNew.value === "DESKTOP" && (
                <React.Fragment>
                  <div className="hub-checklist-item">
                    <div className="hub-checklist-text">
                      <span>Schutzstecker</span>
                    </div>
                    <div className="hub-checklist-action">
                      <Switch
                        name="BOOL_SCHUKO_DELIVERED"
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>}

      <div className="hub-row">
        <div className="hub-block">
          <div className="hub-block-left">
            <div className="hub-block-icon variant1">
              <Icon icon="server"></Icon>
            </div>
          </div>
          <div className="hub-block-right">
            <h2>Sonstiges / Defekte</h2>
            <div className="hub-formarea">
              <label>Sonstige Anmerkungen (optional)</label>
              <Field type="text" name="STR_COMMENT" component="textarea" />
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage
        errorKey="REQUEST_APPOINTMENT"
        message="Leider ist ein Problem aufgetreten. Bitte versuchen Sie es erneut oder laden Sie die Seite neu."
      />
      <div className="submit">
        <ConfirmationButton
          disabled={!isValid}
          dialogImg="empty"
          closeDialogOnConfirm={true}
          onClick={handleSubmit}
          isLoading={props.isLoading}
          dialogTitle="Report Abschließen"
          dialogMessage="Mit diesem Formular bestätigen Sie, dass die ausgewählten Arbeitsschritte ausgeführt und der Hardwaretausch abgeschlossen wurde."
          size="large"
          variant="default-secondary"
          borderStyle="round"
          height="35"
          spaced="true"
        >
          Abschicken
        </ConfirmationButton>
      </div>
    </form>
  );
};

const MakeReportForm = (props) => {
  const validate = (values) => {
    let errors = {};
    if (!values.STR_CINAME) {
      errors = {
        STR_CINAME: "Bitte eingeben",
      };
    }
    if (!values.tierNew && !props.ressource.removeOnly) {
      errors = {
        tierNew: "Bitte angeben",
      };
    }
    if (!values.STR_CINAME_NEW && !props.ressource.removeOnly) {
      errors = {
        STR_CINAME_NEW: "Bitte eingeben",
      };
    }
    if (!values.STR_SERIAL) {
      errors = {
        STR_SERIAL: "Bitte eingeben",
      };
    }
    if (!values.STR_SERIAL_NEW && !props.ressource.removeOnly) {
      errors = {
        STR_SERIAL_NEW: "Bitte eingeben",
      };
    }
    console.log(errors);
    console.log(props.ressource);
    return errors;
  };

  const EnhancedForm = withFormik({
    isInitialValid: true,
    handleSubmit: (values) => {
      const result = {
        ...values,
        tier: values.tier.value,
        tierNew: values.tierNew.value,
      };
      props.onSubmit(result);
    },
    validate: validate,
    mapPropsToValues: ({ ressource }) => ({
      STR_CINAME: ressource.ciName,
      STR_CINAME_NEW: ressource.ciNameNew,
      STR_SERIAL: ressource.serialNumber,
      STR_SERIAL_NEW: ressource.serialNumberNew,
      BOOL_KEYBOARD_RETURNED: false,
      BOOL_MICE_1_RETURNED: false,
      BOOL_MICE_2_RETURNED: false,
      BOOL_DOCK_RETURNED: false,
      BOOL_CHARGER_1_RETURNED: false,
      BOOL_CHARGER_2_RETURNED: false,
      BOOL_SCHUKO_RETURNED: false,
      BOOL_KEYBOARD_DELIVERED: false,
      BOOL_MICE_1_DELIVERED: false,
      BOOL_MICE_2_DELIVERED: false,
      BOOL_DOCK_DELIVERED: false,
      BOOL_CHARGER_1_DELIVERED: false,
      BOOL_CHARGER_2_DELIVERED: false,
      BOOL_SCHUKO_DELIVERED: false,
      STR_COMMENT: "",
      tier: _tierOptions.find((e) => e.value === ressource.tier),
      tierNew: _tierOptions.find((e) => e.value === "NOTEBOOK")
    }),
  })(Form);
  return <EnhancedForm {...props} />;
};

export default MakeReportForm;
