import {normalize} from 'normalizr'
import {Ressource, User, Appointment, ServiceRange} from './schemas'

// export const coursesNormalizer = (data) =>{
    
//     const applyAcl = (courses, acl) =>(
//         courses.map(course=>({
//                 ...course, 
//                 acl: acl
//             })
//         ) 
//     )   
//     const concatedCourses = [
//         ...applyAcl(data.data.courses,  {isOwner: true, isStudent: false, isEditor: false}),
//         ...applyAcl(data.data.editorCourses,  {isOwner: false, isStudent: false, isEditor: true}),
//         ...applyAcl(data.data.studentCourses,  {isOwner: false, isStudent: true, isEditor: false})
//     ]
//     return normalize(concatedCourses, [Course])
// }


export const userNormalizer = (data) =>{
    return normalize(data.data.users, [User])
};
export const ressourcesNormalilzer = (data) =>{
    return normalize(data.data.ressources, [Ressource])
};

export const serviceRangesNormalizer = data =>{
    return normalize(data.data.serviceranges, [ServiceRange])
}

export const ressourceNormalilzer = (data) =>{
    
    return normalize(data.data.ressource, Ressource)
};
export const appointmentsNormalizer = (data) =>{
    return normalize(data.data.appointments, [Appointment])
};