import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {selectClosedAppointments} from '../../../../store/selectors'
import MainHeaderWrapper from '../../../../components/MainHeaderWrapper'
import MainContentWrapper from '../../../../components/MainContentWrapper';
import H3 from '../../../../components/elements/H3'
import AppointmentsTable from '../../../../components/AppointmentsTable';
import AppointmentDetailDrawer from '../../../../components/AppointmentDetailDrawer'


const AppointmentsDonePage = (props) => {
    
    const [_selected, _setSelected] = useState()

    const handleRowSelect = rowInfo =>{
        _setSelected(rowInfo)
    }

    const handleCloseDrawerRequested = () =>{
        _setSelected()
    }

    return (
        
        <React.Fragment>
            <MainContentWrapper>
            <MainHeaderWrapper>
                <H3
                lineHeight="40px"
                >Erledigte Aufgaben</H3>
            </MainHeaderWrapper>
                <AppointmentsTable selected={_selected &&_selected.index} onRowSelect={handleRowSelect}data={props.appointments}/>
            </MainContentWrapper>
            <AppointmentDetailDrawer 
            appointment={_selected && _selected.original}
            onRequestClose={handleCloseDrawerRequested}
            />
        </React.Fragment>
    );
}

const mapStateToProps = state =>({
    appointments: selectClosedAppointments(state)
})
 
const mapDispatchToProps = dispatch =>({
    
})
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsDonePage);


