import React from 'react';
import Text from '../elements/Text'
import emptyState from '../../assets/illustrations/EmptyState.png'
import './EmptyState.scss'
const images = {
    robot: emptyState
}

const EpmtyState = (props) => {
    const image = props.image && images[props.image] ? images[props.image] : emptyState
    return (
        <div className="empty-state-wrapper">
            <div className="empty-state-image">
                <img src={image} alt="empty" />                
            </div>
            <div className="empty-state-text">
                <Text textStyle="sub-headline">{props.title || 'Keine Daten'}</Text>
                <Text>{props.description || 'Momentan gibt es keine Daten.'}</Text>
            </div>
        </div>
    );
}
 
export default EpmtyState;