import React from 'react'

import Text from '../elements/Text'
import {DialogBtnFooter, DialogContent} from '../Dialog'
import robot from '../../assets/illustrations/Robot.png'
import H3 from '../elements/H3'
import {DialogHeader} from './DialogHeader'
import './Dialog.scss'

export const DialogSuccessContent = (props) => {
    return (
        <React.Fragment>
            <DialogHeader title=""onCloseBtnClick={props.onRequestClose}/>
            <DialogContent>
                <div className="success-dialog-content">
                    <img src={robot} alt="robot" />
                    <H3 fontSize="400" mb="0">{props.title}</H3>
                    <Text textStyle="body" lineHeight="2">
                        {props.message}
                    </Text>
                </div>
            </DialogContent>
            <DialogBtnFooter
            rightBtnTitle="Fertig"
            rightBtnVariant="default-primary"
            onRightBtnClick={props.onRequestClose}
            />
        </React.Fragment>
    );
}