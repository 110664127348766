import React from 'react';
import {DrawerSection, DrawerInfoItem} from '../Drawer'
import  Button from '../elements/Button'
import moment from 'moment'
export const ContactAttemptList = (props) => {

    const mapTypes = {
        EMAIL: 'Email',
        SYSTEM_MAIL: 'Automatische Mail',
        SKYPE: 'Skype',
        PHONE: 'Telfon'
    }

    return (
        <DrawerSection title="Korrespondenz">
            {
                props.contactAttempts && props.contactAttempts.map(e=>(
                    
                    <DrawerInfoItem key={e.id} title={moment(e.startDate).format('LLL')} value={mapTypes[e.channel]}/>
                ))
            }
            <Button size="small" onClick={props.onRequestAddContactAttempt} variant="flat-primary" noPadding>Hinzufügen</Button>
        </DrawerSection>
    );
}
 



