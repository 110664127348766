export const borderRadius = ['8px'];
export const radii = ['8px','10px','12px'];

export const shadows = [
    `0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)`,
    `0 0 2px 0 rgba(0,0,0,.08),0 2px 8px 0 rgba(0,0,0,.16)`,
    `0 0 2px 0 rgba(0,0,0,.08),0 4px 16px 0 rgba(0,0,0,.16)`,
    `0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.16)`
];
export const shadowPairs = {
    0: {
        normal : '0 2px 14px rgba(0,0,0,.08)',
        hover: '0 2px 14px rgba(0,0,0,.08), 0 2px 20px rgba(0,0,0,.2)'
    }
}