import React, {useState} from 'react';
import Badge from '../elements/Badge';
import Table from '../elements/Table'
import moment from 'moment'



const AppointmentDueRessourcesTable = (props) => {

    
    const handleRowClick = (e, t, rowInfo)=>{
      
      
      props.onRowSelect && props.onRowSelect(rowInfo)
    }

    


    const columns = [
 
      {
        Header: 'CI Name',
        accessor: 'ciName'
      },
      {
        Header: 'Letzter Kontakt',
        accessor: 'lastContact', // String-based value accessors!,
        id: 'lastContactAttempt', // String-based value accessors!
        accessor: d =>(d.lastContactAttempt ? moment(d.lastContactAttempt.startDate).locale('de').format('LL') : "")
      },
      {
        Header: 'Versuche',
        id: 'contactAttempts',
        accessor: d => (d.activities.length),
      },
      {
        Header: 'Offen seit',
        accessor: 'statusChangedAt', // String-based value accessors!
        Cell: props => { 
          
          return(<span>{props.value ? moment(props.value).locale('de').format('LL') : ""}</span>)
        } // Custom cell components!
      },
      {
        Header: 'Nutzer',
        id: 'customerName',
        accessor: d => (d.customer ? `${d.customer.firstName || ''} ${d.customer.lastName || ''}` : ''),
      },
      {
        Header: 'Werk',
        id: 'locationSite',
        accessor: d => (d.location.site)
      },
      {
        Header: 'Gebäude',
        id: 'locationBuilding',
        accessor: d => (d.location.building)
      },

      
    ]

    const filters = [
      {
        label: 'Werk',
        accessor: d =>(d.location.site),
        type: "text"
      },
      {
        label: 'Gebäude',
        accessor: d =>(d.location.building),
        type: "text"
      },
      { 
        label: 'Datum',
        accessor: d =>(d.lastContactAttempt.startDate),
        type: "date",
      }, 
      { 
        label: 'Datum',
        accessor: 'statusChangedAt',
        type: "date",
      }, 
    ]


        

    return (
        <React.Fragment>
        {/* <TableHeader filters={

        }/> */}
        
        
        <Table
        emptyTitle="Keine Rechner"
        emptyDescription="Es gibt momentan keine Rechner."
        data={props.data} 
        fields={columns}
        filters={filters}
        getTrProps={(state, rowInfo, column) => {
          if(rowInfo){
            return {
              onClick: (e, t) => {
                handleRowClick(e, t, rowInfo);
              },
              style: {
                background: rowInfo.index === props.selected ? '#ECEFFE' : '',
                borderRadius: rowInfo.index === props.selected ? '6px' : '0px',
                border: rowInfo.index === props.selected + 1 || rowInfo.index === props.selected ? 'none' : ''

              }
            };
          }else{
            return{}
          }
          
        }}
        />
        </React.Fragment>
    );
}


 
export default AppointmentDueRessourcesTable;

// {
//     Header: 'Friend Name',
//     accessor: d => d.friend.name // Custom value accessors!
//   }