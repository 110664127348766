export const prodConfig = {
    api:{
        url: 'https://api.service-int-group.com/api',
        ws:{
            private: 'https://api.service-int-group.com/private',
            public: 'https://api.service-int-group.com/public'
        } 
    },
    links:{
        appointment: 'https://service-int-group.com/appointment/',
        report: 'https://service-int-group.com/report/'
    }
}