import React from 'react'

export const DialogFooter = (props) => {
    return (
        <div className="dialog-footer">
            {props.children}
        </div> 
    );
}

DialogFooter.defaultProps = {
    showCloseBtn: true,
    icon: true,
    closeBtnDisabled: true, 
    
}
 


