import React from 'react';
import {uid} from 'react-uid';
import './SubNavigation.scss'
import SubNavigationSection  from "./SubNavigationSection";
import H3 from '../elements/H3'
import Button from '../elements/Button'
import Scrollbar from '../elements/Scrollbar'
import Can from '../Can'

const SubNavigation = (props) =>{
    
    return (
        <div className="subnavigation-wrapper">
        <Scrollbar>
            <div className="subnavigation-content">
            {props.title && 
            <H3 className="subnavigation-heading" textStyle="inherit">{props.title}</H3>
            }
            {   
                props.sections && props.sections.map((section)=>{
                    return(
                        <SubNavigationSection key={uid(section) }{...section}/>
                    )
                })
            }
            <Can I="create" on="ServiceRange">
                {props.bottomBtnTitle && 
                <Button onClick={props.onBottomBtnClick} className="new-course-btn" block>{props.bottomBtnTitle}</Button>
                }
            </Can>
            </div>
        </Scrollbar>
        </div>
    )   
}
export default SubNavigation


