import React, {useState} from 'react'
import {connect} from 'react-redux'
import {selectUser} from '../../store/selectors'
import './Drawer.scss'
import Text from '../elements/Text'
import Button from '../elements/Button'
import ConfirmationButton from '../ConfirmationButton'
import {UserAvatar} from '../Avatar'
import Select from '../elements/Select'
import AlertBox from '../AlertBox'
import Comment from '../Comment'
export const DrawerSection = (props) => {
    return (
        <div className="drawer-section">
            {props.title && 
            <div className="drawer-section-title">
                <Text textStyle="section-headline">{props.title}</Text>
            </div>}
            <div className="drawer-section-body">
                {props.children}
            </div>
            
        </div>
    );
}

export const DrawerItem = (props) => {
    return (
        <div className="drawer-item">
            {
            props.title && 
            <div className="drawer-item-title">
                <Text textStyle="caption" status={props.status}>{props.title}</Text>
            </div>
            }
            <div className="drawer-item-body">
                {props.children}
            </div>
        </div>
    );
}

export const DrawerAlertBox = props =>{
    return (
        <AlertBox style="danger">{props.children}</AlertBox>
    )
}



export const DrawerCommentSection = (props) =>{
    

    return(
        <DrawerSection title={props.title || 'Kommentare'}>
            {props.comments && props.comments.length > 0 ? props.comments.map(e=>(                      
                <Comment key={e.id} comment={e}/>
                ))
                 : 
            <div className="drawer-comments-empty">
                <Text textStyle="caption">No comments</Text>
            </div>}
            <Button size="small" onClick={props.onRequestAddComment} variant="flat-primary" noPadding>Kommentar hinzufügen</Button>
        </DrawerSection>
    )
}


export const DrawerForm = props =>{
    return(
        <div className="drawer-form">
            {props.children}
        </div>
    )
}

export const DrawerFormItem = props =>{
    return(
        <div className="drawer-form-item">
            <label>{props.title}</label>
            {props.children}
        </div>
    )
}

export const DrawerInfoItem = (props) => {
    return (
        <DrawerItem onClick={props.onClickEdit} status={props.titleStatus} title={props.title}>
            <Text textStyle="description">{props.value}</Text>
            {props.onClickEdit && <Button size="small" onClick={props.onClickEdit} variant="blank-neutral" icon="edit"/>}
        </DrawerItem>
    );
}


export const DrawerActionItem = (props) => {
    const Component = props.confirmation ? ConfirmationButton : Button;
    return (
        <DrawerItem>
            
            {props.description && 
            <div className="drawer-action-description">
                <Text textStyle="description">{props.description}</Text>
            </div>
            }
            
            <Component 
            size={props.size}
            variant={props.variant}
            noPadding={props.noPadding}
            dialogMessage={props.confirmationMessage}
            dialogTitle={props.confirmationTitle}
            closeDialogOnConfirm={props.closeDialogOnConfirm}
            onClick={props.onClick} isLoading={props.isLoading} block>{props.btnTitle}</Component>
        </DrawerItem>
    );
}

export const DrawerUserItem = (props) =>{
    return(
        <div className="buffl-drawer-ui-wrap flex">
        <div className="col1">
        <UserAvatar user={props.user} size={25} />
        </div>
        <div className="col2">
            <span>{`${props.user.firstName} ${props.user.lastName }`}</span>
        </div>
        <div className="col3">
        <Button onClick={()=>{
            props.onClick && props.onClick(props.user)
        }} size="small" variant="blank-neutral" icon="trash"></Button>
        </div>
        </div>
    )
}

export const DrawerUserList = (props) =>{
    const handleOnUserClick = user =>{
        props.onUserClick && props.onUserClick(user)
    }

    return(
        <div>
        {props.users && props.users.length > 0 && <DrawerUserItem user={props.users[0]} onClick={handleOnUserClick}/>}
        </div>
    )
}
 

export const DrawerUserSection = ({assignees, users, onSubmit, onRemove})=>{

    const [_selectedUser, _setSelectedUser] = useState("")
    const handleChange = (value)=>{
        console.log(value.value)
        onSubmit && onSubmit(value.value)
    }
   
    const handleRemove = (user) =>{
        onRemove && onRemove(user.id)
    } 
    return (
        <React.Fragment>
            <DrawerSection title="Verantwortliche">
                {assignees.length < 1 ?
                <Select 
                placeholder="Nutzer auswählen"
                value={_selectedUser}
                options={
                    users
                    .filter(e=>{
                        return assignees.map(e=>(e.id)).indexOf(e.id) == -1
                    })
                    .map(e=>{
                    return {
                        value: e.id, 
                        label: `${e.firstName} ${e.lastName}`
                    }
                })}
                onChange={handleChange}/>
                :
                <DrawerUserList users={assignees} onUserClick={handleRemove} />}
            </DrawerSection>
            
        </React.Fragment>
    )
}
 
