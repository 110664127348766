import React, {useEffect, useState} from 'react'
import  {connect} from 'react-redux';

// import {socket} from '../api/socketManager'
import {getAuthenticatedUser, getAuthToken} from './selectors'
import {addCommentSuccess, setEvent, updateAppointmentSuccess,assignUserSuccess, addContactAttemptSuccess,updateRessourceSuccess, removeAssigneeSuccess, openReportSuccess } from './actions'
import config from '../config';
import openSocket from "socket.io-client";

const mapDispatchToProps = dispatch => ({
    addCommentSuccess: (id, data) => dispatch(addCommentSuccess(id, data)),
    updateRessourceSuccess: (id, data) => dispatch(updateRessourceSuccess(id, data)),
    addContactAttemptSuccess: (id, data) => dispatch(addContactAttemptSuccess(id, data)),
    openReportSuccess: (id, data) => dispatch(openReportSuccess(id, data)),
    removeAssigneeSuccess: (id, data) => dispatch(removeAssigneeSuccess(id, data)),
    assignUserSuccess: (id, data) => dispatch(assignUserSuccess(id, data)),
    updateAppointmentSuccess: (id, data) => dispatch(updateAppointmentSuccess(id, data)),
    setEvent: (key) => dispatch(setEvent(key)),
})

const mapStateToProps = state =>({
    me: getAuthenticatedUser(state),
    token: getAuthToken(state)
})


export const SocketDispatcher = connect(mapStateToProps, mapDispatchToProps)(
    (props) => {
    
    const [_socket, _setSocket] = useState()

    const handle = (data, next)=>{
        
        if(false){
            return;
        }else{
            next(data)
        }
    }

    const handleAddComment = (data) =>{
        handle(data, (data)=>{
            props.addCommentSuccess(data.id, data)
            
        })
    }

    const handleUpdateRessource = data =>{
        handle(data, data=>{
            props.updateRessourceSuccess(data.id, data)
        })
    }

    const handleAddContactAttempt = data =>{
        handle(data, data=>{
            props.addContactAttemptSuccess(data.id, data)
            
        })
    }

    const handleOpenReport = data =>{
        handle(data, data=>{
            props.openReportSuccess(data.id, data)
        })
    }

    const handleRemoveAssignee = data =>{
        handle(data, data=>{
            props.removeAssigneeSuccess(data.id, data)
        })
    }

    const handleAddAssignee = data =>{
        handle(data, data=>{
            props.assignUserSuccess(data.id, data)
        })
    }

    const handleUpdateAppointment = data =>{
        handle(data, data=>{
            props.updateAppointmentSuccess(data.id, data)
        })
    }

    useEffect(()=>{
        
        if(props){
            let socket;
            if(props.token){                                
                if(!_socket){
                    socket = openSocket(config.api.ws.private, {query: `auth_token=${props.token}`});
                    socket.on('ADD_COMMENT', handleAddComment) 
                    socket.on('UPDATE_RESSOURCE', handleUpdateRessource) 
                    socket.on('APPOINTMENTS_CREATED', handleUpdateRessource) 
                    socket.on('OPEN_REPORT', handleOpenReport) 
                    socket.on('ADD_CONTACT_ATTEMPT', handleAddContactAttempt) 
                    socket.on('UPDATE_APPOINTMENT', handleUpdateAppointment) 
                    socket.on('ASSIGN_USER', handleAddAssignee) 
                    socket.on('REMOVE_ASSIGNEE', handleRemoveAssignee)
                    _setSocket(socket)                
                }
            }else{
                if(_socket){                    
                    _socket.close()
                    _setSocket()
                }
                // socket = openSocket(config.api.ws.public);
            }
             
        }
        
    }, [props.token, props.me])
    return (null);
})



