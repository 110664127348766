import actionTypes from "../../actionTypes";

/**
 * {user:{firstName: lastName, email, id}}
 */

const initialState = {
    user:{},
    token: "",
    status: 'NOT_MOUNTED'
}

const setToken = (state, action) =>{
    return {
        ...state,
        token: action.payload,
        status: 'MOUNTED'
    }
}

const unSetToken = (state, action) =>{
    return {
        ...state,
        token: ""
    }
}

const setUser = (state, action) =>{
    
    return{
    ...state,
    user: action.payload
}}



const authReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_JWT_TOKEN: return setToken(state, action)
        case actionTypes.LOGIN_SUCCESS: return setToken(state, action)
        case actionTypes.FETCH_PROFILE_SUCCESS: return setUser(state, action)
        case actionTypes.FETCH_PROFILE_SUCCESS: return setUser(state, action)
        case actionTypes.LOGOUT: return unSetToken(state, action)
        default: return state;
    }
};

export default authReducer