import {colors} from './colors'

export const fontSizes = {
    75: '0.68em',
    100: '0.75em',
    200: '0.875em',
    300: '1em',
    400: '1.25em',
    500: '1.5em',
    600: '2em',
    700: '3em'
}
export const lineHeights = ["1em","1.25em","1.5em"];
export const fontWeights = {
    regular: 400, 
    semiBold: 700, 
    bold: 900, 
    thin: 300
};
export const letterSpacings = {
  normal: 'normal',
  caps: '0.025em',
  tracked: '0.1em',
  tight: '-0.05em',
  mega: '0.25em',

};
export const fonts = {
    sans: "Lato, 'Inter', Roboto, Arial, sans-serif",
    serif:'Times New Roman, Times, serif'
}

export const textStyles = {
    link: {
        textDecoration: 'none',
        fontSize: fontSizes[200],
        fill: colors.neutral.grays[500],
        color: colors.neutral.grays[500],
        '&:hover': {
            fill: colors.neutral.grays[800],
            color: colors.neutral.grays[800],
        },
        '&.active': {
            fill: colors.neutral.grays[500],
            color: colors.neutral.grays[500],
        },
    },
    actionLink: {
        fontSize: fontSizes[200],
        '&:hover': {
            fill: colors.brand.primary[500],
            color: colors.brand.primary[500],
        },
        '&.active': {
            fill: colors.brand.primary[800],
            color: colors.brand.primary[800],
        },
    },
    body: {
        fontSize: fontSizes[300],
        fontWeight: fontWeights.regular,
        color: colors.neutral.grays[500],
        fontFamily: fonts['sans'],
        a: {
            fill: 'black',
            backgroundColor: 'red',
            color: 'black',
            '&:hover': {
                fill: 'black',
                backgroundColor: 'white',
                color: 'black',
            },
            '&.active': {
                fill: 'red',
                backgroundColor: 'white',
                color: 'red',
            },
        },
    },
    headline: {
        fontSize: fontSizes[500],
        fontWeight: fontWeights.bold,
        color: colors.neutral.grays[600],
        fontFamily: fonts['sans'],
        '&:colored':{
            color: 'yellow'
        }
    },
    caption: {
        fontSize: fontSizes[75],
        color: colors.neutral.grays[500],
        fontFamily:fonts['sans'],
        fontWeight: fontWeights.semiBold,
        letterSpacing: '0.015em'

    },
    menuCaption: {
        fontSize: fontSizes[100],
        fontWeight: fontWeights.semiBold,
        color: colors.neutral.grays[400],
        letterSpacing: '0.05em'
    },
    description: {
        fontSize: fontSizes[200],
        color: colors.neutral.grays[400],
        fontFamily: fonts['sans'],
        fontWeight: fontWeights.semiBold
        
    },
    modalHeading: {
        fontSize: fontSizes[500],
        fontWeight: fontWeights.bold,
        color: colors.neutral.grays[600],
        fontFamily: fonts['sans'],
        '&:colored':{
            color: 'yellow'
        }
    },
    formLabel:{
        fontSize: fontSizes[200],
        fontWeight: fontWeights.bold,
        fontFamily: fonts['sans'],
        color: colors.neutral.grays[500]
    },
    base: {
        fontSize: ['12px'],
        fontWeight: "regular",
        color: 'red',
        fontFamily: fonts['sans']
    }
}