import React from 'react'

import Text from '../Text'
const H3 = props => (<Text {...props}/>)

H3.defaultProps = {
    textStyle: 'headline',
    component: 'h3'
}

H3.propTypes = Text.propTypes
export default H3