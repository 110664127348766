import React , {useState} from 'react';
import {Creatable} from 'react-select'


export const CreatableSelect = (props) => {
    const [_values, _setValues] = useState(props.value || [])
    const [_inputValue, _setInputValue] = useState("")
    const [_options, _setOptions] = useState(props.options || [])

    const validate = input=> (props.validate ? props.validate(input.trim()) : true)

    const noOptionsMessage = ({inputValue}) =>{
        if(!props.noOptionsMessage){
            return "No options :("
        }else{
            return props.noOptionsMessage && {}.toString.call(props.noOptionsMessage) === '[object Function]'
            ? props.noOptionsMessage(inputValue) : props.noOptionsMessage
        }
    }
    
    const formatCreateLabel = (inputValue) =>{
        if(!props.formatCreateLabel){
            return `${inputValue}`
        }else{
            return props.formatCreateLabel && {}.toString.call(props.formatCreateLabel) === '[object Function]'
            ? props.formatCreateLabel(inputValue) : props.formatCreateLabel
        }
    }

    const setOptions = (newValue) =>{
        _setOptions(props.options.filter(
            e=>{
                return newValue
                .map(e=>{

                    return e.value.toLowerCase()
                })
                .indexOf(e.value.toLowerCase()) === -1
            }
            )
        )
    }

    const handleChange = (newValue, {action}) => {
        
        switch (action) {
            case "select-option":
                _setValues(newValue)
                props.onChange && props.onChange(newValue)
                _setInputValue("")
                break;
            case "pop-value":
                _setValues(newValue)
                setOptions(newValue)
                props.onChange && props.onChange(newValue)
                break;
            case "remove-value":
                setOptions(newValue)
                _setValues(newValue)
                props.onChange && props.onChange(newValue)
                break;
            default:
                break;
        }
        
    
    };
    const handleOnInputChange =(input, {action}) =>{

        switch (action) {
            case "input-change":
                _setInputValue(input)
                if(input.includes(",") || input.includes(";")){
                    const existingValues = _values.map(e=>(e.value.toLowerCase()))
                    const uniqueValues = input
                    .split(/[;,\/]/)
                    .map(e=>(e.trim()))
                    .filter((e, i, arr)=>{

                        return e && e !== "" && existingValues.indexOf(e.toLowerCase()) === -1 && arr.map(e=>(e.toLowerCase())).indexOf(e.toLowerCase()) === i
                    })
                    const preparedValues = uniqueValues.map(e=>({
                        value:e,
                        label:e,
                        error: !validate(e)
                    }))
                    const newValue =[..._values, ...preparedValues]
                    _setValues(newValue)
                    _setInputValue("")
                    props.onChange && props.onChange(newValue)
                    setOptions(newValue)
                    
                }
                break;
        
            default:
                break;
        }
    }

    const handleCreateOption = (inputString)=>{
        const isValid = validate(inputString)
        const newValue =[..._values, { value: inputString.trim(), label: inputString.trim(), error: !isValid }]
        _setValues(newValue)
        _setInputValue("")
        props.onChange && props.onChange(newValue)
    }

    return (
        <Creatable {...props} 
        inputValue={_inputValue}
        onChange={handleChange} 
        onInputChange={handleOnInputChange}
        onCreateOption={handleCreateOption} 
        value={_values} 
        options={_options} 
        isClearable={false}
        noOptionsMessage={noOptionsMessage}        
        formatCreateLabel={formatCreateLabel}           
         />
    );
}

CreatableSelect.defaultProps ={
    options:[]
}
 
 