import { createSelector } from "reselect";
import {defineAbilitiesFor} from '../../authManager'
export const selectAuth = state =>(state.auth)

export const selectAbilities = createSelector(
    [selectAuth],
    (auth)=>(defineAbilitiesFor(auth))
)


export const isAuthenticated = (state) => {
    return(
        state.auth.token ? true : false)
}

export const authIsMounted = state =>{
    return state.auth.status !== 'NOT_MOUNTED'
}

export const getAuthToken = (state) =>(state.auth.token)
export const getAuthenticatedUser = (state) => (state.auth.user)