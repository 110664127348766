import React from 'react'
import {DialogFooter} from './DialogFooter'
import Button from '../elements/Button'


export const DialogBtnFooter = (props) => {
    return (
        <DialogFooter>
            
                <div className="flex-grow-1 display-flex">
                    {props.leftBtnTitle && <Button 
                    variant={props.leftBtnVariant} 
                    size="normal" 
                    noPadding 
                    onClick={props.onLeftBtnClick}
                    disabled={props.leftBtnDisabled}
                    >
                        {props.leftBtnTitle}
                    </Button>}
                </div>
                <div>
                {props.rightBtnTitle &&
                    <Button 
                    isLoading={props.isLoading}
                    size="normal"
                    onClick={props.onRightBtnClick}
                    variant={props.rightBtnVariant}
                    disabled={props.rightBtnDisabled}
                    >
                        {props.rightBtnTitle}
                    </Button>     
                }
                </div>
            
        </DialogFooter>
    );
}

DialogBtnFooter.defaultProps = {
    closeBtnDisabled: true, 
    leftBtnDisabled: false,
    rightBtnDisabled: false,
    leftBtnVariant: "flat-neutral-light",
    isLoading: false
}
 


