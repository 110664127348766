import React from 'react'

import Modal from '../elements/Modal'
import Text from '../elements/Text'
import {DialogBtnFooter, DialogContent} from '../Dialog'
import robot from '../../assets/illustrations/Robot.png'
import empty from '../../assets/illustrations/EmptyState.png'
import H3 from '../elements/H3'

import './ConfirmationDialog.scss'

const imageMap = {
    robot: robot,
    empty: empty,
}

const ConfirmationDialog = (props) =>{
    const imgSrc = props.img && imageMap[props.img] ? imageMap[props.img] : robot ;
    return (
    <Modal 
    isOpen={props.isOpen}
    onRequestClose = {props.onCancel} >
        {/*<DialogHeader 
        title={props.title}
        onCloseBtnClick={props.onCancel}/>*/}             
        <DialogContent>
            <div className="confirmation-dialog-content">
                <img src={imgSrc} alt="robot" />
                <H3 fontSize="400" mb="0">{props.title}</H3>
                <Text textStyle="body" lineHeight="2">
                    {props.message}
                </Text>
            </div>
        </DialogContent>
        {!props.noOptions &&
        <DialogBtnFooter
        rightBtnTitle="Fortfahren"
        leftBtnTitle="Cancel"
        isLoading={props.isLoading}
        rightBtnVariant="default-danger"
        onRightBtnClick={props.onConfirm}
        onLeftBtnClick={props.onCancel}
        />           
        }
    </Modal>
    );
    
}

export default ConfirmationDialog;

