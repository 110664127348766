import React, {useState} from 'react';
import {connect} from 'react-redux'
import Modal from '../elements/Modal'
import {DialogBtnFooter, DialogHeader, DialogContent, DialogSuccessContent} from '../Dialog'
import { withFormik } from 'formik';
import ReduxObserver from '../ReduxObserver'
import {TimeSlotPicker} from '../elements/DayPicker'
import {updateAppointment} from '../../store/actions'
import {selectServiceRangeConfig, selectIsLoading} from '../../store/selectors'
import './AppointmentsDialog.scss'
import moment from 'moment'
const Form = props =>{
    
    
    const {
        values,
        isValid,
        setFieldValue,
        handleSubmit,
        submitForm
      } = props;

      const handleDateChange = (field, value) => {
        setFieldValue(field, value)
    }
    
      return (
        <React.Fragment>
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <div className="create-appointments-dialog">                
                    <div className="information-block">
                        <div className="item">
                            
                            <TimeSlotPicker 
                                allowAll
                                name="saveAppointment" 
                                value={values.appointment}
                                name="appointment"
                                onChange={handleDateChange}
                                
                            />
                        </div>                
                    </div>
                </div>
            </DialogContent>
            <DialogBtnFooter
                rightBtnTitle="Speichern"
                isLoading={props.isLoading}
                leftBtnTitle="Cancel"
                onRightBtnClick={submitForm}
                onLeftBtnClick={props.onRequestClose}
                rightBtnDisabled={!isValid}
                leftBtnDisabled={false}  
                />
        </form>
        
        </React.Fragment>
      )
}


const validate = values =>{
    if(!values.appointment){
        return {
            appointment: 'Termin auswählen'
        }
    }else{
        return {}
    }
}

const mapDispatchToProps = (dispatch) =>({
    updateAppointment: (id, data) => dispatch(updateAppointment(id, data))
})

const mapStateToProps = (state) =>({
    isLoading: selectIsLoading(state, "UPDATE_APPOINTMENT"),
})

export const UpdateAppointmentDialog = connect(mapStateToProps, mapDispatchToProps)((props) => {

    const [_showSuccessMessage, _setShowSuccessMessage] = useState(false)
    const EnhancedForm = withFormik({
        handleSubmit: (values)=>{
            props.updateAppointment(props.appointment.id, values.appointment)
        },
        validate: validate,
        mapPropsToValues: (props) => ({
            appointment: {
                startDate: props.appointment.startDate,
                value: props.appointment.startDate,
                label: `${moment(props.appointment.startDate).locale('de').format('LT')} - ${moment(props.appointment.endDate).locale('de').format('LT')}`,
            }
        }),
        })(Form)
    
    
    return (
        <Modal 
        onRequestClose={()=>{
            props.onRequestClose()
            _setShowSuccessMessage(false)
        }}
        isOpen={props.isOpen}>
            <ReduxObserver event={`UPDATE_APPOINTMENT_${props.appointment.id}`} onEvent={()=>{
                _setShowSuccessMessage(true)}}/>
            
            
            {_showSuccessMessage ?
            <DialogSuccessContent 
            title="Erfolgreich"
            message="Termin wurde geändert."
            onRequestClose={()=>{
                props.onRequestClose()
                _setShowSuccessMessage(false)
            }}/>
            :
            <React.Fragment>
                <DialogHeader 
                title="Termine ändern"
                onCloseBtnClick={props.onRequestClose}/> 
                <EnhancedForm {...props}/>
            </React.Fragment>
            }
        </Modal>
    );
})
 

