import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import HubLayout from '../../../hoc/Layouts/HubLayout';
import Icon from '../../../components/elements/Icon'
import {fetchForm, submitForm} from '../../../store/actions'
import {selectForm, selectFormConfig, selectIsLoading} from '../../../store/selectors'
import logo from '../../../assets/ci/logo.png'
import FormHubForm from './FormHubForm'
import '../hub.scss';
import '../MakeAppointmentHub/MakeAppointmentHub.scss';
import { useTranslation  } from 'react-i18next';
import AlertBox from '../../../components/AlertBox'
import LanguageSwitch from '../../../components/languageSwitch';
import {HubRessourceInformation} from '../fragments'

const FormHub = (props) => {
     useEffect(()=>{
        props.fetchForm(props.match.params.token)
    }, [])

    const handleSubmit = (data)=>{
        props.submitForm(props.form.id, data)
    }
    const { t } = useTranslation();
    
    return (
        <HubLayout>
            {props.form.ressource && <div className="hub">
                <div className="hub-sidebar">
                <div className="hub-sidebar-inner">
                    <div className="hub-sidebar-logo">
                        <img src={logo} alt="robot" />
                    </div>
                    
                    <div className="hub-sidebar-text  ">
                    <h1>{t('hub:form.sidebar.title')}</h1>
                    <h2>{t('hub:form.sidebar.subheading')}</h2>
                    <p>{t('hub:form.sidebar.welcomeText')}</p>
                    <p>{t('hub:form.sidebar.contact')} <a href="mailto:rollout@int-group.de">rollout@int-group.de</a> {t('hub:form.sidebar.contact2')}</p>
                    <div>
                    </div>
                    
                    </div>
                <LanguageSwitch />
                </div>
                </div>
                <div className="hub-content">
                    <div className="hub-row">
                        <div className="hub-block">
                            <div className="hub-block-left">
                                <div className="hub-block-icon variant1">
                                    <Icon icon="desktop"></Icon>
                                </div>
                            </div>
                                <div className="hub-block-right">
                                    <HubRessourceInformation ressource={props.form.ressource}/>
                                </div>
                        
                        </div>
                    </div>
                    {!props.form ? <p>{t('hub:form.form.loading')}</p> 
                        :
                        props.form.status === 'PRISTINE' ?
                        <FormHubForm  
                        ressource={props.form.ressource}
                        isLoading={props.isLoading}
                        onSubmit={handleSubmit}
                        config={props.config}
                        />
                        :
                        <AlertBox>{t('hub:form.form.alertAppointmentSuccess')}</AlertBox>
                    }
                </div>
            </div>}
        </HubLayout>
    );
}

const mapDispatchToProps = dispatch =>({
    fetchForm: (token) => dispatch(fetchForm(token)),
    submitForm: (id, data) => dispatch(submitForm(id, data))
})

const mapStateToProps = (state, props) =>({
    form: selectForm(state),
    isFetching: selectIsLoading(state, "FETCH_SERVICE_RANGE"),
    isLoading: selectIsLoading(state, "SUBMIT_FORM"),
    config: selectFormConfig(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormHub);