import config from '../config'
import openSocket from "socket.io-client";

export let socket;
let _token;

export const initSocket = token =>{
    if(token){
        if(socket){
            socket.disconnect()
        }
            socket = openSocket(config.api.ws.private, {query: `auth_token=${token}`});
            _token = token
        return
    }else{
        if(socket){
            socket.disconnect()
        }
        socket = openSocket(config.api.ws.public);
    }
}


