import React from 'react';
import {Switch, Route} from 'react-router-dom'
import AppLayout from '../hoc/Layouts/AppLayout'

import {HomeRouter} from '../routers/HomeRouter'
import {NotFoundRoute} from '../hoc/routes'


export const AppRouter = (props) => {
    const path = props.match.url
    return (
        <AppLayout>            
            <Switch>
            <Route path={`${path}/home`} component={HomeRouter}/>
            <NotFoundRoute  path={`${path}`} />
            </Switch>
        </AppLayout>
    )
}
