import React, {useState, useEffect} from 'react';
import DayPicker from './DayPicker'
import Select from '../Select'
import {checkIfIsAfter} from '../../../utils'

import './DayPicker.scss'
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment)



const getSlotsForDay = (day, interval=15, duration=60) =>{
    const minMinutes =  0
    const maxMinutes = 1440
    let slots = [];
    let current = minMinutes;
    
    while (current < maxMinutes){
        const newSlot = moment(day).add(current, 'm').toDate()
        slots = [...slots, {
            startDate: newSlot,
            endDate: moment(newSlot).add(duration, 'm').toDate(),
            value: newSlot,
            label: `${moment(newSlot).locale('de').format('LT')} - ${moment(newSlot).add(duration, 'm').locale('de').format('LT')}`,
            duration
        }]
        current = current + interval
    }
    
    
    return slots

}

const getDisabledDays = (start, end, availableDays) =>{
    const range = moment.range(start.startOf('M'), end.endOf('M'));
    
    const arr = Array.from(range.by('day')).filter(e=>{
        return availableDays.indexOf(e.format('YYYY-MM-DD')) === -1
    }).map(e=>(e.toDate()))
    return [...arr, new Date()]
}

export const TimeSlotPicker = (props) => {



    const [_selectedDay, _setSelectedDay] = useState()
    const [_dayValue, _setDayValue] = useState(props.value ? moment(props.value.value).format('LL') : '')


    const sortedSlots = props.options.sort((a,b)=>{
        return a.value - b.value
    })

    const slotOptions = sortedSlots.map(e=>{
        
        return{
            startDate: e.startDate,
            value: e.startDate, 
            label: `${moment(e.startDate).locale('de').format('LT')} - ${moment(e.startDate).add(e.duration, 'm').locale('de').format('LT')}` 
        }
    })
    
    const availableDays = sortedSlots.reduce((acc, e)=>{
        const eFormat = moment(e.startDate).format('YYYY-MM-DD')
        return acc.indexOf(eFormat) === -1 ? [
            ...acc, 
            eFormat
         ] : acc
    }, [])

    const firstDay =  moment(availableDays[0])
    const lastDay = moment(availableDays[availableDays.length-1])
    const disabledDays = getDisabledDays(firstDay, lastDay, availableDays)

    const handleDayChange = (day) =>{
        if(_selectedDay !== moment(day).format('YYYY-MM-DD')){
            
            props.value && handleSlotChange(null)
            _setSelectedDay(moment(day).format('YYYY-MM-DD'))
        }
        
    }

    const handleSlotChange = (value) =>{
        
        props.onChange && props.onChange(props.name, value)
    }

    return (
        <div className="flex row time-slot-picker">
            <div className="flexcol grow">
                <DayPicker
                format="LL"
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={_dayValue}
                onDayChange={handleDayChange}
                dayPickerProps ={
                    {
                    initialMonth: props.allowAll ? null : firstDay.toDate(),
                    fromMonth: props.allowAll ? null : firstDay.toDate(),
                    toMonth: props.allowAll ? null : lastDay.toDate(),
                    disabledDays: props.allowAll ? [] : disabledDays
                    }
                }
                inputProps={{disabled: true}} 
                
                />
            </div>
            <div className="flexcol">

                <Select  
                className="slot-select" 
                value={props.value}
                onChange={handleSlotChange}
                isDisabled={!_selectedDay && !_dayValue} isSearchable={true} placeholder="Uhrzeit wählen" 
                options={
                    !_selectedDay && !_dayValue ? [] : props.allowAll ? getSlotsForDay(_selectedDay) : slotOptions.filter(e=>{
                        return moment(e.value).format('YYYY-MM-DD') === _selectedDay
                    })}/>
            </div>
        </div>
    );
}
TimeSlotPicker.defaultProps = {
    options:[]
};